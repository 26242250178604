import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ImageInput from "../../Image_input/image_input";
import customFetchWithToken from "../../../../../API/CustomFetchWtihToken";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { FormikProps } from "formik";
import {
  hrc_category_menu_api,
  hrc_currency_menu_api,
} from "../../../../../API/api";
import {
  categoryModel,
  currencyModel,
} from "../../../../../models/product_data_model";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { productType } from "./product_schema";

interface ProductFormProps {
  props: FormikProps<productType>;
}

function ProductForm({ props }: ProductFormProps) {
  const { values, handleChange, errors, touched, setFieldValue } = props;
  const [age, setAge] = React.useState("");
  const [balance, setbalance] = React.useState("");

  const [category_menu, setcategory_menu] = React.useState<
    [] | categoryModel[]
  >([]);
  const [currency_menu, setcurrency_menu] = React.useState<
    [] | currencyModel[]
  >([]);
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);

  React.useEffect(() => {
    if (token_id) {
      customFetchWithToken(hrc_category_menu_api, token_id).then((data) =>
        setcategory_menu([...data.DATA])
      );
      customFetchWithToken(hrc_currency_menu_api, token_id).then((data) =>
        setcurrency_menu([...data.DATA])
      );
    }
  }, [token_id]);
  return (
    <div className="w-full h-full  flex flex-col gap-9">
      {/* Form title  */}
      <header>
        <h1 className="text-3xl text-[#00aa13] text-center">Product Details</h1>
      </header>
      <main className="w-full h-[90%] flex-auto ">
        <div className="grid grid-rows-4 grid-cols-5 gap-4 h-full ">
          <div className="row-span-3   ">
            <ImageInput props={props} />
          </div>
          <div className="col-span-4  grid grid-cols-4 gap-4 items-center ">
            <TextField
              id="Product_Name"
              name="Product_Name"
              label="Name"
              variant="outlined"
              value={values.Product_Name}
              onChange={handleChange}
              error={touched.Product_Name && Boolean(errors.Product_Name)}
              helperText={touched.Product_Name && errors.Product_Name}
            />
            <TextField
              id="category"
              name="category"
              label="Category"
              select
              value={values.category}
              onChange={handleChange}
              error={touched.category && Boolean(errors.category)}
              helperText={touched.category && errors.category}
            >
              {category_menu.map((item) => (
                <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
                  {item.TypeName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="currency"
              name="currency"
              label="Currency"
              select
              value={values.currency}
              onChange={handleChange}
              error={touched.currency && Boolean(errors.currency)}
              helperText={touched.currency && errors.currency}
            >
              {currency_menu?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  <span>
                    {item.symbol}
                    <span className="ml-2">{item.name}</span>
                  </span>
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="Product_Code"
              name="Product_Code"
              label="Product Code"
              variant="outlined"
              value={values.Product_Code}
              onChange={handleChange}
              error={touched.Product_Code && Boolean(errors.Product_Code)}
              helperText={touched.Product_Code && errors.Product_Code}
            />
          </div>
          <div className="col-span-4 row-span-2 grid grid-cols-4 gap-4 items-center ">
            <TextField
              id="price_per_unit"
              name="price_per_unit"
              label="Price"
              variant="outlined"
              color="success"
              value={values.price_per_unit}
              onChange={handleChange}
              error={touched.price_per_unit && Boolean(errors.price_per_unit)}
              helperText={touched.price_per_unit && errors.price_per_unit}
            />
            <TextField
              id="discount"
              name="discount"
              label="Discount"
              variant="outlined"
              color="success"
              value={values.discount}
              onChange={handleChange}
              error={touched.discount && Boolean(errors.discount)}
              helperText={touched.discount && errors.discount}
            />

            <TextField
              id="hsn_no"
              name="hsn_no"
              label="HSN number"
              variant="outlined"
              color="success"
              value={values.hsn_no}
              onChange={handleChange}
              error={touched.hsn_no && Boolean(errors.hsn_no)}
              helperText={touched.hsn_no && errors.hsn_no}
            />
            <TextField
              id="lead_time"
              name="lead_time"
              label="Lead Time"
              variant="outlined"
              color="success"
              value={values.lead_time}
              onChange={handleChange}
              error={touched.lead_time && Boolean(errors.lead_time)}
              helperText={touched.lead_time && errors.lead_time}
            />
            <TextField
              id="ratings"
              name="ratings"
              label="Ratings"
              variant="outlined"
              color="success"
              value={values.ratings}
              onChange={handleChange}
              error={touched.ratings && Boolean(errors.ratings)}
              helperText={touched.ratings && errors.ratings}
            />
            <TextField
              id="description"
              name="description"
              label="Description"
              variant="outlined"
              multiline
              color="success"
              value={values.description}
              onChange={handleChange}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
            />
            <>
              <FormControlLabel
                label="has Serial"
                control={
                  <Checkbox
                    name="hasSerial"
                    id="hasSerial"
                    checked={values.hasSerial === 1 ? true : false}
                    onChange={handleChange}
                    color="success"
                  />
                }
              />
            </>
          </div>
          <div className="col-span-2 row-span-2 items-center ">
            <div className="flex flex-col gap-2 grid-row-3">
              <h1 className="text-2xl ">Payment Terms</h1>
              <div className="flex gap-4 w-full ">
                <TextField
                  id="payment_terms"
                  name="payment_terms"
                  label="Advance"
                  variant="outlined"
                  className="flex-auto"
                  value={values.payment_terms}
                  onChange={(e) => {
                    if (
                      e.target.value.length <= 3 &&
                      Number(e.target.value) <= 100
                    ) {
                      setFieldValue("payment_terms", e.target.value);
                      setbalance(String(100 - Number(e.target.value)));
                    }
                  }}
                  color="success"
                  error={touched.payment_terms && Boolean(errors.payment_terms)}
                  helperText={touched.payment_terms && errors.payment_terms}
                />
                <TextField
                  id="hsn-basic"
                  label="Balance"
                  variant="outlined"
                  value={balance}
                  focused={!!balance}
                  color="success"
                  className="flex-auto"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3  row-span-2 items-center  ">
            <div className="flex flex-col gap-2 grid-row-4 ">
              <h1 className="text-2xl ">Tax</h1>
              <div className="flex gap-4 w-full ">
                <TextField
                  id="cgst"
                  name="cgst"
                  label="CGST"
                  variant="outlined"
                  color="success"
                  className="flex-auto"
                  value={values.cgst}
                  onChange={handleChange}
                  error={touched.cgst && Boolean(errors.cgst)}
                  helperText={touched.cgst && errors.cgst}
                />
                <TextField
                  id="sgst"
                  name="sgst"
                  label="SGST"
                  variant="outlined"
                  color="success"
                  className="flex-auto"
                  value={values.sgst}
                  onChange={handleChange}
                  error={touched.sgst && Boolean(errors.sgst)}
                  helperText={touched.sgst && errors.sgst}
                />
                <TextField
                  id="igst"
                  name="igst"
                  label="IGST"
                  variant="outlined"
                  color="success"
                  className="flex-auto"
                  value={values.igst}
                  onChange={handleChange}
                  error={touched.igst && Boolean(errors.igst)}
                  helperText={touched.igst && errors.igst}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default React.memo(ProductForm);
