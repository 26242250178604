import * as React from "react";
import Fab from "@mui/material/Fab";

export default function ViewButton({ setflag }: { setflag: Function }) {
  return (
    <div>
      <Fab color="success" aria-label="add" onClick={() => setflag(true)}>
        <i className="fa-sharp fa-solid fa-eye text-white"></i>
      </Fab>
    </div>
  );
}
