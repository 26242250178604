import React from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { opentoaddData } from "../../../models/lms_data_api";
type FloatButtonProp = {
  opentoAdd: opentoaddData;
  setOpentoAdd: Function;
};

function FloatButton({ opentoAdd, setOpentoAdd }: FloatButtonProp) {
  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ backgroundColor: "#00aa13" }}
        onClick={(e) => {
          e.stopPropagation();
          setOpentoAdd({ ...opentoAdd, add: true, flag: false, update: "" });
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
export default React.memo(FloatButton);
