import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import useInput from "../../API/CustomInput";
import { getAuth } from "firebase/auth";
import customFetchWithToken from "../../API/CustomFetchWtihToken";
import { admin_add_gt_user_api } from "../../API/api";
import showToastMessage from "../../Tostify/Index";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { setAdmin_data } from "../../features/admin_actions/admin_slice";

type AdminAddUserFormProps = {
  // : string;
};

const AdminAddUserForm: React.FC<AdminAddUserFormProps> = ({}) => {
  const [token, settoken] = useState<string>("");

  const user_name = useInput("");
  const mobile_number = useInput("");
  const email = useInput("");
  const {
    admin: {
      data: { admin_data },
    },
  } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    const auth = getAuth();
    const current_user = auth.currentUser;
    current_user?.getIdToken().then((token) => settoken(token));
  }, []);

  const handelForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let body = JSON.stringify({
      UserName: user_name.value,
      MobileNumber: mobile_number.value,
      Email: email.value,
    });
    if (mobile_number.value.length === 10) {
      customFetchWithToken(
        admin_add_gt_user_api,
        token,
        { method: "POST" },
        body
      )
        .then((data) => {
          showToastMessage(data.SUCCESS, "SUCEESS");
          return data;
        })
        .then((data) => {
          if (admin_data) {
            let updated_data = [data.DATA, ...admin_data];
            dispatch(setAdmin_data([...updated_data]));
          }
          user_name.onChange({ target: { value: "" } });
          mobile_number.onChange({ target: { value: "" } });
          email.onChange({ target: { value: "" } });
        });
    } else {
      showToastMessage(false, "Enter valid Number");
    }
  };

  return (
    <form onSubmit={handelForm}>
      <div className="flex flex-col items-center w-full gap-4 ">
        <TextField
          id="standard-basic"
          label="Username"
          color="success"
          variant="standard"
          sx={{ width: "60%" }}
          {...user_name}
          required
        />
        <TextField
          id="standard-basic"
          label="Mobile Number"
          color="success"
          variant="standard"
          sx={{ width: "60%" }}
          {...mobile_number}
          required
        />
        <TextField
          id="standard-basic"
          label="Email"
          type={"email"}
          color="success"
          variant="standard"
          sx={{ width: "60%" }}
          {...email}
          required
        />
        
        <Button
          variant="contained"
          color="success"
          className="self-end"
          type="submit"
        >
          Add
        </Button>
      </div>
    </form>
  );
};

export default AdminAddUserForm;
