import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Modules/login/login";
import Home from "./Modules/Hrc/Home/home";
import LmsHome from "./Modules/lms/home/lms_home";
import LmsAll from "./Modules/lms/Pages/all/lms_all";
import Fresh from "./Modules/lms/Pages/Fresh/Fresh";
import FollowUp from "./Modules/lms/Pages/Follow-up/FollowUp";
import Won from "./Modules/lms/Pages/Won/Won";
import Closed from "./Modules/lms/Pages/Closed/Closed";
import VechileIn from "./Modules/Hrc/Pages/VechileIn/VechileIn";
import JobCard from "./Modules/Hrc/Pages/Job_card/Job_Card";
import Inventory from "./Modules/Hrc/Pages/Inventory/Inventory";
import PrivateRoutes from "./PrivateRoute/PrivateRoute";
import "tw-elements";
import { useEffect, useState } from "react";
import { user_type_api } from "./API/api";
import { opentoaddData } from "./models/lms_data_api";
import MyFollowTab from "./Modules/lms/Components/my_follow_tab";
import PurchaseOrder from "./Modules/Hrc/Pages/Purchase/Purchase";
import RepairingCriteria from "./Modules/lms/Pages/Repairing Criteria/RepairingCriteria";
import AddRider from "./Modules/Hrc/Pages/Rider/AddRider";
import VehicleStatus from "./Modules/Hrc/Pages/vehicle_status/VehicleStatus";
import Transit from "./Modules/Hrc/Pages/transit/Transit";
import CircularProgress from "@mui/material/CircularProgress";
import "firebase/auth";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "./Fribase.tsx/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import customFetchWithToken from "./API/CustomFetchWtihToken";
import { user_type_data_type } from "./models/API_data";
import NotAccessPage from "./Modules/access_denied/access_denied";
import showToastMessage from "./Tostify/Index";
import { ToastContainer } from "react-toastify";
import AdminTools from "./Modules/admin_tools/admin_tools";
import Analytics from "./Modules/lms/Pages/Analytics/analytics";
import { setUser, logOutUser } from "./features/user/user_slice";
import HrcAnalytics from "./Modules/Hrc/Pages/hrc_analytics/hrc_analytics";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import FollowUpWalkin from "./Modules/lms/Pages/follow_up_walkin/follow_up_walkin";
import HrcProduct from "./Modules/Hrc/Pages/product/hrc_product";
import { setAccessPage } from "./features/access_slice/access_slice";
import { useSearchParams } from "react-router-dom";
import AddProduct from "./Modules/Hrc/Pages/new_product/add_product";
import Vendor from "./Modules/Hrc/Pages/new_product/vendor";
import AddVendor from "./Modules/Hrc/Pages/new_product/add_vendor";
import Sow from "./Modules/Hrc/Pages/new_product/Sow";
import ProductHome from "./Modules/Hrc/Pages/new_product/product_home";
import ProductList from "./Modules/Hrc/Pages/new_product/product_list";
import Customer from "./Modules/lms/Pages/customer/customer";
import VehicleData from "./Modules/Hrc/Pages/vehicle_data/vehicle_data";
import PdfExports from "./Modules/lms/Components/pdf_exports/pdf_exports";
import PurchaseExports from "./Modules/Hrc/Pages/purchase_order_exports/purchase_exports";

function HighOrderApp() {
  const dispatch = useDispatch();
  const {
    user,
    access: {
      access_sections: { lms, hrc, admin },
    },
  } = useSelector((state: RootState) => state);

  const [opentoAdd, setOpentoAdd] = useState<opentoaddData>({
    flag: true,
    update: null,
    add: false,
  });
  // const [user, setuser] = useState<null | string>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [user_type, setuser_type] = useState<user_type_data_type | null>(null);
  const [hrc_sub_pages, sethrc_sub_pages] = useState({
    Inventory: true,
    purch_oder: true,
  });
  useEffect(() => {
    return () => {
      if (!(location.state === "private")) {
        localStorage.setItem("lastLocation", location.pathname);
        localStorage.setItem("search", location.search);
      }
    };
  }, [location.pathname]);

  useEffect(() => {
    if (user.token_id) {
      customFetchWithToken(user_type_api, user.token_id)
        .then((data) => {
          setuser_type({ ...data.DATA });
        })
        .catch(() => {
          auth.signOut();
          localStorage.clear();
          showToastMessage(false, "Cannot find your role,please contact admin");
        });
    }
  }, [user]);

  useEffect(() => {
    const unsbscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((data) => {
          dispatch(setUser({ email: user.email, token_id: data }));
        });
      } else {
        dispatch(logOutUser());
        navigate("/login");
      }
    });
    return () => unsbscribe();
  }, []);
  const handleLogin = (
    e: React.FormEvent<HTMLFormElement>,
    email: string,
    setemail: Function,
    password: string,
    setpassword: Function
  ) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        user
          .getIdToken()
          .then((data) => {
            dispatch(setUser({ email: user.email, token_id: data }));
          })
          .then(() => {
            setemail("");
            setpassword("");
          });

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/id-token-expired":
            showToastMessage(
              false,
              "please login again to change your password"
            );
            break;

          case "auth/internal-error":
            showToastMessage(
              false,
              "The Authentication server encountered an unexpected error while trying to process the request"
            );
            break;

          case "auth/invalid-argument":
            showToastMessage(false, error.message);
            break;

          case "auth/invalid-credential":
            showToastMessage(
              false,
              "Incorrect email or password ,please verify and login"
            );
            break;

          case "auth/wrong-password":
            showToastMessage(
              false,
              "Incorrect email or password ,please verify and login"
            );
            break;
          case "auth/session-cookie-expired":
            showToastMessage(
              false,
              "Please login again to change your password"
            );
            break;
          case "auth/too-many-requests":
            showToastMessage(false, "To many attempts ,try after some time");
            break;
          case "auth/user-not-found":
            showToastMessage(false, "User not found,Please contact admin");
            break;
          default:
            showToastMessage(false, "Your request has failed,please try again");
        }
      });
  };

  useEffect(() => {
    let lst_location = localStorage.getItem("lastLocation");
    let search = localStorage.getItem("search");
    if (search) {
      lst_location = `${lst_location}${search}`;
    }
    if (user_type && user) {
      if (user_type?.hasOwnProperty("ADMIN")) {
        dispatch(
          setAccessPage({
            access_sections: {
              lms: true,
              hrc: true,
              admin: true,
            },
          })
        );

        lst_location === "/" || lst_location === "/login"
          ? navigate("/home")
          : navigate(lst_location ?? "/home");
      } else if (user_type?.hasOwnProperty("HRC")) {
        dispatch(
          setAccessPage({
            access_sections: {
              lms: false,
              hrc: true,
              admin: false,
            },
          })
        );

        lst_location === "/" || lst_location === "/login"
          ? navigate("/home")
          : navigate(lst_location ?? "/home");

        for (let value of user_type.HRC) {
          if (value === "MECHANIC") {
            sethrc_sub_pages({
              ...hrc_sub_pages,
              Inventory: false,
              purch_oder: false,
            });
          }
          break;
        }
      } else if (user_type?.hasOwnProperty("SALES")) {
        dispatch(
          setAccessPage({
            access_sections: {
              lms: true,
              hrc: false,
              admin: false,
            },
          })
        );

        lst_location === "/" || lst_location === "/login"
          ? navigate("/lms")
          : navigate(lst_location ?? "/home");
      }
    }
  }, [user_type]);

  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<CircularLoader />} />
        <Route path="/login" element={<Login handleLogin={handleLogin} />} />
        {admin && <Route path="/admin" element={<AdminTools />} />}

        <Route element={<PrivateRoutes token_id={user.token_id} />}>
          <Route
            path="/home/product/doucment/:params"
            element={<PurchaseExports />}
          />
          {hrc && (
            <>
              {" "}
              <Route path="/home" element={<Home />}>
                <Route path="/home" element={<VechileIn />} />
                <Route path="/home/job-card" element={<JobCard />} />
                <Route path="/home/analytics" element={<HrcAnalytics />} />

                <Route path="/home/vehicle-data" element={<VehicleData />} />
                {hrc_sub_pages.Inventory && (
                  <Route path="/home/inventory" element={<Inventory />} />
                )}
                {hrc_sub_pages.purch_oder && (
                  <Route path="/home/purchase" element={<PurchaseOrder />} />
                )}
                <Route path="/home/transit" element={<Transit />} />
                <Route path="/home/product" element={<ProductHome />}>
                  <Route path="/home/product" element={<ProductList />} />
                  <Route path="/home/product/vendor" element={<Vendor />} />
                  <Route path="/home/product/sow" element={<Sow />} />
                </Route>
                <Route path="/home/product/add" element={<AddProduct />} />
                <Route path="/home/vendor/add" element={<AddVendor />} />
              </Route>
              <Route element={<PrivateRoutes token_id={user.token_id} />}>
                <Route path="/add-rider" element={<AddRider />} />
              </Route>
              <Route element={<PrivateRoutes token_id={user.token_id} />}>
                <Route path="/track-vehicle" element={<VehicleStatus />} />
              </Route>
            </>
          )}
          <Route path="/gt-console/pdf-view/:params" element={<PdfExports />} />
          {lms && (
            <>
              <Route element={<PrivateRoutes token_id={user.token_id} />}>
                <Route
                  path="/lms"
                  element={
                    <LmsHome
                      setOpentoAdd={setOpentoAdd}
                      opentoAdd={opentoAdd}
                    />
                  }
                >
                  <Route
                    path="/lms"
                    element={
                      <LmsAll
                        opentoAdd={opentoAdd}
                        setOpentoAdd={setOpentoAdd}
                      />
                    }
                  />
                  <Route
                    path="/lms/fresh"
                    element={
                      <Fresh
                        opentoAdd={opentoAdd}
                        setOpentoAdd={setOpentoAdd}
                      />
                    }
                  />

                  <Route
                    path="/lms/follow-up"
                    element={
                      <FollowUp
                        opentoAdd={opentoAdd}
                        setOpentoAdd={setOpentoAdd}
                      />
                    }
                  />
                  <Route
                    path="/lms/follow-up-walk-in"
                    element={
                      <FollowUpWalkin
                        opentoAdd={opentoAdd}
                        setOpentoAdd={setOpentoAdd}
                      />
                    }
                  />

                  <Route
                    path="/lms/won"
                    element={
                      <Won opentoAdd={opentoAdd} setOpentoAdd={setOpentoAdd} />
                    }
                  />
                  <Route
                    path="/lms/closed"
                    element={
                      <Closed
                        opentoAdd={opentoAdd}
                        setOpentoAdd={setOpentoAdd}
                      />
                    }
                  />
                  <Route path="/lms/customers" element={<Customer />} />

                  <Route
                    path="/lms/my-follow-up"
                    element={
                      <MyFollowTab
                        opentoAdd={opentoAdd}
                        setOpentoAdd={setOpentoAdd}
                      />
                    }
                  />
                  <Route path="/lms/analytics" element={<Analytics />} />
                  <Route
                    path="/lms/repairing-criteria"
                    element={<RepairingCriteria />}
                  />
                </Route>
              </Route>
            </>
          )}
          <Route path="*" element={<NotAccessPage />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default HighOrderApp;

const CircularLoader = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <CircularProgress size={100} color="success" />
    </div>
  );
};
// function setemail(arg0: string) {
//   throw new Error("Function not implemented.");
// }

// function setpassword(arg0: string) {
//   throw new Error("Function not implemented.");
// }
