import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { purchase_stage_menu_type } from "../../../../models/API_data";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { update_purchase_order_status } from "../../../../API/api";
import showToastMessage from "../../../../Tostify/Index";
import MenuItem from "@mui/material/MenuItem";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
interface purchaseStatusModelType {
  id: number;
  status_menu: purchase_stage_menu_type[];
  status_id: number;
  status_name: string;
}

export default function PurchaseStatusModel({
  id,
  status_menu,
  status_id,
  status_name,
}: purchaseStatusModelType) {
  const [open, setOpen] = React.useState(false);
  const [selected_state, setselected_state] = React.useState(status_id);
  const [remarks_input, setremarks_input] = React.useState("");
  console.log(id, selected_state);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let url = `${update_purchase_order_status}${id}/${selected_state}`;
    let body = JSON.stringify({
      remarks: remarks_input,
      updatedBy: 1,
    });
    customFetchWithToken(url, "", { method: "PATCH" }, body).then((_) => {
      showToastMessage(true, "success");
      handleClose();
    });
  };

  return (
    <div>
      <Chip
        label={status_name}
        sx={{ background: "rgb(165 214 167 )" }}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="flex flex-col gap-4" onSubmit={handleSumbit}>
            <TextField
              id="purchase_status"
              label="Select Status"
              color="success"
              select
              value={selected_state}
              onChange={(e) => setselected_state(Number(e.target.value))}
            >
              {status_menu.map((item) => (
                <MenuItem
                  value={item.Stage_Master_Id}
                  key={item.Stage_Master_Id}
                >
                  {item.Stage_Name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="remarks"
              multiline
              color="success"
              rows={4}
              label="Add remarks"
              value={remarks_input}
              onChange={(e) => setremarks_input(e.target.value)}
            />
            <div className="flex gap-2 self-end">
              <Button variant="text" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="text" color="success" type="submit">
                confirm
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
