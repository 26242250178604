import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "100vh",
};
interface image_view_modal_props {
  selectedFile: File | null;
}

export default function ImageViewModal({
  selectedFile,
}: image_view_modal_props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {selectedFile && (
        <img
          src={URL.createObjectURL(selectedFile)}
          alt="selectedFile"
          style={{ width: "51px", height: "51px" }}
          onClick={handleOpen}
        />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            className="absolute right-0 top-5  text-red-900 cursor-pointer"
            style={{ fontSize: "3rem", zIndex: "2000" }}
            onClick={handleClose}
          />

          {selectedFile && (
            <img
              src={URL.createObjectURL(selectedFile)}
              alt="selectedFile"
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
