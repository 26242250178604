import * as React from "react";
import ListVeiw from "../../Components/list_view/list_view";
import { useDispatch, useSelector } from "react-redux";
import VendorAccordion from "../../Components/accord_vendor/accord_vendor";
import { setvendor_list_data } from "../../../../features/hrc_actions/hrc_slice";
import { RootState } from "../../../../app/store";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import {
  hrc_update_vendor_api,
  hrc_vendor_list_api,
} from "../../../../API/api";
import { updateType, vendorModel } from "../../../../models/product_data_model";
import { Form, Formik } from "formik";
import {
  initialSeller,
  sellerSchema,
  sellerType,
} from "../../Components/Forms/vendor_form/vendor_schema";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import UpdateVendor from "../../Components/Forms/update_form/update_vendor/update_vendor";
import CloseIcon from "@mui/icons-material/Close";
import showToastMessage from "../../../../Tostify/Index";

export interface VendorProps {}

export default function Vendor(props: VendorProps) {
  const [update, setupdate] = React.useState<updateType<sellerType>>({
    flag: false,
    data: null,
    id: 0,
  });
  const {
    user: { token_id },
    hrc: {
      new_product_data: {
        vendor_list: { data, loader },
      },
    },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (token_id)
      customFetchWithToken(hrc_vendor_list_api, token_id).then((data) =>
        dispatch(setvendor_list_data([...data.DATA]))
      );
  }, [token_id]);

  return (
    <div className="flex w-full h-full gap-3">
      <div className="flex-auto w-full h-full ">
        <ListVeiw title="Vendor" path="/home/vendor/add">
          <div className="flex flex-col gap-4 flex-auto h-96 overflow-auto custom-scroll">
            {data?.map((item) => (
              <VendorAccordion vendor={item} setupdate={setupdate} />
            ))}
          </div>
        </ListVeiw>
      </div>
      <div
        className={`flex-auto h-full overflow-auto bg-white ${
          update.flag
            ? " w-[550px] visible animate__animated animate__fadeIn"
            : "w-[0] hidden animate__fadeOutRight"
        } `}
      >
        <Formik
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            if (token_id) {
              let vendor_update_url = `${hrc_update_vendor_api}${update.id}`;
              let body = JSON.stringify(values);
              customFetchWithToken(
                vendor_update_url,
                token_id,
                { method: "PATCH" },
                body
              ).then(() => {
                showToastMessage(true, "Success");
                resetForm();
                setSubmitting(false);
              });
            }
          }}
          initialValues={initialSeller}
          validationSchema={sellerSchema}
        >
          {(props) => {
            return (
              <Form className="flex flex-col gap-4 w-full p-4 ">
                <div className="flex justify-between w-full items-center ">
                  <h1 className="text-xl text-[#00aa13]">Update</h1>
                  <IconButton
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      setupdate({
                        ...update,
                        flag: false,
                      });
                    }}
                    className="w-16 h-16"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <UpdateVendor prop={props} update={update} />
                <Button variant="contained" type="submit" color="success">
                  Update
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
