import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InfoIcon from "@mui/icons-material/Info";
import packageJson from "../../../package.json";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function About() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div
        className="flex justify-between py-3 px-6 hover:bg-gray-500"
        onClick={handleOpen}
      >
        <InfoIcon />
        <span>About</span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col gap-8">
            {/* headers  */}
            <header className="flex justify-center">
              <img
                src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/hrc_lms_content/gt_fav_icon.png"
                alt="logo"
                style={{
                  width: "160px",
                  height: "160px",
                  objectFit: "cover",
                }}
              />
            </header>
            <div className="grid grid-cols-1 gap-2 justify-items-center ">
              <span>{packageJson.title}</span>
              <span>
                {packageJson.version}/{process.env.NODE_ENV}
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
