import * as Yup from "yup";
const updateSowSchema = Yup.object({
  payment_terms: Yup.string().required("Payment Terms is required"),
  validity_sow: Yup.string().required("Validity SOW is required"),
  order_projection: Yup.string().required("Order Projection is required"),
  warranty: Yup.string().required("Warranty is required"),
  terms_conditions: Yup.string().required("Terms & Condition is required"),
  time_policy: Yup.string().required("Time Policy is required"),
  cost_ownership: Yup.string().required("Cost & Ownership is required"),
  sow_reference_no: Yup.string().required("SOW Reference No. is required"),
  replacement_time: Yup.string().required("Replacement Time is required"),
  delivery_time: Yup.string().required("Delivery Time is required"),
  shipping_terms: Yup.string().required("Shipping Terms is required"),
  shipping_method: Yup.string().required("Shipping Method is required"),
  payment_method: Yup.string().required("Payment Method is required"),
});
const updateSowInitialValue: updateSowInitialValueType = {
  payment_terms: "",
  validity_sow: "",
  order_projection: "",
  warranty: "",
  terms_conditions: "",
  time_policy: "",
  cost_ownership: "",
  sow_reference_no: "",
  replacement_time: "",
  delivery_time: "",
  shipping_terms: "",
  shipping_method: "",
  payment_method: "",
};
export interface updateSowInitialValueType {
  payment_terms: string;
  validity_sow: string;
  order_projection: string;
  warranty: string;
  terms_conditions: string;
  time_policy: string;
  cost_ownership: string;
  sow_reference_no: string;
  replacement_time: string;
  delivery_time: string;
  shipping_terms: string;
  shipping_method: string;
  payment_method: string;
}
export { updateSowSchema, updateSowInitialValue };
