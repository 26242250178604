import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Inventory_table_data_type } from "../models/API_data";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 8,
  height: "85vh",
  overflow: "auto",
};

export default function BasicModal({
  setOpen,
  open,
  row,
}: {
  setOpen: Function;
  open: boolean;
  row: Inventory_table_data_type | null;
}) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-scroll">
          <i
            className="fa-solid fa-square-xmark text-green-700 text-3xl absolute right-3 top-3 cursor-pointer"
            onClick={handleClose}
          ></i>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Component Id</TableCell>
                  <TableCell align="center">Component Name</TableCell>
                  <TableCell align="center">Component Code</TableCell>
                  <TableCell align="center">Serial No</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row?.Component.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.Component_Id}
                    </TableCell>
                    <TableCell align="center">{row.Component_Name}</TableCell>
                    <TableCell align="center">{row.Component_Code}</TableCell>
                    <TableCell align="center">{row.Serial_No}</TableCell>
                    <TableCell align="center">{row.Quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
