import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import JobCardAssign from "./Job_Card_assign";
import JobCardAdd from "./Job_card_add";
import { ThemeProvider } from "@mui/material/styles";
import { custom_theme } from "../../../../custom_them/custom_them";
import { useEffect } from "react";
import {
  job_Card_table_data_url,
  Inventory_allocation_table_data,
} from "../../../../API/api";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useDispatch } from "react-redux";
import { setjob_card_data } from "../../../../features/hrc_actions/hrc_slice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function JobCard() {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { token_id } = useSelector((state: RootState) => state.user);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  let urls = [job_Card_table_data_url, Inventory_allocation_table_data];
  useEffect(() => {
    if (token_id) {
      Promise.all(urls.map((url) => customFetchWithToken(url, token_id))).then(
        (response) => {
          const [res1, res2] = response;
          let data = {
            job_card_table_data: res1.DATA,
            assign_inventory_table_data: res2.DATA,
          };
          dispatch(setjob_card_data(data));
        }
      );
    }
  }, [token_id]);

  return (
    <Box sx={{ width: "100%" }} className="mt-16">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <ThemeProvider theme={custom_theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Create Job Card" {...a11yProps(0)} />
            <Tab label="Assign Inventory" {...a11yProps(1)} />
          </Tabs>
        </ThemeProvider>
      </Box>
      <TabPanel value={value} index={0}>
        <JobCardAdd />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <JobCardAssign />
      </TabPanel>
    </Box>
  );
}
