import { createTheme } from "@mui/material/styles";

const custom_theme = createTheme({
  palette: {
    primary: {
      main: "#00aa13",
    },
    secondary: {
      main: "#f44336",
    },
  },
});
export { custom_theme };
