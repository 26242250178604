import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface initial_state_type {
  user_email: null | string;
  token_id: null | string;
  user_type: payload_action_user_cat_type | null;
}
export interface payload_action_user_cred_type {
  email: string | null;
  token_id: string;
}
export interface payload_action_user_cat_type {
  SALES: [];
  HRC: [];
  ADMIN: [];
}

const initialState: initial_state_type = {
  user_email: null,
  token_id: null,
  user_type: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<payload_action_user_cred_type>) => {
      state.user_email = action.payload.email;
      state.token_id = action.payload.token_id;
    },
    logOutUser: (state) => {
      state.user_email = null;
      state.token_id = null;
    },
    setUserType: (
      state,
      action: PayloadAction<payload_action_user_cat_type>
    ) => {
      state.user_type = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, logOutUser, setUserType } = userSlice.actions;

export default userSlice.reducer;
