import React, { useRef } from "react";
import AWS from "aws-sdk";
import { ErrorMessage, Field, FormikProps } from "formik";
import { productType } from "../Forms/product_form/product_schema";
import Button from "@mui/material/Button";
import showToastMessage from "../../../../Tostify/Index";
const s3 = new AWS.S3({
  accessKeyId: "AKIATDLJCIZ3SO4ZWW46",
  secretAccessKey: "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",
  region: "ap-south-1",
});
interface ImageInputProps {
  props: FormikProps<productType>;
}
export default function ImageInput({ props }: ImageInputProps) {
  const [progress, setProgress] = React.useState(0);
  const { setFieldValue, values, handleBlur, touched, errors } = props;
  const [imgPreviw, setimgPreviw] = React.useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      if (files[0].type !== "image/jpeg" && files[0].type !== "image/png") {
        alert("File must be a JPEG or PNG image");
        return false;
      }
      if (files[0].size > 5000000) {
        alert("File size must be less than 5MB");
        return false;
      }
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setimgPreviw(reader.result as string);
      };
    }
  };
  const handleImageUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (inputRef.current?.files?.[0]) {
      const imageFile = inputRef.current.files?.[0];
      const folderName = "productImage";
      const params = {
        Bucket: "greentiger.in-content",
        Key: `${folderName}/${imageFile.name}`,
        Body: imageFile,
        ContentType: imageFile.type,
        ACL: "public-read",
      };
      try {
        const response = await s3
          .upload(params)
          .on("httpUploadProgress", (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          })
          .promise();
        setFieldValue("Image", [response.Location]);
        showToastMessage(true, "Image uploaded successfully!");
      } catch (error) {
        showToastMessage(false, "something went wrong");
      } finally {
        inputRef.current.value = "";
        setProgress(0);
      }
    } else if (imgPreviw) {
      showToastMessage(
        false,
        "This image is uploaded Successfully,Pleas select  again to upload"
      );
    } else {
      showToastMessage(false, "Please Select image to upload");
    }
  };
  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div className="image-upload flex justify-center items-center relative rounded-md h-full w-full bg-gray-100 overflow-hidden">
        <label htmlFor="Image" className="relative w-full h-full">
          <input
            type="file"
            id="Image"
            name="Image"
            accept="image/*"
            className="sr-only"
            ref={inputRef}
            onChange={handleImageSelect}
            onBlur={handleBlur}
          />
          {imgPreviw && (
            <img
              id="preview"
              className="h-full w-full object-cover"
              src={imgPreviw}
              alt="Selected image"
            />
          )}
          {!imgPreviw && (
            <span className="upload-icon absolute inset-0 flex items-center justify-center opacity-25 hover:opacity-75 transition-opacity duration-300 bg-black bg-opacity-75 text-white">
              <i className="fa fa-camera fa-4x"></i>
            </span>
          )}
        </label>
      </div>
      {touched.Image && errors.Image ? (
        <div>
          <span className="text-red-900">{errors.Image}</span>
        </div>
      ) : null}
      <Button
        variant="contained"
        color="success"
        disabled={progress !== 0}
        onClick={handleImageUpload}
      >
        Upload <span className="ml-2"> {progress !== 0 && progress}</span>
      </Button>
    </div>
  );
}
