import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material";
import { custom_theme } from "../../../../custom_them/custom_them";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { hrc_shipping_details_menu } from "../../../../API/api";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import { setshipping_method_menu } from "../../../../features/hrc_actions/hrc_slice";
import { ToastContainer } from "react-toastify";
interface TabPanelProps {}
const product_tabs = [
  { id: 1, label: "Product", path: "/home/product" },
  { id: 2, label: "Vendor", path: "/home/product/vendor" },
  { id: 3, label: "Sow", path: "/home/product/sow" },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductHome() {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);
  const [value, setValue] = React.useState(0);
  React.useMemo(() => {
    switch (pathname) {
      case product_tabs[0].path:
        setValue(0);
        break;
      case product_tabs[1].path:
        setValue(1);
        break;
      case product_tabs[2].path:
        setValue(2);
        break;
      default:
        setValue(0);
    }
  }, [pathname]);
  // React.useEffect(() => {
  //   if (token_id) {
  //     customFetchWithToken(hrc_shipping_details_menu, token_id).then(
  //       (data) => {
  //         console.log(data.DATA);
  //       }
  //       // dispatch(setshipping_method_menu([...data.DATA]))
  //     );
  //   }
  // }, [token_id]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={custom_theme}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#ECEFF1",
          padding: "1rem 0rem 0rem 1rem",
          paddingBottom: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="primary"
            indicatorColor="primary"
          >
            {product_tabs.map((item, index) => {
              return (
                <Tab
                  label={item.label}
                  {...a11yProps(index)}
                  component={Link}
                  to={item.path}
                  key={item.label}
                />
              );
            })}
          </Tabs>
        </Box>
        <div className="w-full h-full flex-auto overflow-hidden ">
          <ToastContainer />
          <Outlet />
        </div>
      </Box>
    </ThemeProvider>
  );
}
