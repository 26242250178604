import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import Typography from "@mui/material/Typography";
import { stepper_data_type } from "../../../../models/API_data";
import { useState, useEffect } from "react";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00aa13",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00aa13",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#00aa13",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00aa13",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}
const steps = [
  {
    label: "Lead",
  },
  {
    label: "Inspection",
  },

  {
    label: "HRC",
  },
  {
    label: "Conversion Process",
  },
  {
    label: "Vehicle Out",
  },
];
interface vehicle_track_props {
  data: stepper_data_type[] | [];
  reg_input: string;
}

export default function VehicleTrack({ data, reg_input }: vehicle_track_props) {
  const [activeStep, setActiveStep] = useState(0);
  const [desc, setdesc] = useState("");
  useEffect(() => {
    if (data[0].Stage_number === 9) {
      setdesc(data[0].Details);
      setActiveStep(5);
    } else if (data[0].Stage_number === 7 || data[0].Stage_number === 8) {
      setdesc(data[0].Details);
      setActiveStep(4);
    } else if (
      data[0].Stage_number === 5 ||
      data[0].Stage_number === 6 ||
      data[0].Stage_number === 32 ||
      data[0].Stage_number === 33 ||
      data[0].Stage_number === 34 ||
      data[0].Stage_number === 35 ||
      data[0].Stage_number === 36 ||
      data[0].Stage_number === 28 ||
      data[0].Stage_number === 29 ||
      data[0].Stage_number === 4
    ) {
      setdesc(data[0].Details);
      setActiveStep(3);
    } else if (
      data[0].Stage_number === 1 ||
      data[0].Stage_number === 2 ||
      data[0].Stage_number === 3
    ) {
      setdesc(data[0].Details);
      setActiveStep(2);
    } else if (
      data[0].Stage_number === 10 ||
      data[0].Stage_number === 14 ||
      data[0].Stage_number === 11 ||
      data[0].Stage_number === 16 ||
      data[0].Stage_number === 15
    ) {
      setdesc(data[0].Details);
      setActiveStep(2);
    }
  }, [reg_input, data]);
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label, i) => (
          <Step key={label.label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              {label.label}
            </StepLabel>

            {activeStep === i && (
              <div className="">
                <Typography>{desc}</Typography>
              </div>
            )}
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
