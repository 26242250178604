import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { lmsData } from "../../models/lms_data_api";
import produce from "immer";
import {
  lms_dropdown_lead_source_data,
  lms_lead_status_drop_menu_type,
  lms_vehicle_brand_menu_type,
  lms_state_menu_type,
  wheel_type_drp_menu_data_type,
  lms_profession_drpdwn_type,
  lms_gender_drpdwn_type,
  repair_data_type,
  lms_user_data_type,
} from "../../models/API_data";
interface setLmsDataProps {
  data?: lmsData[];
  leadType: number[];
}
export interface initialStateType {
  lmsData: {
    all: null | lmsData[];
    fresh: null | lmsData[];
    followUp: null | lmsData[];
    won: null | lmsData[];
    closed: null | lmsData[];
    allData: {
      data: null | lmsData[];
    };
    b2c: {
      data: null | lmsData[];
    };
    franchise: {
      data: null | lmsData[];
    };
    b2b: {
      data: null | lmsData[];
    };
  };
  appData: {
    dataAccToPage: null | lmsData[];
    loader: boolean;
    message: string;
    length: number | null;
    filterSearch: lmsData[] | null;
  };
  historyData: {
    data: null | lmsData[];
  };
  assignedData: {
    dataToAssigned: [] | number[];
  };
  followUpWalkIn: {
    appData: null | lmsData[];
    loader: boolean;
  };
  lmsForm: {
    dropDownData: formDropdownPayload;
    loader: boolean;
  };
  repairingCriteria: {
    appData: null | repair_data_type[];
    loader: boolean;
  };

  lmsUsers: {
    users: null | lms_user_data_type[];
  };
}
const initialState: initialStateType = {
  lmsData: {
    all: null,
    fresh: null,
    followUp: null,
    won: null,
    closed: null,
    allData: {
      data: null,
    },
    b2c: {
      data: null,
    },
    franchise: {
      data: null,
    },
    b2b: {
      data: null,
    },
  },
  appData: {
    dataAccToPage: null,
    length: null,
    loader: true,
    message: "No records",
    filterSearch: null,
  },
  historyData: {
    data: null,
  },
  assignedData: {
    dataToAssigned: [],
  },
  followUpWalkIn: {
    appData: null,
    loader: true,
  },
  lmsForm: {
    dropDownData: {
      leadSource: null,
      leadStatus: null,
      vehiclBrand: null,
      state: null,
      wheelType: null,
      profession: null,
      gender: null,
    },
    loader: true,
  },
  repairingCriteria: {
    appData: null,
    loader: true,
  },
  lmsUsers: {
    users: null,
  },
};
export interface formDropdownPayload {
  leadSource: lms_dropdown_lead_source_data[] | null;
  leadStatus: lms_lead_status_drop_menu_type[] | null;
  vehiclBrand: lms_vehicle_brand_menu_type[] | null;
  state: lms_state_menu_type[] | null;
  wheelType: wheel_type_drp_menu_data_type[] | null;
  profession: lms_profession_drpdwn_type[] | null;
  gender: lms_gender_drpdwn_type[] | null;
}

export interface paginationPayload {
  data: lmsData[] | [];
  message: string;
  length: number;
  filterSearch: lmsData[] | null;
}
export interface lms_form_drpdwn_payload_data_type {
  DATA: any[];
  MESSAGE: string;
  SUCCESS: boolean;
}

const handleFilter = (LeadStatus: number, state: lmsData[]): any[] => {
  // Validate the input parameter
  if (typeof LeadStatus !== "number") {
    throw new Error("LeadStatus parameter should be a number");
  }

  // copy all leads
  let all_copied_leads = [...state];

  // Use filter method for filtering
  let filter_data = all_copied_leads.filter(
    (item) => item.LeadStatus === LeadStatus
  );

  // return filtered array
  return filter_data;
};
//  lead types
const lead_types = {
  b2b: 1,
  b2c: 2,
  franchise: 127,
};

export const lmsSlice = createSlice({
  name: "lms",
  initialState,
  reducers: {
    setLmsData: (state, action: PayloadAction<setLmsDataProps>) => {
      if (action.payload.data) {
        state.lmsData.allData.data = action.payload?.data;
      }
      const allData =
        state.lmsData.allData.data?.filter((item) => {
          if (action.payload.leadType.indexOf(item.LeadTypeId) !== -1) {
            return item;
          }
        }) || [];

      state.lmsData.all = allData;
      state.lmsData.fresh = handleFilter(10, allData);
      state.lmsData.won = allData.filter((item) =>
        [14, 108, 106, 109].includes(item.LeadStatus)
      );
      state.lmsData.followUp = allData.filter((item) =>
        [11, 18, 19, 20, 21, 22, 23, 24, 31, 43, 45].includes(item.LeadStatus)
      );
      state.lmsData.closed = allData.filter((item) =>
        [15, 25, 26, 27, 30, 37, 44, 107].includes(item.LeadStatus)
      );
    },
    updateLmsData: (state, action: PayloadAction<lmsData>) => {
      let {
        lmsData: { all },
      } = state;
      let updated_data = action.payload;

      // Renamed variable to match its definition
      if (all) {
        let index = all.findIndex(
          (obj: lmsData) => obj.LeadId === updated_data?.LeadId
          // Changed comparison to loose equality
        );
        if (index >= 0) {
          all[index] = { ...all[index], ...updated_data };
          // Fixed spread operator syntax for objects
        }
      }
    },
    setLmsFromDropdown: (state, action: PayloadAction<formDropdownPayload>) => {
      return {
        ...state,
        lmsForm: {
          ...state.lmsForm,
          dropDownData: { ...action.payload },
          loader: false,
        },
      };
    },
    setAssignData: (state, action: PayloadAction<number[]>) => {
      return {
        ...state,
        assignedData: {
          ...state.assignedData,
          dataToAssigned: [...action.payload],
        },
      };
    },
    setappData: (state, action: PayloadAction<paginationPayload>) => {
      return produce(state, (draft) => {
        draft.appData.loader = true;
        draft.appData.dataAccToPage = action.payload.data;
        draft.appData.message = action.payload.message;
        draft.appData.length = action.payload.length;
        draft.appData.loader = false;
        draft.appData.filterSearch = action.payload.filterSearch;
      });
    },
    setHistoryData: (state, action: PayloadAction<lmsData[] | null>) => {
      return produce(state, (draft) => {
        draft.historyData.data = action.payload;
      });
    },
    setfollowUpWalkIn: (state, action: PayloadAction<lmsData[]>) => {
      return {
        ...state,
        followUpWalkIn: {
          ...state.followUpWalkIn,
          appData: action.payload,
          loader: false,
        },
      };
    },
    setrepairingCriteria: (
      state,
      action: PayloadAction<repair_data_type[]>
    ) => {
      state.repairingCriteria.appData = action.payload;
      state.repairingCriteria.loader = false;
    },
    setLmsUserData: (state, action: PayloadAction<lms_user_data_type[]>) => {
      return {
        ...state,
        lmsUsers: {
          ...state.lmsUsers,
          users: [...action.payload],
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLmsData,
  updateLmsData,
  setLmsFromDropdown,
  setappData,
  setLmsUserData,
  setfollowUpWalkIn,
  setrepairingCriteria,
  setAssignData,
  setHistoryData,
} = lmsSlice.actions;

export default lmsSlice.reducer;
