import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivateRoutes = ({ token_id }: { token_id: string | null }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      let url = `${location.pathname}${location.search}`;
      localStorage.setItem("lastLocation", url);
    } else {
      localStorage.setItem("lastLocation", location.pathname);
    }
  }, []);
  return token_id ? <Outlet /> : <Navigate to={"/"} state={"private"} />;
};

export default PrivateRoutes;
