import React, { useState } from "react";
import { Button, Checkbox, Input } from "@material-tailwind/react";

type LogHomeProps = {
  handleLogin: (
    e: React.FormEvent<HTMLFormElement>,
    email: string,
    setemail: Function,
    password: string,
    setpassword: Function
  ) => void;
  setforgot_page: Function;
};

const LogHome: React.FC<LogHomeProps> = ({ handleLogin, setforgot_page }) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  return (
    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
        Sign in to your account
      </h1>
      <form
        className="space-y-4 md:space-y-6"
        action="#"
        onSubmit={(e) => handleLogin(e, email, setemail, password, setpassword)}
      >
        <div>
          <Input
            label="Email Address"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div>
          <Input
            label="Password"
            type={"password"}
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-between sm:flex-row flex-col">
          <div className="flex items-start">
            <Checkbox label="Remember Me" defaultChecked />
          </div>
          <a
            href="..."
            className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
            onClick={(e) => {
              e.preventDefault();
              setforgot_page(true);
            }}
          >
            Forgot Password?
          </a>
        </div>
        <Button fullWidth type="submit">
          Sign in
        </Button>
        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          Don’t have an account yet? &nbsp;
          <a
            href="/"
            className="font-medium text-primary-600 hover:underline dark:text-primary-500"
          >
            Sign up
          </a>
        </p>
      </form>
    </div>
  );
};

export default LogHome;
