import * as Yup from "yup";
import dayjs, { Dayjs } from "dayjs";
export interface productType {
  Product_Name: string;
  Product_Code: string;
  hasSerial: number;
  weight: number;
  hsn_no: string;
  price_per_unit: number;
  category: number | null;
  discount: number;
  description: string;
  tax: number;
  sgst: number;
  cgst: number;
  igst: number;
  Image: Array<string> | null;
  lead_time: number;
  payment_terms: string;
  ratings: null | number;
  currency: number | null;
  createdBy: number;
  updatedBy: number;
}
const ProductSchema = Yup.object().shape({
  Product_Name: Yup.string().required("Please enter product name"),
  Product_Code: Yup.string().required("Please enter product code"),
  hasSerial: Yup.number().required("Please specify if the product has serial"),
  weight: Yup.number().required("Please enter product weight"),
  hsn_no: Yup.string().required("Please enter HSN number"),
  price_per_unit: Yup.number().required("Please enter price per unit"),
  category: Yup.number().required("Please enter category"),
  discount: Yup.number().required("Please enter discount"),
  description: Yup.string().required("Please enter product description"),
  tax: Yup.number().required("Please enter tax"),
  sgst: Yup.number().required("Please enter SGST"),
  cgst: Yup.number().required("Please enter CGST"),
  igst: Yup.number().required("Please enter IGST"),
  Image: Yup.array().of(Yup.string()).required("Please enter product images"),
  lead_time: Yup.number().required("Please enter lead time"),
  payment_terms: Yup.string()
    .required("Please enter payment terms")
    .matches(/^(100|[1-9]?[0-9])$/, "please enter number between 0 to 100"),
  ratings: Yup.number().required("Please enter product ratings"),
  currency: Yup.number().required("Please enter currency"),
  createdBy: Yup.number().required("Please enter createdBy"),
  updatedBy: Yup.number().required("Please enter updatedBy"),
});

const initialProduct: productType = {
  Product_Name: "",
  Product_Code: "z",
  hasSerial: 0,
  weight: 0,
  hsn_no: "12345678",
  price_per_unit: 0,
  category: null,
  discount: 0,
  description: "Product XYZ - High-quality electronics",
  tax: 0,
  sgst: 0,
  cgst: 0,
  igst: 0,
  Image: null,
  lead_time: 5,
  payment_terms: "",
  ratings: null,
  currency: null,
  createdBy: 1,
  updatedBy: 1,
};
export { ProductSchema, initialProduct };
