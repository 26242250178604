interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "Registration_No", label: "Vehicle Reg No", minWidth: 100 },
  { id: "RiderName", label: "Rider Name", minWidth: 100 },
  {
    id: "MobileNumber",
    label: "Mobile Number",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "ManufacturingYear",
    label: "Mfg Yr",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "VehicleColor",
    label: "Vehicle Color",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Model_Name",
    label: "Model Name",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Vehicle_In_Time",
    label: "Vehicle In Time",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "UpdatedOn",
    label: "Updated On",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "kms_driven",
    label: "Kms Driven",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];
export { columns };
