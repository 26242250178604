import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { objectSize } from "pdfjs-dist/types/src/shared/util";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
    fontFamily: "Helvetica",
  },
  header: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleBody: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    textAlign: "center",
    border: "2px solid red",
  },
  logo: {
    width: 150,
    height: 150,
    objectFit: "cover",
    objectPosition: "center",
  },
  body: {
    flex: 1,
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: "justify",
  },
});

type Props = {};

const PDFDocument = (props: Props) => {
  return (
    <PDFViewer className="w-full h-screen">
      <Document>
        <Page style={styles.page}>
          <View style={styles.header}>
            <Image
              style={styles.logo}
              src="https://images-platform.99static.com//UV5Ow-bShpT5vwHe04T-w_E3WmA=/0x0:960x960/fit-in/500x500/99designs-contests-attachments/89/89967/attachment_89967959"
            />
            <View style={styles.titleBody}>
              <Text style={styles.title}>Company Name</Text>
            </View>
          </View>
          <View style={styles.body}>
            <Text>
              This is the body of the PDF document. You can add any amount of
              information here.
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFDocument;
