import TopNav from "../../Components/top_nav/top_nav";
import NavContent from "../../../../Global_Component/nav_content/nav_content";
import SidebarMenu from "../../../../data/rider_side_menu";
import { useState, useEffect } from "react";
import RiderAccordion from "../../Components/Rider_accord/Rider_accord";
import customFetch from "../../../../API/CustomFetch";
import { rider_data_url } from "../../../../API/api";
import RiderForm from "../../Components/Rider_Form/Rider_Form";
import MobileNavComponent from "../../Components/mobile_nav/mobile_nav";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import { setrider_data } from "../../../../features/hrc_actions/hrc_slice";
import CircularProgress from "@mui/material/CircularProgress";

const AddRider = () => {
  const [openNav, setOpenNav] = useState(false);
  const [close_side_nav, setclose_side_nav] = useState(false);
  const dispatch = useDispatch();
  const {
    rider: {
      data: { rider_data },
      loader,
    },
  } = useSelector((state: RootState) => state.hrc);
  const toggleNav = () => {
    setOpenNav((openNav) => !openNav);
  };

  useEffect(() => {
    customFetch(rider_data_url).then((data) =>
      dispatch(setrider_data(data.DATA))
    );
    if (window.innerWidth > 960) {
      setOpenNav(false);
      setclose_side_nav(true);
    }
  }, []);
  const [drop_show, setdrop_show] = useState(false);
  if (loader)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <CircularProgress color="success" size={100} />
      </div>
    );
  return (
    <div className="w-full h-screen flex flex-col relative  ">
      <div className="flex-none h-18 ">
        <TopNav
          openTopNav={openNav}
          setTopNav={toggleNav}
          setdrop_show={setdrop_show}
        />
        <MobileNavComponent openSideNav={openNav} setSideNav={toggleNav}>
          <NavContent
            SidebarMenu={SidebarMenu}
            lms={true}
            close_side_nav={close_side_nav}
            setSideNav={toggleNav}
          />
        </MobileNavComponent>
      </div>
      <div className="h-48 flex-auto flex  ">
        {close_side_nav && (
          <div className=" w-48 flex-auto h-full  shadow-2xl backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-white/80 bg-white ">
            <NavContent
              SidebarMenu={SidebarMenu}
              lms={true}
              close_side_nav={close_side_nav}
              setSideNav={toggleNav}
            />
          </div>
        )}
        <div className="w-full flex-auto h-full  flex flex-col  items-center  bg-green-50 overflow-auto  ">
          {rider_data?.length ?? -1 > 0 ? (
            <div className="lg:w-[90%] md:w-[98%] sm:w-[576px] h-48 flex-auto flex flex-col gap-8 mt-10  overflow-auto custom-scroll ">
              {rider_data?.map((item) => (
                <RiderAccordion key={item.RiderProfileId} item={item} />
              ))}
            </div>
          ) : (
            <div className="h-full w-full flex justify-center items-center">
              <h1 className="text-2xl text-red-900 tracking-widest">
                No any available lead
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          )}
        </div>
      </div>
      <div className="absolute right-6 bottom-10 z-10">
        <RiderForm />
      </div>
    </div>
  );
};
export default AddRider;
