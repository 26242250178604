import * as React from "react";
import VendorForm from "../../Components/Forms/vendor_form/vendor_form";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import {
  initialSeller,
  sellerSchema,
} from "../../Components/Forms/vendor_form/vendor_schema";

import { Form, Formik } from "formik";
import { categoryModel } from "../../../../models/product_data_model";
import { RootState } from "../../../../app/store";

import { useEffect, useState } from "react";

import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { hrc_add_vendor_api, hrc_category_menu_api } from "../../../../API/api";
import showToastMessage from "../../../../Tostify/Index";
import Button from "@mui/material/Button";

export interface AddVendorProps {}
const steps = ["Vendor Details", "SOW Details"];

export default function AddVendor(props: AddVendorProps) {
  const navigate = useNavigate();
  const [category_menu, setcategory_menu] = useState<categoryModel[] | []>([]);

  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (token_id)
      customFetchWithToken(hrc_category_menu_api, token_id).then((data) =>
        setcategory_menu([...data.DATA])
      );
  }, [token_id]);

  return (
    <div className="w-full h-full p-8 relative ">
      <Formik
        initialValues={initialSeller}
        validationSchema={sellerSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          setSubmitting(true);
          let body = JSON.stringify(values);
          if (token_id) {
            customFetchWithToken(
              hrc_add_vendor_api,
              token_id,
              { method: "POST" },
              body
            )
              .then(() => showToastMessage(true, "Success"))
              .then(() => {
                resetForm();
                setSubmitting(false);
              })
              .catch((response) => showToastMessage(false, response.MESSAGE));
          }
        }}
      >
        {(props) => {
          const { handleChange, values, errors, touched } = props;
          return (
            <Form className="flex flex-col gap-4">
              <TextField
                id="product_category"
                name="product_category"
                label="Select Product Category"
                select
                value={values.product_category}
                onChange={handleChange}
                sx={{ width: "220px" }}
                error={
                  touched.product_category && Boolean(errors.product_category)
                }
                helperText={touched.product_category && errors.product_category}
              >
                {category_menu.map((item) => (
                  <MenuItem value={item.TypeMasterId}>{item.TypeName}</MenuItem>
                ))}
              </TextField>
              <VendorForm props={props} selected_vendor="Add" />
              <Button
                variant="contained"
                color="success"
                type="submit"
                className="self-end"
              >
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik>
      <div className="absolute right-5 top-5 ">
        <Tooltip title="list" placement="bottom">
          <IconButton onClick={() => navigate("/home/product/vendor")}>
            <i className="fa-sharp fa-solid fa-list text-3xl text-[#00aa13]"></i>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
