import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import customFetch from "../../../../API/CustomFetch";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/ar-sa";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  conversion_centers_api,
  experience_centers_api,
  all_vehicle_reg_api,
  all_users_api,
  post_vehicle_in_api,
} from "../../../../API/api";
import {
  centers_data_type,
  user_dropmenu_data_type,
  trans_vehicle_data_type,
  tech_options_type,
} from "../../../../models/API_data";
import showToastMessage from "../../../../Tostify/Index";
import { ToastContainer } from "react-toastify";
import { job_Card_dropdown_techinician_url } from "../../../../API/api";
type TranFormProps = {
  center_type: number;
  form_data: {
    open_form: boolean;
    item_t_add: trans_vehicle_data_type | null;
  };
};
interface dropdown_api_type {
  VehicleProfileId: number;
  Registration_No: string;
}

const TranForm: React.FC<TranFormProps> = ({ center_type, form_data }) => {
  const [age, setAge] = useState("");

  const locales = ["en", "ru", "ar-sa"] as const;
  const [locale, setLocale] = React.useState<typeof locales[number]>("en");
  const [value, setValue] = React.useState<Dayjs | null>(null);

  const [ampm, setAmpm] = React.useState<boolean | undefined>(undefined);
  const [exp_centers, setExp_centers] = useState<[] | centers_data_type[]>([]);
  const [user_menu, setuser_menu] = useState<[] | tech_options_type[]>([]);
  const [conv_centers, setConv_centers] = useState<[] | centers_data_type[]>(
    []
  );
  const [source_location, setsource_location] = useState<
    [] | centers_data_type[]
  >([]);
  const [destination_location, setdestination_location] = useState<
    [] | centers_data_type[]
  >([]);
  const [reg_menu, setreg_menu] = useState<[] | dropdown_api_type[]>([]);

  const [user_input, setuser_input] = useState("");
  const [reg_input, setreg_input] = useState("");
  const [source_input, setsource_input] = useState("");
  const [destination_input, setdestination_input] = useState("");

  useEffect(() => {
    customFetch(experience_centers_api).then((data) =>
      setExp_centers([...data.DATA])
    );
    customFetch(conversion_centers_api).then((data) =>
      setConv_centers([...data.DATA])
    );
    customFetch(all_vehicle_reg_api).then((data) =>
      setreg_menu([...data.DATA])
    );
    let technician_url = `${job_Card_dropdown_techinician_url}1`;
    customFetch(technician_url).then((data) => setuser_menu([...data.DATA]));

    // setConv_centers(form_data)
  }, []);
  useEffect(() => {
    if (center_type === 1) {
      setsource_location([...exp_centers]);
      setdestination_location([...conv_centers]);
    } else if (center_type === 2) {
      setsource_location([...conv_centers]);
      setdestination_location([...exp_centers]);
    }
  }, [conv_centers, exp_centers]);
  useEffect(() => {
    setreg_input(form_data?.item_t_add?.VehilceId.toString() ?? "");

    if (center_type === 1) {
      setdestination_input(form_data?.item_t_add?.Dest_VI.toString() ?? "");
      setsource_input(form_data?.item_t_add?.Source_VI.toString() ?? "");
    } else if (center_type === 2) {
      setdestination_input(form_data?.item_t_add?.Dest_VO.toString() ?? "");
      setsource_input(form_data?.item_t_add?.Source_VO.toString() ?? "");
    }
  }, [form_data]);
  const handelSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data_to_send = {
      UserId_VI: user_input,
      VehilceId: reg_input,
      ScheduledTimeSlot_VI: value,
      Source_VI: source_input,
      Dest_VI: destination_input,
    };
    let body = JSON.stringify(data_to_send);
    customFetch(post_vehicle_in_api, body, { method: "PUT" }).then((data) =>
      showToastMessage(data.SUCCESS, data.MESSAGE)
    );
  };

  return (
    <div className="w-full">
      <ToastContainer />
      <form onSubmit={handelSubmit}>
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-5  ">
            <Box sx={{ minWidth: 250, width: 350 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" color="success">
                  User
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={user_input}
                  label="User"
                  onChange={(e) => setuser_input(e.target.value)}
                  color="success"
                >
                  {user_menu.map((item) => (
                    <MenuItem
                      value={item.technician_Id}
                      key={item.technician_Id}
                    >
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 250, width: 350 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" color="success">
                  Reg No
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reg_input}
                  label="Reg No"
                  color="success"
                  onChange={(e) => setreg_input(e.target.value)}
                >
                  {reg_menu.map((item) => (
                    <MenuItem
                      value={item.VehicleProfileId}
                      key={item.VehicleProfileId}
                    >
                      {item.Registration_No}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 250, width: 350 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" color="success">
                  Source Location
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={source_input}
                  label="Source Location"
                  color="success"
                  onChange={(e) => setsource_input(e.target.value)}
                >
                  {source_location.map((item) => (
                    <MenuItem value={item.Hrc_Id} key={item.Code}>
                      {item.Location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 250, width: 350 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" color="success">
                  Destination
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={destination_input}
                  label="Destination"
                  color="success"
                  onChange={(e) => setdestination_input(e.target.value)}
                >
                  {destination_location.map((item) => (
                    <MenuItem value={item.Hrc_Id} key={item.Code}>
                      {item.Location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <DateTimePicker
                value={value}
                onChange={(newValue) => setValue(newValue)}
                renderInput={(params) => (
                  <TextField {...params} color="success" />
                )}
                ampm={ampm}
                minutesStep={60}
                minDateTime={dayjs()}

                // maxTime={dayjs()
                //   .set("hour", 18)
                //   .set("minute", 0)
                //   .set("second", 0)}
              />
            </LocalizationProvider>
          </div>
          <div className="">
            <Fab variant="extended" color="success" type="submit">
              <NavigationIcon sx={{ mr: 1 }} />
              Submit
            </Fab>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TranForm;
