import { Dayjs } from "dayjs";
import { lmsData } from "../models/lms_data_api";
import { sub_lebel_options_type } from "../Modules/lms/Components/lms_filter/filter_popover";

class lmsFilter {
  original_data: lmsData[] | null;
  constructor(data: lmsData[] | null) {
    this.original_data = data;
  }

  filterByTodayDate(selected_chips: string[], data?: lmsData[]) {
    let Today = new Date().setHours(0, 0, 0, 0);
    if (data) {
      return data.filter((item) => {
        for (let key of selected_chips) {
          if (new Date(item[key]).setHours(0, 0, 0, 0) === Today) {
            return item;
          }
        }
      });
    } else {
      return this.original_data?.filter((item) => {
        for (let key of selected_chips) {
          if (new Date(item[key]).setHours(0, 0, 0, 0) === Today) {
            return item;
          }
        }
      });
    }
  }
  filterByDateRange(
    date_type: string[],
    date_input1: Dayjs | null,
    date_input2: Dayjs | null,
    data?: lmsData[]
  ) {
    if (data) {
      return data.filter((item) => {
        for (let key of date_type) {
          if (
            new Date(item[key]).setHours(0, 0, 0, 0) >=
              new Date(date_input1?.toString() ?? "").setHours(0, 0, 0, 0) &&
            new Date(item[key]).setHours(0, 0, 0, 0) <=
              new Date(date_input2?.toString() ?? "").setHours(0, 0, 0, 0)
          ) {
            console.log(item);
            return item;
          }
        }
      });
    } else {
      return this.original_data?.filter((item) => {
        for (let key of date_type) {
          if (
            new Date(item[key]).setHours(0, 0, 0, 0) >=
              new Date(date_input1?.toString() ?? "").setHours(0, 0, 0, 0) &&
            new Date(item[key]).setHours(0, 0, 0, 0) <=
              new Date(date_input2?.toString() ?? "").setHours(0, 0, 0, 0)
          ) {
            return item;
          }
        }
      });
    }
  }
  filterByUser(user_selected: [] | sub_lebel_options_type[], data?: lmsData[]) {
    if (data) {
      return data.filter(
        (item) =>
          user_selected.findIndex((user) => user.id === item.AssignedTo) !== -1
      );
    } else {
      return this.original_data?.filter(
        (item) =>
          user_selected.findIndex((user) => user.id === item.AssignedTo) !== -1
      );
    }
  }
  filterByStage(
    stage_selected: [] | sub_lebel_options_type[],
    data?: lmsData[]
  ) {
    if (data) {
      return data.filter(
        (item) =>
          stage_selected.findIndex((stage) => stage.id === item.LeadStatus) !==
          -1
      );
    } else {
      return this.original_data?.filter(
        (item) =>
          stage_selected.findIndex((stage) => stage.id === item.LeadStatus) !==
          -1
      );
    }
  }
}

export default lmsFilter;
