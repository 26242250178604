import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import Checkbox from "@mui/material/Checkbox";
import useInput from "../../../../API/CustomInput";
import { useState, useEffect } from "react";
import { baseUrl, add_rider_url } from "../../../../API/api";
import { lmsData } from "../../../../models/lms_data_api";
import customFetch from "../../../../API/CustomFetch";
import CircularProgress from "@mui/material/CircularProgress";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "75%",

  "@media (max-width: 998px)": {
    width: "80%",
  },
  "@media (max-width: 576px)": {
    width: "95%",
    p: 0,
  },
};

export default function RiderForm() {
  const [open, setOpen] = useState(false);

  const [open_form, setOpen_form] = useState(false);
  const [lead_details, setLead_details] = useState<[] | lmsData[]>([]);

  const reg_no_input = useInput("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handelContinue = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(reg_no_input.value === "")) {
      setOpen_form(true);
      let url = `${baseUrl}/rider/v1/get-rider/${reg_no_input.value}`;
      customFetch(url).then((data) => {
        setLead_details([...data.DATA]);
        setOpen_form(true);
      });
    }
  };

  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ backgroundColor: "#00aa13" }}
        onClick={handleOpen}
      >
        <AddIcon />
      </Fab>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col h-full shadow-2xl shadow-green-700 p-4 backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 overflow-auto custom-scroll">
            <CloseIcon
              sx={{
                color: "#00aa13",
                alignSelf: "flex-end",
                fontSize: "40px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            {!open_form ? (
              <div className="w-full h-full">
                <form
                  className="flex flex-col items-center w-full gap-20 justify-center h-full "
                  onSubmit={handelContinue}
                >
                  <label className="text-xl font-bold">
                    Enter Vehicle Registration Number
                  </label>
                  <TextField
                    id="standard-basic"
                    label="Regitration Number"
                    variant="standard"
                    color="success"
                    className="w-[75%]"
                    {...reg_no_input}
                    required
                  />
                  <div className="flex gap-4">
                    <Button
                      type="button"
                      variant="contained"
                      color="success"
                      endIcon={<DoDisturbAltIcon />}
                      onClick={handleClose}
                    >
                      close
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      endIcon={<SendIcon />}
                    >
                      Continue
                    </Button>
                  </div>
                </form>
              </div>
            ) : !(lead_details.length === 0) ? (
              <div className="w-full flex flex-col gap-8 h-full mt-20">
                <div className="flex justify-between gap-y-6 w-full flex-wrap">
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Name
                    </span>
                    <span>{lead_details[0].LeadName}</span>
                  </div>
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Number
                    </span>
                    <span>{lead_details[0].MobileNumber}</span>
                  </div>
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Email
                    </span>
                    <span>{lead_details[0].Email}</span>
                  </div>
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Reg No
                    </span>
                    <span>{lead_details[0].VehicleRegistrationNumber}</span>
                  </div>
                </div>
                <div className="flex justify-between gap-y-6 w-full flex-wrap ">
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Vehicle Brand
                    </span>
                    <span>{lead_details[0].Brand_Name}</span>
                  </div>
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Vehicle Model
                    </span>
                    <span>{lead_details[0].Model_Name}</span>
                  </div>
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Variant
                    </span>
                    <span>{lead_details[0].Variant}</span>
                  </div>
                  <div className="flex flex-auto flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Mfg Year
                    </span>
                    <span>{lead_details[0].MfgYr}</span>
                  </div>
                </div>
                <div className="flex justify-between  w-full">
                  <div className="flex flex-none flex-col gap-2i w-32 items-center">
                    <span className="text-gray-700 font-bold text-xl">
                      Device Id
                    </span>
                    <span>
                      {lead_details[0].DeviceMasterId
                        ? lead_details[0].DeviceMasterId
                        : "_____"}
                    </span>
                  </div>
                </div>
                <ChildModal
                  setOpen={setOpen}
                  setopen_form={setOpen_form}
                  data={lead_details[0]}
                />
              </div>
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <CircularProgress color="success" size={100} />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
interface child_props {
  setOpen: Function;
  setopen_form: Function;
  data: lmsData;
}
interface data_post {
  LeadId: number;
  RiderName: string;
  MobileNumber: number;
  Email: string;
  ClientMasterId: number;
}
function ChildModal({ setOpen, setopen_form, data }: child_props) {
  const [open_child, setOpen_child] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  const name_input = useInput("");
  const address_input = useInput("");
  const pan_input = useInput("");
  const mobile_input = useInput("");
  const adhar_input = useInput("");
  const email_input = useInput("");

  const handleClose = () => {
    setOpen_child(false);
    setOpen(false);
    setopen_form(false);
  };
  const handleOpen_child = () => {
    setOpen_child(true);
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    height: "60%",
    overflow: "auto",
    "@media (max-width: 998px)": {
      width: "80%",
    },
  };

  const add_rider = (data_to_send: data_post) => {
    let body = JSON.stringify(data_to_send);
    customFetch(add_rider_url, body, { method: "POST" })
      .then(() => {
        handleOpen_child();
      })
      .then(() =>
        setTimeout(() => {
          handleClose();
        }, 1000)
      );
  };
  useEffect(() => {
    if (check1) {
      let rider_same_lead = {
        LeadId: data.LeadId,
        RiderName: data.LeadName,
        MobileNumber: Number(data.MobileNumber),
        Email: data.Email,
        ClientMasterId: data.ClientMasterId,
      };
      add_rider(rider_same_lead);
    } else if (check2) {
      handleOpen_child();
    }
  }, [check1, check2]);
  const handelSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let rider_different_lead = {
      LeadId: data.LeadId,
      RiderName: name_input.value,
      MobileNumber: Number(mobile_input.value),
      Email: email_input.value,
      ClientMasterId: data.ClientMasterId,
      Aadhar_No: adhar_input.value,
      PAN_No: pan_input.value,
      Address: address_input.value,
    };
    add_rider(rider_different_lead);
    name_input.onChange({ target: { value: "" } });
    mobile_input.onChange({ target: { value: "" } });
    email_input.onChange({ target: { value: "" } });
    adhar_input.onChange({ target: { value: "" } });
    pan_input.onChange({ target: { value: "" } });
    address_input.onChange({ target: { value: "" } });
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <React.Fragment>
      <div className=" flex gap-10 items-center w-11/12 self-center flex-wrap ">
        <span className="tracking-[0.3rem] text-xl font-bold text-gray-700 ">
          Is Rider same as Lead?
        </span>
        <div>
          <div className="flex gap-4 items-center">
            <label htmlFor="yes">Yes</label>
            <Checkbox
              {...label}
              color="success"
              id="yes"
              disabled={check2}
              onChange={(e) => setCheck1(e.target.checked)}
            />
          </div>
          <div className="flex gap-4 items-center">
            <label htmlFor="No">No</label>
            <Checkbox
              {...label}
              color="success"
              id="No"
              disabled={check1}
              onChange={(e) => setCheck2(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <Modal
        hideBackdrop
        open={open_child}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }} className="custom-scroll">
          {!check1 ? (
            <>
              <div className="flex gap-4 justify-center w-full items-center">
                <DirectionsBikeIcon color="success" sx={{ fontSize: "2rem" }} />

                <h1 className="text-center text-2xl font-bold text-[#00aa13]">
                  Add Rider
                </h1>
              </div>
              <div className="w-full flex justify-center p-3 sm:p-8">
                <form
                  className="sm:w-[75%] w-[95%] flex flex-col gap-5"
                  onSubmit={handelSubmit}
                >
                  <div className="flex w-full justify-between gap-5 flex-wrap">
                    <div className="flex-auto w-[200px]">
                      <TextField
                        id="outlined-basic"
                        color="success"
                        label="Name"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        {...name_input}
                      />
                    </div>
                    <div className="flex-auto w-[200px]">
                      <TextField
                        id="outlined-basic"
                        color="success"
                        label="Mobile"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        {...mobile_input}
                      />
                    </div>
                  </div>
                  <div className="flex w-full justify-between gap-5 flex-wrap">
                    <div className="flex-auto w-[200px]">
                      <TextField
                        id="outlined-basic"
                        color="success"
                        label="Email"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        {...email_input}
                      />
                    </div>
                    <div className="flex-auto w-[200px]">
                      <TextField
                        id="outlined-basic"
                        color="success"
                        label="Aadhar"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        {...adhar_input}
                      />
                    </div>
                  </div>
                  <div className="flex w-full justify-between gap-5 flex-wrap">
                    <div className="flex-auto w-[200px]">
                      <TextField
                        id="outlined-basic"
                        color="success"
                        label="PAN"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        {...pan_input}
                      />
                    </div>
                    <div className="flex-auto w-[200px]">
                      <TextField
                        id="outlined-basic"
                        color="success"
                        label="Address"
                        variant="outlined"
                        multiline
                        maxRows={4}
                        sx={{ width: "100%" }}
                        {...address_input}
                      />
                    </div>
                  </div>

                  <div className="flex gap-6 sm:self-end self-center ">
                    <Button
                      type="button"
                      variant="contained"
                      color="success"
                      endIcon={<DoDisturbAltIcon />}
                      onClick={() => {
                        setOpen_child(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      endIcon={<SendIcon />}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div className="w-full flex flex-col justify-center items-center h-full gap-10">
              <CloseIcon
                sx={{
                  color: "#00aa13",
                  alignSelf: "flex-end",
                  fontSize: "40px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
              <img
                src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/hrc_lms_content/giphy.gif"
                alt=""
                className="w-48 h-48"
              />
              <h1 className=" text-[#00aa13] text-3xl font-bold ">
                Rider Added Successfully
              </h1>
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
