import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { admin_delete_role_api } from "../../API/api";
import customFetchWithToken from "../../API/CustomFetchWtihToken";
import { getAuth } from "firebase/auth";
import showToastMessage from "../../Tostify/Index";
import { useDispatch } from "react-redux";
import { delete_role } from "../../features/admin_actions/admin_slice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid green",
  boxShadow: 24,
  p: 4,
  height: "20vh",
  borderRadius: "1rem",
};

export default function AdminConfirmModal({
  user_role_mapping_Id,
  user_id,
}: {
  user_role_mapping_Id: number;
  user_id: number;
}) {
  React.useEffect(() => {
    const auth = getAuth();
    const current_user = auth.currentUser;
    current_user?.getIdToken().then((token) => settoken(token));
  }, []);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [token, settoken] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    let api_url = `${admin_delete_role_api}${user_role_mapping_Id}`;
    customFetchWithToken(api_url, token, { method: "DELETE" })
      .then((data) => {
        showToastMessage(data.SUCCESS, "SUCCESS");
        setOpen(false);
      })
      .then(() =>
        dispatch(
          delete_role({
            user_id: user_id,
            role_mapping_id: user_role_mapping_Id,
          })
        )
      );
  };

  return (
    <div>
      <span className="cursor-pointer" onClick={handleOpen}>
        <DeleteIcon />
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col gap-8">
            <h1 className="text-center text-2xl text-green-700">
              Are you Sure?
            </h1>

            <div className="flex gap-8 justify-center">
              <Button
                variant="contained"
                color="success"
                className="self-end"
                onClick={handleDelete}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="success"
                className="self-end"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
