import * as React from "react";
import ProductStepper from "../../Components/product_stepper/product_stepper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ProductForm from "../../Components/Forms/product_form/Product_from";
import VendorForm from "../../Components/Forms/vendor_form/vendor_form";
import SopForm from "../../Components/Forms/sop_form/sop_form";
import {
  ProductSchema,
  initialProduct,
  productType,
} from "../../Components/Forms/product_form/product_schema";

import { useDispatch } from "react-redux";
import {
  initialSeller,
  sellerSchema,
  sellerType,
} from "../../Components/Forms/vendor_form/vendor_schema";

import { FormikProps, FormikValues } from "formik";
import {
  FormContent,
  sellerMenuType,
  sowMenuType,
} from "../../../../models/product_data_model";
import { useSelector } from "react-redux";
import {
  SowInitialValuesType,
  sowInitialValues,
  sowSchema,
} from "../../Components/Forms/sop_form/sop_schema";

import {
  setproduct_form_data,
  setseller_form_data,
} from "../../../../features/hrc_actions/hrc_slice";
import { RootState } from "../../../../app/store";
import {
  hrc_add_product_api,
  hrc_add_sow_api,
  hrc_add_vendor_api,
  hrc_all_seller_acc_prod_cate_api,
  hrc_all_sow_acc_vendor_id_api,
  hrc_product_add_api,
  hrc_vendor_list_api,
} from "../../../../API/api";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import showToastMessage from "../../../../Tostify/Index";

export interface AddProductProps {}

const steps = ["Product Details", "Vendor Details", "SOW Details"];

export default function AddProduct(props: AddProductProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [seller_list, setseller_list] = React.useState<[] | sellerMenuType[]>(
    []
  );
  const [sow_list, setsow_list] = React.useState<[] | sowMenuType[]>([]);
  const [selected_seller, setselected_seller] = React.useState("");
  const [selected_sow, setselected_sow] = React.useState("");
  const {
    hrc: {
      form_data: {
        data: { product, venodr, sow },
      },
    },
    user: { token_id },
  } = useSelector((state: RootState) => state);
  React.useEffect(() => {
    if (product?.category && token_id && !venodr) {
      let url = `${hrc_all_seller_acc_prod_cate_api}${product.category}`;
      customFetchWithToken(url, token_id).then((response) =>
        setseller_list([...response.DATA])
      );
    } else if (
      venodr &&
      token_id &&
      selected_seller &&
      selected_seller !== "Add"
    ) {
      let url = `${hrc_all_sow_acc_vendor_id_api}${selected_seller}`;
      customFetchWithToken(url, token_id).then((response) =>
        setsow_list([...response.DATA])
      );
    }
  }, [product, token_id, venodr, selected_seller]);

  const handleProductAdd = async (value: FormikValues) => {
    let product_body = JSON.stringify({
      ...product,
      payment_terms: `${product?.payment_terms}-${
        100 - Number(product?.payment_terms)
      }`,
    });
    if (token_id) {
      const product_response = await customFetchWithToken(
        hrc_add_product_api,
        token_id,
        { method: "POST" },
        product_body
      );
      let vendor_body = JSON.stringify(venodr);
      if (selected_seller === "Add") {
        var vendor_response = await customFetchWithToken(
          hrc_add_vendor_api,
          token_id,
          { method: "POST" },
          vendor_body
        );
      }
      let sow_body = JSON.stringify({
        ...value,
        vendorId:
          selected_seller !== "Add"
            ? selected_seller
            : vendor_response.DATA.NewInsertedSellerId,
        productId: product_response.DATA[0].Component_Id,
      });
      customFetchWithToken(
        hrc_add_sow_api,
        token_id,
        { method: "POST" },
        sow_body
      ).then(() => showToastMessage(true, "Product added sucessfully"));
    }
  };

  const dipatchData = (value: any, activeStep: number) => {
    switch (activeStep) {
      case 0:
        dispatch(setproduct_form_data(value));
        break;
      case 1:
        dispatch(setseller_form_data(value));
        break;
      case 2:
        handleProductAdd(value);
        break;
    }
  };

  //submit form
  const formContents: FormContent<any>[] = [
    {
      initialValues: product || initialProduct,
      validationSchema: ProductSchema,
      content: (props: FormikProps<productType>): JSX.Element => (
        <ProductForm props={props} />
      ),
    },
    {
      initialValues: venodr || initialSeller,
      validationSchema: sellerSchema,
      content: (props: FormikProps<sellerType>): JSX.Element => (
        <VendorForm props={props} selected_vendor={selected_seller}>
          <TextField
            id="select-vendor"
            label="Select Vendor"
            select
            value={selected_seller}
            onChange={(e) => setselected_seller(e.target.value)}
            sx={{ minWidth: "300px" }}
          >
            {seller_list.map((item) => (
              <MenuItem value={item.Seller_Id} key={item.Seller_Id}>
                {" "}
                {item.Seller_Name}
              </MenuItem>
            ))}
            <MenuItem value={"Add"} key={"Add"}>
              {" "}
              Add New vendor
            </MenuItem>
          </TextField>
        </VendorForm>
      ),
    },
    {
      initialValues: sowInitialValues,
      validationSchema: sowSchema,
      content: (props: FormikProps<SowInitialValuesType>): JSX.Element => (
        <SopForm props={props} selected_sow={selected_sow}>
          <TextField
            id="select-vendor"
            label="Select Vendor"
            select
            value={selected_sow}
            onChange={(e) => setselected_sow(e.target.value)}
            sx={{ minWidth: "300px" }}
          >
            {sow_list.map((item) => (
              <MenuItem value={item.sow_id} key={item.sow_id}>
                {" "}
                {item.sow_reference_no}
              </MenuItem>
            ))}
            <MenuItem value={"Add"}> Add New Sow</MenuItem>
          </TextField>
        </SopForm>
      ),
    },
  ];

  return (
    <div className="w-full h-full p-8 relative">
      <div className="absolute right-5 ">
        <Tooltip title="list" placement="bottom">
          <IconButton onClick={() => navigate("/home/product")}>
            <i className="fa-sharp fa-solid fa-list text-3xl text-[#00aa13]"></i>
          </IconButton>
        </Tooltip>
      </div>
      <ProductStepper
        steps={steps}
        formContents={formContents}
        dipatchData={dipatchData}
      />
    </div>
  );
}
