import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useState } from "react";
import showToastMessage from "../../Tostify/Index";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface pass_rst_mdl_type {
  setdrop_show: Function;
  open: boolean;
  setOpen: Function;
}

function PassResetModal({ setdrop_show, open, setOpen }: pass_rst_mdl_type) {
  const [old_password, setold_password] = useState("");
  const [new_password, setnew_password] = useState("");
  const [confirm_pass, setconfirm_pass] = useState("");
  const [errro_message, seterrro_message] = useState<string | null>(null);
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(false);
  };

  const update_password = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const auth = getAuth();
    let user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user?.email ?? "",
      old_password
    );

    if (user && !errro_message) {
      reauthenticateWithCredential(user, credential)
        .then((user) => {
          // User re-authenticated.
          setloader(true);
          updatePassword(user.user, new_password)
            .then(() => {
              // Update successful.
              setloader(false);
              showToastMessage(true, "Success");
              auth.signOut();
              navigate("/");
              window.location.reload();
            })
            .catch((error) => {
              // An error ocurred
              // ...
              const errorCode = error.code;
              console.log(errorCode);
              switch (errorCode) {
                case "auth/id-token-expired":
                  showToastMessage(
                    false,
                    "please login again to change your password"
                  );
                  break;
                case "auth/weak-password":
                  showToastMessage(
                    false,
                    "Your password is weak.Password must contain 6 characters or number at least "
                  );
                  break;

                case "auth/internal-error":
                  showToastMessage(
                    false,
                    "The Authentication server encountered an unexpected error while trying to process the request"
                  );
                  break;
                case "auth/invalid-password":
                  showToastMessage(
                    false,
                    "Your password must contain 6 characters or number at least "
                  );
                  break;

                case "auth/invalid-argument":
                  showToastMessage(false, error.message);
                  break;

                case "auth/invalid-credential":
                  showToastMessage(
                    false,
                    "Your password must contain 6 characters or number at least"
                  );
                  break;

                case "auth/wrong-password":
                  showToastMessage(
                    false,
                    "Your password must contain 6 characters or number at least"
                  );
                  break;
                case "auth/session-cookie-expired":
                  showToastMessage(
                    false,
                    "Please login again to change your password"
                  );
                  break;
                case "auth/too-many-requests":
                  showToastMessage(
                    false,
                    "To many attempts ,try after some time"
                  );
                  break;
                default:
                  showToastMessage(
                    false,
                    "Your request has failed,please try again"
                  );
              }
            });
        })
        .catch((error) => {
          // An error ocurred
          // ...
          const errorCode = error.code;
          switch (errorCode) {
            case "auth/id-token-expired":
              showToastMessage(
                false,
                "please login again to change your password"
              );
              break;

            case "auth/internal-error":
              showToastMessage(
                false,
                "The Authentication server encountered an unexpected error while trying to process the request"
              );
              break;

            case "auth/invalid-argument":
              showToastMessage(false, error.message);
              break;

            case "auth/invalid-credential":
              showToastMessage(false, "Please enter your correct old password");
              break;

            case "auth/wrong-password":
              showToastMessage(
                false,
                "The provided old  password is incorrect. Please double-check and try again."
              );
              break;
            case "auth/session-cookie-expired":
              showToastMessage(
                false,
                "Please login again to change your password"
              );
              break;
            case "auth/too-many-requests":
              showToastMessage(false, "To many attempts ,try after some time");
              break;
            default:
              showToastMessage(
                false,
                "Your request has failed,please try again"
              );
          }
        });
    } else {
      showToastMessage(false, "Please match your passwords");
    }
  };

  React.useEffect(() => {
    if (confirm_pass && new_password) {
      seterrro_message("Password is not same");

      if (confirm_pass === new_password) {
        seterrro_message(null);
      }
    } else {
      seterrro_message(null);
    }
  }, [confirm_pass, new_password]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!loader ? (
            <div className="flex flex-col">
              <div className="self-end cursor-pointer" onClick={handleClose}>
                <CloseIcon />
              </div>

              <h1 className="text-xl font-bold">Update your password</h1>
              <form className="w-full mt-4" onSubmit={update_password}>
                <div className="flex flex-col gap-4">
                  <TextField
                    id="standard-basic1"
                    label="Enter your old password"
                    variant="standard"
                    type="password"
                    sx={{ width: "100%" }}
                    value={old_password}
                    onChange={(e) => setold_password(e.target.value)}
                    color="success"
                    required
                  />
                  <TextField
                    id="standard-basic2"
                    label=" New Password"
                    variant="standard"
                    color="success"
                    type={"password"}
                    sx={{ width: "100%" }}
                    value={new_password}
                    onChange={(e) => setnew_password(e.target.value)}
                    required
                  />
                  <TextField
                    id="standard-basic3"
                    label="Confirm New Password"
                    variant="standard"
                    color="success"
                    type={"password"}
                    sx={{ width: "100%" }}
                    value={confirm_pass}
                    required
                    onChange={(e) => setconfirm_pass(e.target.value)}
                  />
                  {errro_message && (
                    <span className="text-red-900">{errro_message}</span>
                  )}
                  <Button
                    variant="contained"
                    color="success"
                    className="self-end "
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div className="h-full w-full flex justify-center items-center">
              <CircularProgress size={75} color="success" />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
export default React.memo(PassResetModal);
