import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { purchase_data_type } from "../../../../models/API_data";
import PurchaseAccord from "../../Components/Purchage_accordian";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { custom_theme } from "../../../../custom_them/custom_them";
import { ThemeProvider } from "@mui/material/styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: "100%", height: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PurchaseDetails() {
  const [value, setValue] = React.useState(0);
  let [active_tab_list, setactive_tab_list] = React.useState<
    [] | purchase_data_type[]
  >([]);

  //decalration for redux usecases
  const {
    purchase_order: {
      data: { purchase_order_data },
    },
  } = useSelector((state: RootState) => state.hrc);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log(purchase_order_data);

  React.useEffect(() => {
    if (purchase_order_data) {
      // Check if purchase_order_data is not undefined or null
      switch (
        value // Use a switch statement instead of multiple if statements
      ) {
        case 0:
          active_tab_list = purchase_order_data.filter(
            (item) => item.purchase_status_id === 32
          );
          setactive_tab_list(active_tab_list);
          break;
        case 1:
          active_tab_list = purchase_order_data.filter(
            (item) => item.purchase_status_id === 33
          );
          setactive_tab_list(active_tab_list);
          break;
        case 2:
          active_tab_list = purchase_order_data.filter(
            (item) => item.purchase_status_id === 34
          );
          setactive_tab_list(active_tab_list);
          break;
        case 3:
          active_tab_list = purchase_order_data.filter(
            (item) => item.purchase_status_id === 35
          );
          setactive_tab_list(active_tab_list);
          break;
        case 4:
          active_tab_list = purchase_order_data.filter(
            (item) => item.Purchase_Status === 36
          );
          setactive_tab_list(active_tab_list);
          break;
        default:
          // handle any other values of "value" here
          break;
      }
    }
  }, [value, purchase_order_data]);
  let purchase_list = (
    <div className="flex flex-col gap-6">
      {active_tab_list.map((item) => (
        <PurchaseAccord item={item} key={item.id} />
      ))}
    </div>
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <ThemeProvider theme={custom_theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Entered(Approval)" {...a11yProps(0)} />
            <Tab label="Issued(Approval)" {...a11yProps(1)} />
            <Tab label="Completed(Order Placed)" {...a11yProps(2)} />
            <Tab label="Cancelled" {...a11yProps(3)} />
            <Tab label="Revise" {...a11yProps(4)} />
          </Tabs>
        </ThemeProvider>
      </Box>
      <TabPanel value={value} index={0}>
        {active_tab_list.length > 0 ? (
          purchase_list
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <h1 className="text-2xl text-red-700">No Records😭</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {active_tab_list.length > 0 ? (
          purchase_list
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <h1 className="text-2xl text-red-700">No Records😭</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {active_tab_list.length > 0 ? (
          purchase_list
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <h1 className="text-2xl text-red-700">No Records😭</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {active_tab_list.length > 0 ? (
          purchase_list
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <h1 className="text-2xl text-red-700">No Records😭</h1>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {active_tab_list.length > 0 ? (
          purchase_list
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <h1 className="text-2xl text-red-700">No Records😭</h1>
          </div>
        )}
      </TabPanel>
    </Box>
  );
}
