import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import { baseUrl } from "../../../API/api";
import { opentoaddData, lmsData } from "../../../models/lms_data_api";
import { lead_history_type } from "../../../models/API_data";
import Tooltip from "@mui/material/Tooltip";
import AssignModal from "./assign_model/assign_modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import customFetchWithToken from "../../../API/CustomFetchWtihToken";
import IconButton from "@mui/material/IconButton";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { setAssignData } from "../../../features/lms_actions/lmsSlice";
import { Link } from "react-router-dom";

type AccordianpropType = {
  opentoAdd: opentoaddData;
  setOpentoAdd: Function;
  item: lmsData;
};

function Accordian({ opentoAdd, setOpentoAdd, item }: AccordianpropType) {
  const {
    user: { token_id },
    lms: {
      assignedData: { dataToAssigned },
    },
  } = useSelector((state: RootState) => state);
  const [flag, setflag] = useState(false);
  const [view, setview] = useState(false);
  const [check_input, setcheck_input] = useState(false);
  const dispatch = useDispatch();
  const [histoy, sethistoy] = useState<[] | lead_history_type[]>([]);
  const memoizedDispatch = useMemo(() => {
    return dispatch;
  }, [dispatch]);
  const handleView = (e: React.MouseEvent) => {
    e.stopPropagation();

    setflag(true);
    let api_url = `${baseUrl}/lms/v1/lead-history/${item.LeadId}`;
    if (token_id && !view) {
      customFetchWithToken(api_url, token_id, { method: "GET" }).then((data) =>
        sethistoy(data.DATA)
      );
    }
    setview(!view);
  };
  useEffect(() => {
    const updatedIds = [...dataToAssigned];
    let index = updatedIds.indexOf(item.LeadId);
    if (index > -1) {
      updatedIds.splice(index, 1);
    } else if (check_input) {
      updatedIds.push(item.LeadId);
    }
    memoizedDispatch(setAssignData(updatedIds));
  }, [check_input]);
  useEffect(() => {
    if (dataToAssigned.length === 0 && check_input) {
      setcheck_input(false);
    } else {
      dataToAssigned?.map((LeadId) => {
        if (LeadId === item.LeadId) {
          setcheck_input(true);
        }
      });
    }
  }, [dataToAssigned]);

  return (
    <div className="w-full rounded bg-white">
      <div
        className={`p-4 border-b border-[#bcf3c2] cursor-pointer ${
          check_input && "bg-[#bef2c3] opacity-75"
        }`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.detail === 2) {
            setcheck_input(!check_input);
          } else if (e.detail === 1) {
            setflag(!flag);
          }
        }}
      >
        {/* checkbox-container  */}
        <div
          className={`w-full flex-auto  flex justify-evenly md:flex-nowrap flex-wrap gap-y-5 items-center text-black`}
        >
          <div
            className="form-check w-16  h-16 rounded-full   flex items-center justify-center hover:bg-[#000] group"
            onClick={(e) => {
              e.stopPropagation();
              setcheck_input(!check_input);
            }}
          >
            <input
              className="form-check-input bg-white group-hover:bg-[#000] appearance-none h-6 w-6 border border-gray-300 rounded-sm  checked:bg-[#00aa13] checked:border-none focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left  cursor-pointer"
              type="checkbox"
              id="flexCheckDefault"
              checked={check_input}
              onChange={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                setcheck_input(!check_input);
              }}
            />
          </div>
          {/* name */}
          <div className=" flex flex-col w-32 flex-auto break-all">
            <span className="text-center sm:text-start">{item.LeadName}</span>
            <span className="text-center sm:text-start">
              {item.MobileNumber}
            </span>
          </div>
          {/* timing  */}
          <div className="flex-auto w-32 break-all">
            <span className="text-center sm:text-start">{item.Stage_Name}</span>
          </div>
          {/* email  */}
          <div className=" flex-auto sm:w-32 w-48 break-all">
            <span className="text-center sm:text-start">{item.Email}</span>
          </div>
          {/* code  */}
          <div className="flex-auto sm:w-32 w-48 break-all">
            <span className="text-center sm:text-start">
              {item.VehicleRegistrationNumber}
            </span>
          </div>
          <div className="flex w-60  flex-auto">
            <div>
              <Tooltip title="update" placement="top">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpentoAdd({
                      ...opentoAdd,
                      flag: false,
                      add: false,
                      update: {
                        ...item,
                      },
                    });
                  }}
                >
                  <i className="fa-solid fa-pen text-[#00aa13] text-[1rem]"></i>
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip title="history" placement="top">
                <IconButton onClick={handleView}>
                  <i className="fa-sharp fa-solid fa-clock-rotate-left  text-[#00aa13] text-[1rem]"></i>
                </IconButton>
              </Tooltip>
            </div>
            {item.WhatsAppNo && (
              <div>
                <Tooltip title="whats app" placement="top">
                  <IconButton
                    onClick={(e) => e.stopPropagation()}
                    href={`https://wa.me/+91${item.WhatsAppNo}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-whatsapp text-[#00aa13] text-[1.2rem]"></i>
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div>
              <IconButton
                href={`tel:+91${item.MobileNumber}`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <Tooltip title="call" placement="top">
                  <i className="fa-solid fa-phone text-[#00aa13] text-[1rem]"></i>
                </Tooltip>
              </IconButton>
            </div>
            <div>
              <Link
                to={`/gt-console/pdf-view/document-${item.LeadId}`}
                target="_blank"
              >
                <IconButton onClick={(e) => e.stopPropagation()}>
                  <Tooltip title="export pdf" placement="top">
                    <i className="fa-solid fa-file-pdf text-[#00aa13]"></i>
                  </Tooltip>
                </IconButton>
              </Link>
            </div>
          </div>

          <div className="flex-none text-center ">
            <i
              className={`fa-solid fa-chevron-down ${flag && "rotate-180"}`}
            ></i>
          </div>
        </div>
      </div>
      <Transition.Root show={flag} as={Fragment}>
        <div className="mt-2">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-1000"
            enterFrom="opacity-0 h-0"
            enterTo="opacity-100 h-auto"
            leave="ease-in-out duration-100"
            leaveFrom="opacity-100 h-auto"
            leaveTo="opacity-0 h-0"
          >
            <div className="flex flex-col gap-3 pb-8">
              <div className="accordion-body py-4 px-5 flex justify-around md:flex-nowrap flex-wrap gap-y-5">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Birth Date
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {new Date(item?.AgeGroup ?? "").toLocaleString(
                        "default",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Vehicle Registration Number
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.VehicleRegistrationNumber}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Lead Source
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.Source_Name}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Created On
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {new Date(item?.CreatedOn ?? "").toLocaleString(
                        "default",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Gender
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.Gender}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Vehicle Brand
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.Brand_Name}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      State
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.State_Name}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Updated On
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {new Date(item?.UpdatedOn ?? "").toLocaleString(
                        "default",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Profession
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.Profession}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Vehicle Model
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.Model_Name}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      City
                    </span>
                    <span className="text-black text-center sm:text-base">
                      {item.City_Name}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto ">
                    <span className="text-gray-500 text-center sm:text-base">
                      Comments
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.Comments}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col w-48 flex-auto ">
                    <span className="text-gray-500 text-center sm:text-base">
                      Annual Income
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.AnnualIncome}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto ">
                    <span className="text-gray-500 text-center sm:text-base">
                      Vehicle Make Year
                    </span>
                    <span className="text-black text-center sm:text-base break-words">
                      {item.MfgYr}
                    </span>
                  </div>
                  {item.NextFollowUp && (
                    <div className="flex flex-col w-48 flex-auto">
                      <span className="text-gray-500 text-center sm:text-base">
                        Next Follow Up
                      </span>
                      <span className="text-center sm:text-bas break-words">
                        {new Date(item?.NextFollowUp ?? "").toLocaleString(
                          "default",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      Cost
                    </span>
                    <span className="text-center sm:text-base break-words">
                      {item.COST}
                    </span>
                  </div>
                  <div className="flex flex-col w-48 flex-auto">
                    <span className="text-gray-500 text-center sm:text-base">
                      AssignedTo
                    </span>
                    <span className="text-center sm:text-base break-words">
                      {item.AssignedToName}
                    </span>
                  </div>
                </div>
              </div>
              {view && (
                <div className="flex flex-col gap-2   border-t border-green-700">
                  {histoy[0]?.data.length >= 0 ? (
                    histoy[0]?.data.map((item) => {
                      return (
                        <div className="w-full py-4 px-5 flex justify-around">
                          <div className="flex flex-col flex-auto gap-2 w-48 items-center">
                            <span className="text-md font-bold text-green-700 ">
                              Stage Name
                            </span>
                            <span className="break-words">
                              {item.Stage_Name}
                            </span>
                          </div>

                          <div className="flex w-48 flex-col gap-2 flex-auto items-center">
                            <span className="text-md font-bold text-green-700">
                              Follow Up Date
                            </span>
                            <span className="break-words">
                              {new Date(
                                item?.NextFollowUp ?? ""
                              ).toLocaleString("default", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </span>
                          </div>
                          <div className="flex w-48 flex-col gap-2 flex-auto items-center ">
                            <span className="text-md font-bold text-green-700">
                              Comments
                            </span>
                            <span className="break-words">{item.Comments}</span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className=" p-4">
                      <h1 className="text-2xl text-center text-red-900">
                        No history Found
                      </h1>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    </div>
  );
}

export default React.memo(Accordian);
