interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "center";
  obj_id?: string;
  format?: (value: number) => string;
}

const job_card_table_column_data: Column[] = [
  { id: "JobCardId", label: "JobCardId", minWidth: 170 },
  { id: "JobCard", label: "Job Card", minWidth: 170 },
  {
    id: "Registration_No",
    label: "RegistrationNo",
    minWidth: 170,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Model_Name",
    label: "ModelName",
    minWidth: 170,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Bay",
    label: "Bay No",
    obj_id: "Bay_No",
    minWidth: 170,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Technician",
    label: "Technician Name",
    obj_id: "Name",
    minWidth: 170,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Technician",
    label: "Mobile No",
    obj_id: "Mobile_No",
    minWidth: 170,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];
export { job_card_table_column_data };
