import { FormikProps } from "formik";
import React, { useState } from "react";
import { SowInitialValuesType } from "../Forms/sop_form/sop_schema";
import showToastMessage from "../../../../Tostify/Index";
import AWS from "aws-sdk";
import { ToastContainer } from "react-toastify";
const s3 = new AWS.S3({
  accessKeyId: "AKIATDLJCIZ3SO4ZWW46",
  secretAccessKey: "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",
  region: "ap-south-1",
});
interface ImageSelectorProps {
  props: FormikProps<SowInitialValuesType>;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({ props }) => {
  const { setFieldValue, values, touched, errors } = props;
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [progress, setProgress] = React.useState(0);
  const [images, setImages] = useState<string[]>([]);

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const selectedImages: string[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          selectedImages.push(reader.result as string);

          if (selectedImages.length === files.length) {
            setImages(selectedImages);
          }
        };
      }
    }
  };
  const handleImageUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let files = inputRef?.current?.files;
    if (!files || files.length === 0) {
      showToastMessage(false, "Please select an image to upload");
      return;
    }
    const folderName = "productImage";
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const params = {
            Bucket: "greentiger.in-content",
            Key: `${folderName}/${file.name}`,
            Body: file,
            ContentType: file.type,
            ACL: "public-read",
          };
          const response = await s3
            .upload(params)
            .on("httpUploadProgress", (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            })
            .promise();
          return response;
        })
      );

      showToastMessage(true, "Images uploaded successfully!");
      setProgress(0);
      let designArray = uploadedFiles.map((item) => item.Location);
      setFieldValue("design", designArray);
    } catch (error) {
      console.log(error);
      showToastMessage(false, "Something went wrong");
    }
  };
  return (
    <div className="flex flex-col gap-4 w-full ">
      <ToastContainer />
      <div className="flex flex-row-reverse">
        <div className="flex flex-wrap justify-center  mt-4 w-[50%] flex-auto ">
          {images.length > 0 &&
            images.map((image, index) => (
              <img
                key={index}
                className="w-32 h-32 object-cover m-2"
                src={image}
                alt={`Preview ${index}`}
              />
            ))}
        </div>

        <label className="mt-6 flex flex-col items-center px-4 py-6 bg-gray-200 text-gray-600 rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-gray-300">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M15 1H5a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V3a2 2 0 00-2-2zm-1 11l-4-4-4 4V4h8v8z"
              clipRule="evenodd"
            />
          </svg>
          <span className="mt-2 text-base leading-normal">
            Select Images (max 5)
          </span>
          <input
            ref={inputRef}
            id="design"
            name="design"
            className="hidden"
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageSelect}
          />
        </label>
      </div>
      {touched.design && errors.design ? (
        <div>
          <span className="text-red-900">{errors.design}</span>
        </div>
      ) : null}
      {images.length > 0 && (
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 self-start text-white font-bold py-2 px-4 rounded mt-4"
          onClick={handleImageUpload}
          disabled={progress !== 0}
        >
          Upload Images{" "}
          <span className="ml-2"> {progress !== 0 && progress}</span>
        </button>
      )}
    </div>
  );
};

export default ImageSelector;
