import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  vehicle_data_daily_insight_response,
  vehicle_data_daily_insight_table_data,
} from "../../../../models/vehicle_data_models";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { get_rider_status_daily_data_api } from "../../../../API/api";
import dayjs, { Dayjs } from "dayjs";

interface Column {
  id: "name" | "code" | "population" | "size" | "density";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Density",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toFixed(2),
  },
];

interface Data {
  name: string;
  code: string;
  population: number;
  size: number;
  density: number;
}

function createData(
  name: string,
  code: string,
  population: number,
  size: number
): Data {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];
interface DailyInsightProps {
  selectedDate: Dayjs | null;
}

export default function DailyInsight({ selectedDate }: DailyInsightProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableDATA, settableDATA] = React.useState<
    vehicle_data_daily_insight_table_data[]
  >([]);
  const [tableHeading, settableHeading] = React.useState<string[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  React.useEffect(() => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    let url = `${get_rider_status_daily_data_api}${formattedDate}`;
    console.log(url);
    customFetchWithToken(url, "").then(
      (data: vehicle_data_daily_insight_response) => {
        settableHeading([...extractKeys(data.DATA[0])]);
        settableDATA(data.DATA);
      }
    );
  }, [selectedDate]);
  function extractKeys(obj: vehicle_data_daily_insight_table_data): string[] {
    const keys: string[] = [];
    if (obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (Array.isArray(value)) {
          const subKeys: string[] = [];
          for (const item of value) {
            if (typeof item === "object") {
              // recursively extract keys from object inside array
              subKeys.push(...extractKeys(item));
            }
          }
          keys.push(...subKeys);
        } else {
          keys.push(key);
        }
      }
      return keys;
    }
    return [];
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", maxHeight: "100%" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeading.map((column) => (
                <TableCell
                  key={column}
                  // align={column.align}
                  // style={{ minWidth: column.minWidth }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDATA
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row["Device Id"]}
                  >
                    {tableHeading.map((column) => {
                      const value = row[column];
                      return (
                        <TableCell key={column}>
                          {value ? value.toString() : 0}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
