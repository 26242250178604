import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { admin_users_data_type } from "../../models/API_data";
import customFetchWithToken from "../../API/CustomFetchWtihToken";
import { Button } from "@mui/material";
import AdminModal from "./admin_modal";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import AdminConfirmModal from "./admin_confirm_modal";
import { useSelector, useDispatch } from "react-redux";
import {
  activate_user,
  deactivate_user,
  add_roles,
} from "../../features/admin_actions/admin_slice";
import {
  admin_deactivate_user_api,
  admin_activate_user_api,
  admin_system_drop_api,
  admin_role_drop_api,
  admin_assign_role_api,
} from "../../API/api";
import {
  system_name_drop_data_type,
  role_name_drop_data_type,
} from "../../models/API_data";
import showToastMessage from "../../Tostify/Index";
import { RootState } from "../../app/store";
interface user_role_drp {
  label: string;
  id: number;
}

export default function UserTable() {
  const [modal_cls_by_parent, setmodal_cls_by_parent] = useState(false);
  const [system_name, setsystem_name] = useState("");
  const [system_menu, setsystem_menu] = useState<
    [] | system_name_drop_data_type[]
  >([]);
  let [user_role_drp, setuser_role_drp] = useState<[] | user_role_drp[]>([]);
  const [role_name, setrole_name] = useState<[] | user_role_drp[]>([]);
  const {
    user: { token_id: token },
    admin: {
      admin: {
        data: { admin_data: rows },
      },
    },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      customFetchWithToken(admin_system_drop_api, token, {
        method: "GET",
      }).then((data) => setsystem_menu([...data.DATA]));
      customFetchWithToken(admin_role_drop_api, token, { method: "GET" }).then(
        (data) => {
          user_role_drp = data.DATA.map((item: role_name_drop_data_type) => {
            return {
              label: item.RoleName,
              id: item.RoleId,
            };
          });
          setuser_role_drp(user_role_drp);
        }
      );
    }
  }, [token]);

  const handleActivation = (row: admin_users_data_type) => {
    let body = JSON.stringify({
      email: row.Email,
    });
    if (token) {
      customFetchWithToken(
        admin_activate_user_api,
        token,
        { method: "PATCH" },
        body
      )
        .then((data) => {
          setmodal_cls_by_parent(!modal_cls_by_parent);
          showToastMessage(data.SUCCESS, "SUCCESS");
        })
        .then(() => {
          dispatch(activate_user(row));
        });
    } else {
      showToastMessage(false, "Your token has been expired! Please re-login");
    }
  };
  const handleDeactivation = (row: admin_users_data_type) => {
    let body = JSON.stringify({
      email: row.Email,
    });
    if (token) {
      customFetchWithToken(
        admin_deactivate_user_api,
        token,
        { method: "PATCH" },
        body
      )
        .then((data) => {
          setmodal_cls_by_parent(!modal_cls_by_parent);
          showToastMessage(data.SUCCESS, "SUCCESS");
        })
        .then(() => {
          dispatch(deactivate_user(row));
        });
    } else {
      showToastMessage(false, "Your token has been expired! Please re-login");
    }
  };
  const handleRoles = (
    e: React.MouseEvent<HTMLButtonElement>,
    userId: number
  ) => {
    let api_url = `${admin_assign_role_api}${userId}`;
    let role_id_arr = role_name.map((item) => {
      return item.id;
    });
    let body = JSON.stringify({
      [system_name]: role_id_arr,
    });
    if (token) {
      customFetchWithToken(api_url, token, { method: "POST" }, body)
        .then((data) => {
          return data;
        })
        .then((response) => {
          dispatch(
            add_roles({ updated_role_data: response.DATA[0], user_id: userId })
          );
        });
    } else {
      showToastMessage(false, "Your token has been expired! Please re-login");
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ height: "100%" }}
      className="custom-scroll"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "green" }}
            >
              S.No
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "green" }}
            >
              User Name
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "green" }}
            >
              Mobile Number
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "green" }}
            >
              Email
            </TableCell>

            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "green" }}
            >
              Total Role
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row.UserId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {index + 1}
              </TableCell>
              <TableCell align="center">{row.UserName}</TableCell>
              <TableCell align="center">{row.MobileNumber}</TableCell>
              <TableCell align="center">{row.Email}</TableCell>
              <TableCell align="center">{row.RoleMapping.length}</TableCell>
              <TableCell align="center">
                <AdminModal
                  user_status={null}
                  modal_cls_by_parent={modal_cls_by_parent}
                >
                  <div className="flex flex-col w-full gap-10">
                    <div className="flex flex-col gap-5">
                      <h1 className="text-2xl text-green-700 text-center">
                        Update Role
                      </h1>
                      <TableContainer
                        component={Paper}
                        sx={{
                          width: 350,
                          alignSelf: "center",
                          border: "thin solid green",
                        }}
                      >
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>System Name</TableCell>
                              <TableCell>Roles</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.RoleMapping.map((item) => {
                              return (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  key={item.UserRoleMappingId}
                                >
                                  <TableCell>{item.SystemName}</TableCell>
                                  <TableCell>{item.RoleName}</TableCell>
                                  <TableCell>
                                    <AdminConfirmModal
                                      user_role_mapping_Id={
                                        item.UserRoleMappingId
                                      }
                                      user_id={row.UserId}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <form className="self-center w-[50%] flex flex-col gap-6">
                      <Box sx={{ width: "100%" }}>
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                          <InputLabel
                            id="demo-simple-select-standard-label"
                            color="success"
                          >
                            System Name
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={system_name}
                            onChange={(e) => setsystem_name(e.target.value)}
                            label="System Name"
                            color="success"
                          >
                            {system_menu.map((item) => (
                              <MenuItem
                                value={item.MasterSystemId}
                                key={item.MasterSystemId}
                              >
                                {item.SystemName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <Stack spacing={3} sx={{ width: "100%" }}>
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={user_role_drp}
                          getOptionLabel={(option) => option.label}
                          onChange={(e, newValue) =>
                            setrole_name([...newValue])
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Roles"
                              placeholder="e.g..admin"
                              color="success"
                            />
                          )}
                        />
                      </Stack>
                      <Button
                        variant="contained"
                        color="success"
                        className="self-end"
                        onClick={(e) => handleRoles(e, row.UserId)}
                      >
                        confirm
                      </Button>
                    </form>
                  </div>
                </AdminModal>
              </TableCell>
              <TableCell align="center">
                {row.UserStatus === 0 && (
                  <AdminModal
                    user_status={0}
                    modal_cls_by_parent={modal_cls_by_parent}
                  >
                    <div className="flex flex-col w-full gap-5">
                      <div>
                        <h1 className="text-center text-2xl text-green-700">
                          Activate User
                        </h1>
                      </div>

                      <TableContainer
                        component={Paper}
                        sx={{
                          width: 350,
                          alignSelf: "center",
                          border: "thin solid green",
                        }}
                      >
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>System Name</TableCell>
                              <TableCell>Roles</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.RoleMapping.map((item) => {
                              return (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  key={item.MasterSystemId}
                                >
                                  <TableCell>{item.SystemName}</TableCell>
                                  <TableCell>{item.RoleName}</TableCell>

                                  <TableCell>
                                    <AdminConfirmModal
                                      user_role_mapping_Id={
                                        item.UserRoleMappingId
                                      }
                                      user_id={row.UserId}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <form action="" className="w-full flex justify-center">
                        <div className="w-[60%]  flex flex-col gap-5">
                          <TextField
                            id="standard-basic"
                            label="Email"
                            variant="standard"
                            sx={{ width: "100%" }}
                            value={row.Email}
                            disabled
                          />
                          <Button
                            variant="contained"
                            color="success"
                            className="self-end"
                            onClick={() => handleActivation(row)}
                          >
                            confirm
                          </Button>
                        </div>
                      </form>
                    </div>
                  </AdminModal>
                )}
                {row.UserStatus === 1 && (
                  <AdminModal
                    user_status={1}
                    modal_cls_by_parent={modal_cls_by_parent}
                  >
                    <div className="flex flex-col w-full gap-10">
                      <div className="flex flex-col gap-5">
                        <h1 className="text-center text-2xl text-green-700">
                          Deactivate User
                        </h1>
                      </div>

                      <TableContainer
                        component={Paper}
                        sx={{
                          width: 350,
                          alignSelf: "center",
                          border: "thin solid green",
                        }}
                      >
                        <Table sx={{ width: "100%" }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>System Name</TableCell>
                              <TableCell>Roles</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.RoleMapping.map((item) => {
                              return (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  key={item.MasterSystemId}
                                >
                                  <TableCell>{item.SystemName}</TableCell>
                                  <TableCell>{item.RoleName}</TableCell>
                                  <TableCell>
                                    <AdminConfirmModal
                                      user_role_mapping_Id={
                                        item.UserRoleMappingId
                                      }
                                      user_id={row.UserId}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <form action="" className="w-full flex justify-center">
                        <div className="w-[60%]  flex flex-col gap-5">
                          <TextField
                            id="standard-basic"
                            label="Email"
                            variant="standard"
                            sx={{ width: "100%" }}
                            value={row.Email}
                            disabled
                          />
                          <Button
                            variant="contained"
                            color="success"
                            className="self-end"
                            onClick={() => handleDeactivation(row)}
                          >
                            confirm
                          </Button>
                        </div>
                      </form>
                    </div>
                  </AdminModal>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
