interface MethodType {
  method?: string;
}

const customFetchWithToken = async (
  url: string,
  token: string,
  options: MethodType = {},
  body?: BodyInit
) => {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `${token}`;
  }

  const requestOptions: RequestInit = {
    method: options.method || "GET",
    headers,
    ...(body && { body }),
  };

  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(`${response.status} - ${response.statusText}`);
  } else if (response.status === 202) {
    throw new Error("Lead is present with same number,Kindly update this lead");
  }
  const responseData = await response.json();
  return responseData;
};

export default customFetchWithToken;
