import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Inventory_table_data_type } from "../../../../models/API_data";
import { Button } from "@material-tailwind/react";
import BasicModal from "../../../../Modals/BasicModel";
import { useState } from "react";

export default function BasicTable({
  rows,
}: {
  rows: Inventory_table_data_type[] | null;
}) {
  const [open, setOpen] = React.useState(false);
  const [Component_details, setComponent_details] =
    useState<null | Inventory_table_data_type>(null);
  const handleOpen = (data: Inventory_table_data_type) => {
    setOpen(true);
    setComponent_details(data);
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>JobCardId</TableCell>
            <TableCell align="center">Unique Kit Id</TableCell>
            <TableCell align="center">Bay No</TableCell>
            <TableCell align="center">Technician Name</TableCell>
            <TableCell align="center">Vehicle Reg Number</TableCell>
            <TableCell align="center">Vehicle Model Name</TableCell>
            <TableCell align="center">Total Components</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={row.Id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.JobCardId}
              </TableCell>
              <TableCell align="center">{row.Unique_kit_Id}</TableCell>
              <TableCell align="center">{row.Bay.Bay_No}</TableCell>
              <TableCell align="center">{row.Technician.Name}</TableCell>
              <TableCell align="center">
                {row.Vehicle.Registration_No}
              </TableCell>
              <TableCell align="center">{row.Vehicle.Model_Name}</TableCell>
              <TableCell align="center">{row.Component.length}</TableCell>
              <TableCell align="center">
                <Button onClick={() => handleOpen(row)}>View Details</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <BasicModal setOpen={setOpen} open={open} row={Component_details} />
    </TableContainer>
  );
}
