import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { Sidemenu } from "../../models/lms_sidemenu";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import React from "react";
type NavcontentProptype = {
  SidebarMenu: Sidemenu[];
  lms: boolean;
  close_side_nav: boolean;
  setSideNav: () => void;
};

function NavContent({
  SidebarMenu,
  close_side_nav,
  setSideNav,
  lms,
}: NavcontentProptype) {
  let location = useLocation();
  const {
    access: {
      access_sections: { lms: lms_access, hrc },
    },
  } = useSelector((state: RootState) => state);

  return (
    <div className="flex flex-col h-full justify-between pt-2 pb-6">
      <div>
        <ul className="mt-6 space-y-2 tracking-wide">
          {SidebarMenu.map((sm, i) => (
            <NavItem
              sm={sm}
              isActive={sm.path === location.pathname}
              key={i}
              close_side_nav={close_side_nav}
              setSideNav={setSideNav}
            />
          ))}
        </ul>
      </div>
      {lms_access && hrc && (
        <div className="w-max -mb-3">
          <Link
            className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600"
            to={`${lms ? "/home" : "/lms"}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 group-hover:fill-cyan-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"
              />
            </svg>
            <span className="group-hover:text-gray-700">
              {lms ? "HRC" : "LMS"}
            </span>
          </Link>
        </div>
      )}
    </div>
  );
}

/**
 *
 * @param {MenuItemData} sm
 * @param {boolean} isActive
 */
function NavItem({
  sm,
  close_side_nav,
  setSideNav,
  isActive,
}: {
  sm: Sidemenu;
  close_side_nav: boolean;
  setSideNav: () => void;
  isActive: boolean;
}) {
  return (
    <li
      className="min-w-max hover:bg-[#e7f4ea] hover:text-[#00aa13] rounded-full"
      onClick={(e: React.MouseEvent) => {
        !close_side_nav && setSideNav();
      }}
    >
      <Link
        className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600 group-hover:bg-gray-600 group-hover:text-green-700"
        to={sm.path}
        style={{
          color: isActive ? "#00aa13" : "gray",
          background: isActive ? "#e7f4ea" : "none",
        }}
      >
        <IconContext.Provider value={{ className: `h-5 w-5` }}>
          {sm.icons}
        </IconContext.Provider>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 hidden"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            className="fill-current text-gray-300 group-hover:text-cyan-300"
            fillRule="evenodd"
            d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
            clipRule="evenodd"
          />
          <path
            className="fill-current text-gray-600 group-hover:text-cyan-600"
            d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
          />
        </svg>
        <span
          className={`hover:text-[#00aa13] 
          `}
        >
          {sm.label}
        </span>
      </Link>
    </li>
  );
}

export default React.memo(NavContent);
