import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ImageSelector from "../../muti_image_selector/multi_image_selector";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../app/store";
import { FormikProps } from "formik";
import { SowInitialValuesType, sowInitialValues } from "./sop_schema";
import {
  hrc_shipping_details_menu,
  hrc_single_sow_data_api,
} from "../../../../../API/api";
import customFetchWithToken from "../../../../../API/CustomFetchWtihToken";
import { setshipping_method_menu } from "../../../../../features/hrc_actions/hrc_slice";

interface sopFormProps {
  children?: JSX.Element;
  props: FormikProps<SowInitialValuesType>;
  selected_sow: string;
}

function SopForm({ children, props, selected_sow }: sopFormProps) {
  const { values, handleChange, errors, touched, setValues } = props;
  const dispatch = useDispatch();
  const {
    user: { token_id },
    hrc: {
      new_product_data: {
        shipping_method_dropmenu: {
          data: { payment_method, payment_terms, shipping_method },
        },
      },
    },
  } = useSelector((state: RootState) => state);
  useEffect(() => {
    if (selected_sow && token_id && selected_sow !== "Add") {
      let sow_url = `${hrc_single_sow_data_api}${selected_sow}`;
      customFetchWithToken(sow_url, token_id).then((response) => {
        let sow_data = { ...response.DATA[0] };
        setValues(sow_data);
        console.log(values);
      });
    } else {
      setValues(sowInitialValues);
    }
  }, [selected_sow]);
  useEffect(() => {
    if (token_id) {
      customFetchWithToken(hrc_shipping_details_menu, token_id).then((data) => {
        dispatch(setshipping_method_menu([...data.DATA]));
      });
    }
  }, [token_id]);
  console.log(errors);
  return (
    <div className="w-full flex flex-col gap-9">
      {/* Form title  */}
      <header className="flex justify-between">
        <h1 className="text-3xl text-[#00aa13] ">SOW Details</h1>
        {children}
      </header>
      <main className="w-[75%] self-center">
        <div className="w-full  grid grid-row-4 grid-cols-4 gap-10 ">
          <TextField
            id="payment_terms"
            name="payment_terms"
            label="Payment Terms"
            variant="outlined"
            color="success"
            value={values.payment_terms}
            onChange={handleChange}
            error={touched.payment_terms && Boolean(errors.payment_terms)}
            helperText={touched.payment_terms && errors.payment_terms}
          />

          <TextField
            id="order_projection"
            name="order_projection"
            label="Order Projection"
            variant="outlined"
            color="success"
            value={values.order_projection}
            onChange={handleChange}
            error={touched.order_projection && Boolean(errors.order_projection)}
            helperText={touched.order_projection && errors.order_projection}
          />
          <TextField
            id="delivery_time"
            name="delivery_time"
            label="Delivery Time "
            variant="outlined"
            color="success"
            value={values.delivery_time}
            onChange={handleChange}
            error={touched.delivery_time && Boolean(errors.delivery_time)}
            helperText={touched.delivery_time && errors.delivery_time}
          />

          <TextField
            id="penalty_terms"
            name="penalty_terms"
            label="Penalty Terms"
            variant="outlined"
            color="success"
            value={values.penalty_terms}
            onChange={handleChange}
            error={touched.penalty_terms && Boolean(errors.penalty_terms)}
            helperText={touched.penalty_terms && errors.penalty_terms}
          />
          <TextField
            id="replacement_time"
            name="replacement_time"
            label="Replacement Time"
            variant="outlined"
            color="success"
            value={values.replacement_time}
            onChange={handleChange}
            error={touched.replacement_time && Boolean(errors.replacement_time)}
            helperText={touched.replacement_time && errors.replacement_time}
          />

          <TextField
            id="validity_sow"
            name="validity_sow"
            label="SOW Validity"
            variant="outlined"
            color="success"
            value={values.validity_sow}
            onChange={handleChange}
            error={touched.validity_sow && Boolean(errors.validity_sow)}
            // helperText={touched.validity_sow && errors.validity_sow}
          />
          <TextField
            id="warranty"
            name="warranty"
            label="Warranty"
            variant="outlined"
            color="success"
            value={values.warranty}
            onChange={handleChange}
            error={touched.warranty && Boolean(errors.warranty)}
            helperText={touched.warranty && errors.warranty}
          />
          <TextField
            id="terms_conditions"
            name="terms_conditions"
            label="Terms and Conditions"
            variant="outlined"
            multiline
            rows={4}
            color="success"
            value={values.terms_conditions}
            onChange={handleChange}
            error={touched.terms_conditions && Boolean(errors.terms_conditions)}
            helperText={touched.terms_conditions && errors.terms_conditions}
          />
          <div className="col-span-full flex flex-col gap-4">
            <h1 className="text-2xl">Shipping Details:-</h1>
            <div className="w-full grid grid-flow-col">
              <TextField
                id="method_shipping"
                name="method_shipping"
                label="Shipping Method"
                variant="outlined"
                color="success"
                select
                sx={{ width: "300px" }}
                value={values.method_shipping}
                onChange={handleChange}
                error={
                  touched.method_shipping && Boolean(errors.method_shipping)
                }
                helperText={touched.method_shipping && errors.method_shipping}
              >
                {shipping_method.map((item) => (
                  <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
                    {item.TypeName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="payment_mode"
                name="payment_mode"
                label="Payment Mode"
                select
                variant="outlined"
                color="success"
                sx={{ width: "300px" }}
                value={values.payment_mode}
                onChange={handleChange}
                error={touched.payment_mode && Boolean(errors.payment_mode)}
                helperText={touched.payment_mode && errors.payment_mode}
              >
                {payment_method.map((item) => (
                  <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
                    {item.TypeName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="shipping_terms"
                name="shipping_terms"
                label="Payment Terms"
                variant="outlined"
                select
                color="success"
                sx={{ width: "300px" }}
                value={values.shipping_terms}
                onChange={handleChange}
                error={touched.shipping_terms && Boolean(errors.shipping_terms)}
                helperText={touched.shipping_terms && errors.shipping_terms}
              >
                {payment_terms.map((item) => (
                  <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
                    {item.TypeName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="w-full col-span-full ">
            <h1 className="text-2xl">Upload Images</h1>
            <div className="w-full grid grid-flow-col ">
              <ImageSelector props={props} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default React.memo(SopForm);
