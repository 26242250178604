import { useState, useEffect } from "react";
import { Navbar, Typography, IconButton } from "@material-tailwind/react";
import Images from "../../../data/images";
import topmenuItems from "../../../data/lms_topmenu";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { custom_theme } from "../../../custom_them/custom_them";
import React from "react";

interface top_nav_props {
  setOpenNav: Function;
  openNav: boolean;
  setdrop_show: Function;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TopNavType1({ setOpenNav, openNav, setdrop_show }: top_nav_props) {
  const [name, setname] = useState("");
  const {
    lmsData: { all, fresh, followUp, won, closed },
    appData: { filterSearch },
  } = useSelector((state: RootState) => state.lms);
  const all_lead_paths = {
    all_path: "/lms",
    fresh_path: "/lms/fresh",
    follow_up_path: "/lms/follow-up",
    walk_in_path: "/lms/walkin",
    in_future_path: "/lms/in-future",
    won_path: "/lms/won",
    closed_path: "/lms/closed",
    re_enquired_path: "/lms/re-enquired",
  };
  const location = useLocation();
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      const email = user.email;
      setname(email?.[0] ?? "");
    }
    handle_active_tab();
  }, []);
  const [value, setValue] = useState(0);
  const { search } = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleCount = (id: number[]) => {
    if (filterSearch) {
      if (!id.length) {
        return filterSearch.length;
      } else {
        const data = filterSearch.filter((item) =>
          id.includes(item.LeadStatus)
        );
        return data.length;
      }
    }
    return null;
  };
  const data_total = (index: number) => {
    switch (index) {
      case 0:
        return filterSearch?.length ? handleCount([]) : all?.length ?? 0;
      case 1:
        return filterSearch?.length ? handleCount([10]) : fresh?.length ?? 0;
      case 2:
        return filterSearch?.length
          ? handleCount([11, 18, 19, 20, 21, 22, 23, 24, 31, 43, 45])
          : followUp?.length ?? 0;
      case 3:
        return filterSearch?.length
          ? handleCount([14, 108, 106, 109])
          : won?.length ?? 0;
      case 4:
        return filterSearch?.length
          ? handleCount([15, 25, 26, 27, 30, 37, 44, 107])
          : closed?.length ?? 0;
      default:
        return null ?? 0;
    }
  };
  const handle_active_tab = () => {
    switch (location.pathname) {
      case all_lead_paths.all_path:
        setValue(0);
        break;
      case all_lead_paths.fresh_path:
        setValue(1);
        break;
      case all_lead_paths.follow_up_path:
        setValue(2);
        break;
      case all_lead_paths.won_path:
        setValue(3);
        break;
      case all_lead_paths.closed_path:
        setValue(4);
        break;
      default:
        return null;
    }
  };

  const navList = (
    <Box sx={{ width: "100%" }}>
      <Box>
        <ThemeProvider theme={custom_theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="primary"
            indicatorColor="primary"
          >
            {topmenuItems.map((item, index) => {
              return (
                <Tab
                  label={`${item.label}(${data_total(index)})`}
                  {...a11yProps(index)}
                  component={Link}
                  to={{ pathname: item.path, search }}
                  key={item.label}
                />
              );
            })}
          </Tabs>
        </ThemeProvider>
      </Box>
    </Box>
  );

  return (
    <Navbar className="max-w-full py-2 px-6  lg:py-4">
      <div className="flex items-center justify-between text-blue-gray-900 ">
        <Typography
          as="a"
          href="#"
          variant="small"
          className="mr-4 cursor-pointer py-1.5 font-normal  "
        >
          <img
            src={Images.brand_logo}
            alt="logo"
            className="w-18 h-8 ml-2 lg:ml-0 mr-2 cursor-pointer"
          />
        </Typography>
        <div className="hidden lg:block">{navList}</div>

        <Avatar
          sx={{ bgcolor: deepOrange[500] }}
          onClick={(e) => {
            e.stopPropagation();
            setdrop_show(true);
          }}
          className="cursor-pointer"
        >
          {name.toUpperCase()}
        </Avatar>
        <IconButton
          variant="text"
          className=" h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden order"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
    </Navbar>
  );
}
export default React.memo(TopNavType1);
