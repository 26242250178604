import * as React from "react";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import { lmsData } from "../../../../models/lms_data_api";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import { useSearchParams } from "react-router-dom";
import FilterMenu from "../filter_menu/filter_menu";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import lmsFilter from "../../../../lms_filter_methods/lms_filter";
import { handlePaginationData } from "../../../../lms_utility_func";
import { useLocation } from "react-router-dom";
import { lms_sub_stages_drp_api } from "../../../../API/api";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { setLmsData } from "../../../../features/lms_actions/lmsSlice";
interface filterMenuProps {
  page: number;
  row: number;
}
export interface sub_lebel_options_type {
  label: string;
  id: number;
  parent_id?: string;
}
const lead_type_check_boxes = [
  { label: "B2C", id: 2 },
  { label: "B2B", id: 1 },
  { label: "Franchise", id: 127 },
];
const quick_filter_menu = [
  {
    label: "Follow up",
    key: "NextFollowUp",
  },

  {
    label: "Created",
    key: "CreatedOn",
  },
  {
    label: "Updated",
    key: "UpdatedOn",
  },
];

export default function FilterPopOver({ page, row }: filterMenuProps) {
  // all states decalaration
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [user_options, setuser_options] = useState<
    sub_lebel_options_type[] | []
  >([]);
  const [activeMenu, setactiveMenu] = useState<any>({});
  const [selected_quick_filter, setselected_quick_filter] = useState<any>([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [selected_sub_stage, setselected_sub_stage] = useState<
    [] | sub_lebel_options_type[]
  >([]);
  const [selected_counseller, setselected_counseller] = useState<
    sub_lebel_options_type[] | []
  >([]);
  const [date_input1, setdate_input1] = useState<Dayjs | null>(null);
  const [date_input2, setdate_input2] = useState<Dayjs | null>(null);
  const [filter_active, setfilter_active] = useState(false);
  const [lead_selected, setlead_selected] = useState<number[]>([2]);
  const dispatch = useDispatch();
  const [sub_stage_options, setsub_stage_options] = useState<
    sub_lebel_options_type[] | []
  >([]);
  const [dateRangeTypeFilterArray, setdateRangeTypeFilterArray] = useState([
    "NextFollowUp",
  ]);
  // variables
  const { pathname } = useLocation();

  // redux store access
  const {
    lms: {
      lmsUsers: { users },
      historyData: { data: oldData },
      lmsData: { all },
    },
    user: { token_id },
  } = useSelector((state: RootState) => state);
  // created an instance from lms filter class
  let filterMethods = new lmsFilter(all);

  // Functions
  // function to open popover
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // useEffect methods
  useEffect(() => {
    clearFilter();
  }, [pathname]);

  // useEffect(() => {
  //   if (lead_status_menu) {
  //     let result: sub_lebel_options_type[] = [];
  //     for (let item of lead_status_menu) {
  //       if (item.id === 11 || item.id === 15) {
  //         result = [...result, ...item.sub_lebel];
  //       }
  //     }
  //     setsub_status_options(result);
  //
  // }, [lead_status_menu]);
  useEffect(() => {
    if (users) {
      let updated_data = users.map((item) => {
        return {
          label: item.userName,
          id: item.userId,
        };
      });
      setuser_options([...updated_data]);
    }
  }, [users]);
  useEffect(() => {
    if (token_id) {
      customFetchWithToken(lms_sub_stages_drp_api, token_id).then((data) =>
        setsub_stage_options([...data.DATA])
      );
    }
  }, [token_id]);
  //function to close popover
  const handleClose = () => {
    setAnchorEl(null);
  };
  // functions to manage multiple select data of filter
  const handleQuickFilterArray = (key: string) => {
    let index = selected_quick_filter.indexOf(key);
    if (index === -1) {
      selected_quick_filter.push(key);
    } else {
      selected_quick_filter.splice(index, 1);
    }
    setselected_quick_filter([...selected_quick_filter]);
  };
  const handleDateRangeTypeFilterArray = (key: string) => {
    let index = dateRangeTypeFilterArray.indexOf(key);
    if (index === -1) {
      dateRangeTypeFilterArray.push(key);
    } else {
      dateRangeTypeFilterArray.splice(index, 1);
    }
    setdateRangeTypeFilterArray(dateRangeTypeFilterArray);
  };
  // all possible condition of filter
  const conditional_statement = {
    filter_type1:
      activeMenu.hasOwnProperty(0) &&
      activeMenu.hasOwnProperty(1) &&
      activeMenu.hasOwnProperty(2),
    filter_type2:
      activeMenu.hasOwnProperty(0) &&
      activeMenu.hasOwnProperty(1) &&
      selected_quick_filter.length > 0,
    filter_type3: activeMenu.hasOwnProperty(0) && activeMenu.hasOwnProperty(1),
    filter_type4:
      activeMenu.hasOwnProperty(0) && selected_quick_filter.length > 0,
    filter_type5:
      activeMenu.hasOwnProperty(1) && selected_quick_filter.length > 0,
    filter_type6: activeMenu.hasOwnProperty(0) && activeMenu.hasOwnProperty(1),
    filter_type7: activeMenu.hasOwnProperty(1) && activeMenu.hasOwnProperty(2),
    filter_type8: activeMenu.hasOwnProperty(0) && activeMenu.hasOwnProperty(2),
    filter_type9: activeMenu.hasOwnProperty(0),
    filter_type10: activeMenu.hasOwnProperty(1),
    filter_type11: activeMenu.hasOwnProperty(2),
    filter_type12: selected_quick_filter.length > 0,
  };
  // managing add on filter
  const handleMenu = (index: number) => {
    activeMenu[index] = null;
    setactiveMenu({ ...activeMenu });
  };
  // main function to manage filter
  const handleApplyFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (conditional_statement.filter_type1) {
      let data_acc_to_date_range = filterMethods.filterByDateRange(
        dateRangeTypeFilterArray,
        date_input1,
        date_input2
      );
      let data_acc_stage = filterMethods.filterByStage(
        selected_sub_stage,
        data_acc_to_date_range
      );
      let result = filterMethods.filterByUser(
        selected_counseller,
        data_acc_stage
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type2) {
      let today_quick_filter_data = filterMethods.filterByTodayDate(
        selected_quick_filter
      );
      let data_acc_stage = filterMethods.filterByStage(
        selected_sub_stage,
        today_quick_filter_data
      );
      let result = filterMethods.filterByUser(
        selected_counseller,
        data_acc_stage
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type3) {
      let data_acc_user = filterMethods.filterByUser(selected_counseller);
      let result = filterMethods.filterByStage(
        selected_sub_stage,
        data_acc_user
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type4) {
      let today_quick_filter_data = filterMethods.filterByTodayDate(
        selected_quick_filter
      );
      let result = filterMethods.filterByUser(
        selected_counseller,
        today_quick_filter_data
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type5) {
      let today_quick_filter_data = filterMethods.filterByTodayDate(
        selected_quick_filter
      );
      let result = filterMethods.filterByStage(
        selected_sub_stage,
        today_quick_filter_data
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type6) {
      let data_acc_counseller = filterMethods.filterByUser(selected_counseller);
      let result = filterMethods.filterByStage(
        selected_sub_stage,
        data_acc_counseller
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type7) {
      let data_acc_sub_stage = filterMethods.filterByStage(selected_sub_stage);
      let result = filterMethods.filterByDateRange(
        dateRangeTypeFilterArray,
        date_input1,
        date_input2,
        data_acc_sub_stage
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type8) {
      let data_acc_counseller = filterMethods.filterByUser(selected_counseller);
      let result = filterMethods.filterByDateRange(
        dateRangeTypeFilterArray,
        date_input1,
        date_input2,
        data_acc_counseller
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type9) {
      let result = filterMethods.filterByUser(selected_counseller);
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type10) {
      let result = filterMethods.filterByStage(selected_sub_stage);
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type11) {
      setselected_quick_filter([]);
      let result = filterMethods.filterByDateRange(
        dateRangeTypeFilterArray,
        date_input1,
        date_input2
      );
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    } else if (conditional_statement.filter_type12) {
      let result = filterMethods.filterByTodayDate(selected_quick_filter);
      if (result)
        handlePaginationData(
          result,
          page,
          row,
          "No match found",
          false,
          dispatch
        );
    }
    setfilter_active(true);
    handleClose();
  };
  // function to clear filter
  const clearFilter = () => {
    setselected_quick_filter([]);
    setselected_counseller([]);
    setselected_sub_stage([]);
    setactiveMenu({});
    handleClose();
    setdateRangeTypeFilterArray(["NextFollowUp"]);
    setdate_input1(null);
    setdate_input2(null);
    setfilter_active(false);
    if (oldData) {
      handlePaginationData(oldData, page, row, "NO records", true, dispatch);
    }
  };
  const getDataAccToLeadType = useCallback(() => {
    if (lead_selected.length) {
      dispatch(setLmsData({ leadType: lead_selected }));
    }
  }, [lead_selected]);
  const handleCheckBoxes = (id: number) => {
    let index = lead_selected.indexOf(id);
    if (index !== -1) {
      lead_selected.splice(index, 1);
    } else {
      lead_selected.push(id);
    }
    setlead_selected([...lead_selected]);
    getDataAccToLeadType();
  };
  const check_check_boxes = (id: number) => {
    if (lead_selected.indexOf(id) !== -1) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <div>
        <Tooltip placement="top" title="filter">
          <div
            onClick={handleClick}
            className={`flex gap-2 items-center p-2 rounded-md cursor-pointer ${
              filter_active ? "bg-[#b4eabc]" : "bg-none"
            }  active:bg-[#00aa13] hover:bg-blue-gray-200`}
          >
            <FilterListIcon color="success" sx={{ fontSize: "1.4rem" }} />
            <span className="text-[1rem]">
              Filter{filter_active && <span>:Active</span>}
            </span>
          </div>
        </Tooltip>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="min-w-[365px]  max-h-[650px] overflow-auto p-6">
          <div className="flex flex-col gap-6 w-full">
            <div className="flex gap-2">
              {lead_type_check_boxes.map((item) => (
                <FormControlLabel
                  label={item.label}
                  key={item.id}
                  control={
                    <Checkbox
                      value=""
                      checked={check_check_boxes(item.id)}
                      onChange={() => handleCheckBoxes(item.id)}
                      color="success"
                    />
                  }
                />
              ))}
            </div>
            <span className="text-gray-400">Quick Date Filters</span>
            <span className="text-sm">Today </span>
            {/* cheap container  */}

            <div className="flex gap-3 flex-wrap">
              {quick_filter_menu.map((item) => (
                <Chip
                  key={item.key}
                  label={item.label}
                  variant="outlined"
                  sx={{
                    cursor: "pointer",
                    backgroundColor: `${
                      selected_quick_filter.indexOf(item.key) >= 0
                        ? "#d8ebd9"
                        : null
                    }`,
                    color: `${
                      selected_quick_filter.indexOf(item.key) >= 0
                        ? "#0b420b"
                        : null
                    }`,
                  }}
                  onClick={() => {
                    handleQuickFilterArray(item.key);
                  }}
                  disabled={activeMenu.hasOwnProperty(2)}
                />
              ))}
            </div>
            <div className="flex flex-col gap-6">
              {activeMenu.hasOwnProperty(0) && (
                <div className="flex flex-col gap-6">
                  <div>
                    <Chip
                      label="Counseller name"
                      variant="outlined"
                      sx={{ cursor: "pointer" }}
                      onDelete={() => {
                        delete activeMenu[0];
                        setactiveMenu({ ...activeMenu });
                      }}
                    />
                  </div>
                  <Box sx={{ width: "100%" }}>
                    <Autocomplete
                      multiple
                      disablePortal
                      id="combo-box-demo"
                      options={user_options}
                      sx={{ width: "100%" }}
                      value={selected_counseller}
                      onChange={(e, values) => setselected_counseller(values)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Counseller name"
                          color="success"
                          variant="standard"
                        />
                      )}
                    />
                  </Box>
                </div>
              )}
              {activeMenu.hasOwnProperty(1) && (
                <div className="flex flex-col gap-6">
                  <div>
                    <Chip
                      label="Sub-stage"
                      variant="outlined"
                      sx={{ cursor: "pointer" }}
                      onDelete={() => {
                        delete activeMenu[1];
                        setactiveMenu({ ...activeMenu });
                      }}
                    />
                  </div>
                  <Box sx={{ width: "100%" }}>
                    <Autocomplete
                      multiple
                      disablePortal
                      id="combo-box-demo"
                      options={sub_stage_options}
                      value={selected_sub_stage}
                      sx={{ width: "100%" }}
                      onChange={(e, values) => setselected_sub_stage(values)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub stage"
                          color="success"
                          variant="standard"
                        />
                      )}
                    />
                  </Box>
                </div>
              )}
              {activeMenu.hasOwnProperty(2) && (
                <div className="flex flex-col gap-6">
                  <div>
                    <Chip
                      label="Date"
                      variant="outlined"
                      sx={{ cursor: "pointer" }}
                      onDelete={() => {
                        delete activeMenu[2];
                        setactiveMenu({ ...activeMenu });
                      }}
                    />
                  </div>
                  <div className="w-full flex items-center  gap-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From"
                        value={date_input1}
                        onChange={(newValue) => setdate_input1(newValue)}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="To"
                        value={date_input2}
                        onChange={(newValue) => setdate_input2(newValue)}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="flex gap-3">
                    {quick_filter_menu.map((item) => {
                      return (
                        <div className="flex items-center gap-3" key={item.key}>
                          <label htmlFor={item.key}>{item.label}</label>
                          <input
                            type="checkbox"
                            id={item.key}
                            name={item.key}
                            className="form-check-input appearance-none h-5 w-5 border border-gray-300 rounded-sm bg-white checked:bg-[#00aa13] checked:border-[#00aa13] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            onChange={() =>
                              handleDateRangeTypeFilterArray(item.key)
                            }
                            defaultChecked={
                              dateRangeTypeFilterArray.indexOf(item.key) !== -1
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div>
              <FilterMenu handleMenu={handleMenu} />
            </div>
            <div className="self-end flex gap-2 items-center">
              <Button variant="outlined" color="inherit" onClick={clearFilter}>
                Clear all
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={handleApplyFilter}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
