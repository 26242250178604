import * as Yup from "yup";
export type SowInitialValuesType = {
  payment_terms: string;
  order_projection: string;
  delivery_time: string;
  penalty_terms: string;
  validity_sow: Date;
  warranty: string;
  design: string[] | null;
  shipping_terms: number | null;
  terms_conditions: string;
  time_policy: string;
  cost_ownership: string;
  sow_reference_no: string;
  createdBy: number;
  updatedBy: number;
  replacement_time: number;
  payment_mode: number;
  method_shipping: number;
  vendorId: number | null;
  productId: number | null;
};

const sowSchema = Yup.object().shape({
  payment_terms: Yup.string().required("Please enter payment terms"),
  order_projection: Yup.string().required("Please enter order projection"),
  delivery_time: Yup.string().required("Please enter delivery time"),
  penalty_terms: Yup.string().required("Please enter penalty terms"),
  validity_sow: Yup.date().required("Please enter validity sow").nullable(),
  warranty: Yup.string().required("Please enter warranty"),
  design: Yup.array().of(Yup.string()).required("Please Select Image"),
  terms_conditions: Yup.string().required("Please enter terms and conditions"),
  time_policy: Yup.string().required("Please enter time policy"),
  cost_ownership: Yup.string().required("Please enter cost ownership"),
  sow_reference_no: Yup.string().required("Please enter SOW reference number"),
  createdBy: Yup.number(),
  updatedBy: Yup.number(),
  replacement_time: Yup.number().required("Please enter replacement time"),
  shipping_terms: Yup.number().required("Please enter shipping terms"),
  payment_mode: Yup.number().required("Please enter payment mode"),
  method_shipping: Yup.number().required("Please enter method shipping"),
  vendorId: Yup.number(),
  productId: Yup.number(),
});

const sowInitialValues: SowInitialValuesType = {
  payment_terms: "Net 30",
  order_projection: "1000",
  delivery_time: "2 weeks",
  penalty_terms: "10% penalty after 7 days",
  validity_sow: new Date("2024-12-30 12:00:00"),
  warranty: "1 year",
  design: null,
  shipping_terms: null,
  terms_conditions: "All sales are final",
  time_policy: "Flexible working hours",
  cost_ownership: "Buyer",
  sow_reference_no: "sow12Pj23",
  createdBy: 1,
  updatedBy: 1,
  replacement_time: 90,
  payment_mode: 97,
  method_shipping: 92,
  vendorId: 0,
  productId: 0,
};
export { sowSchema, sowInitialValues };
