import React from "react";
import { Button } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import StickyHeadTable from "../../Components/StickyHeadTable/StickyHeadTable";
import customFetch from "../../../../API/CustomFetch";
import { vechileoptionType } from "../../../../models/API_data";
import showToastMessage from "../../../../Tostify/Index";
import { ToastContainer } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import ViewButton from "../../Components/ViewButton/ViewButton";
import { new_vehicle_in_drop_url_type } from "../../../../models/API_data";
import {
  vechileInPosturl,
  vehicle_in_all_hrc,
  vehicle_ready_to_in,
} from "../../../../API/api";
import "./VehicleIn.css";
import { columns } from "../../../../data/vehicle_In_table_data";
import AddButton from "../../Components/AddButton/AddButton";
import ImageViewModal from "../../Components/Imge_view_model/Imge_view_model";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import { set_vehicle_in_table_data } from "../../../../features/hrc_actions/hrc_slice";
import CircularProgress from "@mui/material/CircularProgress";

export default function VechileIn() {
  let [flag, setflag] = useState(true);
  let [Vehicle_Profile_Id, setVehicle_Profile_Id] = useState("");
  let [kmsdriven, setkmsdriven] = useState("");

  let [optionMenu, setoptionMenu] = useState<
    [] | new_vehicle_in_drop_url_type[]
  >([]);

  const [Vehicle_Make, setVehicle_Make] = useState(false);
  const [Vehicle_Brand, setVehicle_Brand] = useState(false);
  const [Reer_Wheel_Type, setReer_Wheel_Type] = useState(false);
  const dispatch = useDispatch();
  const {
    vehicle_in: {
      data: { vehicle_in_table_data },
      loader,
    },
  } = useSelector((state: RootState) => state.hrc);

  useEffect(() => {
    customFetch(vehicle_ready_to_in).then((data) =>
      setoptionMenu([...data.DATA])
    );
    customFetch(vehicle_in_all_hrc).then((data) => {
      let newData = data.DATA.map((item: vechileoptionType) => {
        let Vehicle_in_time_date = new Date(
          `${item.Vehicle_In_Time}`
        ).toLocaleDateString("default", {
          month: "short",
          year: "numeric",
          day: "numeric",
        });
        let Updated_on_time_date = new Date(
          `${item.UpdatedOn}`
        ).toLocaleDateString("default", {
          month: "short",
          year: "numeric",
          day: "numeric",
        });
        item.Vehicle_In_Time = Vehicle_in_time_date;
        item.UpdatedOn = Updated_on_time_date;
        return item;
      });
      dispatch(set_vehicle_in_table_data([...newData]));
    });
  }, []);
  let handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let responseData = {
      vehicleProfileId: Number(Vehicle_Profile_Id),
      kmsdriven: Number(kmsdriven),
      vehicleMake: Vehicle_Make,
      vehicleModel: Vehicle_Brand,
      rearWheelType: Reer_Wheel_Type,
    };
    let body = JSON.stringify(responseData);

    let result = customFetch(vechileInPosturl, body, { method: "POST" });
    result.then((data) => showToastMessage(data.SUCCESS, data.MESSAGE));
    setVehicle_Profile_Id("");
    setkmsdriven("");
    setVehicle_Make(false);
    setVehicle_Brand(false);
    setReer_Wheel_Type(false);
  };

  const styles = {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    width: "100%",
    "@media(max-width: 576px)": {
      flexDirection: "column",
      gap: "40px",
    },
  };

  const image_type = ["right", "left", "top", "bottom"];
  const [selected_left_file, setselected_left_file] = useState<File | null>(
    null
  );
  const [selected_right_file, setselected_right_file] = useState<File | null>(
    null
  );
  const [selected_front_file, setselected_front_file] = useState<File | null>(
    null
  );
  const [selected_rear_file, setselected_rear_file] = useState<File | null>(
    null
  );
  function handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      let { name } = event.target;
      switch (name) {
        case "left":
          setselected_left_file(event.target.files[0]);
          console.log("left");
          break;
        case "front":
          setselected_front_file(event.target.files[0]);
          console.log("front");
          break;
        case "right":
          setselected_right_file(event.target.files[0]);
          console.log("right");
          break;

        case "rear":
          setselected_rear_file(event.target.files[0]);
          console.log("rear");
          break;
      }
    }
  }

  return (
    <div className="w-full h-full flex items-center gap-16 flex-col">
      <ToastContainer />

      {flag ? (
        <div className="fixed md:right-10 right-0 top-[90%] z-10">
          <AddButton setflag={setflag} />
        </div>
      ) : (
        <div className="fixed md:right-10 right-0  top-[90%] z-10">
          <ViewButton setflag={setflag} />
        </div>
      )}
      {flag ? (
        <div className="w-full h-full flex justify-center ">
          {loader ? (
            <CircularProgress color="success" size={100} />
          ) : vehicle_in_table_data?.length === 0 ? (
            <div className="flex w-full h-full justify-center  gap-4 items-center">
              <h1 className="text-2xl text-red-900 tracking-widest">
                No any available data
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          ) : (
            <div className="md:w-11/12 w-[96%]">
              <StickyHeadTable
                data={vehicle_in_table_data}
                ColumnsData={columns}
              />
            </div>
          )}
        </div>
      ) : (
        <div className=" bg-gray-50 w-11/12 flex items-center  p-8 rounded">
          <form
            className="flex justify-evenly flex-col gap-6 w-full"
            onSubmit={(e) => handleSubmit(e)}
          >
            <Box sx={{ ...styles }}>
              <FormControl
                sx={{
                  minWidth: 225,
                  width: 450,
                  "@media(max-width:576px)": {
                    width: "100%",
                  },
                }}
                required
              >
                <InputLabel id="demo-simple-select-label">
                  Vehicle Reg-No
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={Vehicle_Profile_Id?.toString()}
                  label="Vehicle Reg-No"
                  onChange={(event) => {
                    setVehicle_Profile_Id(event.target.value as string);
                  }}
                >
                  {optionMenu?.map((item) => (
                    <MenuItem
                      key={item.VehicleProfileId}
                      value={item.VehicleProfileId.toString()}
                    >
                      {item.Registration_No}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="KMs Driven"
                variant="outlined"
                value={kmsdriven}
                onChange={(e) => setkmsdriven(e.target.value)}
                sx={{
                  minWidth: 225,
                  width: 450,
                  "@media(max-width:576px)": {
                    width: "100%",
                  },
                }}
                required
              />
            </Box>
            {optionMenu.map((item) => {
              if (item.VehicleProfileId === Number(Vehicle_Profile_Id)) {
                return (
                  <div
                    className="flex w-full justify-between flex-col-reverse gap-8 md:gap-0 md:flex-row "
                    key={item.VehicleProfileId}
                  >
                    <div>
                      <p>Please confirm the following details of the vehicle</p>
                      <div
                        className="w-full flex flex-col gap-6"
                        key={item.VehicleProfileId}
                      >
                        <div>
                          <Checkbox
                            id="brand"
                            onChange={(e) => setVehicle_Make(e.target.checked)}
                            checked={Vehicle_Make}
                          />
                          <label htmlFor="brand">
                            Vehicle Make:&nbsp;
                            <span className="font-bold uppercase">
                              {item.Brand_Name}
                            </span>
                          </label>
                        </div>
                        <div>
                          <Checkbox
                            id="model"
                            onChange={(e) => setVehicle_Brand(e.target.checked)}
                            checked={Vehicle_Brand}
                          />
                          <label htmlFor="model">
                            Vehicle Variant:&nbsp;
                            <span className="font-bold uppercase">
                              {item.Model_Name}
                            </span>
                          </label>
                        </div>
                        <div>
                          <Checkbox
                            id="rearWheelType"
                            onChange={(e) =>
                              setReer_Wheel_Type(e.target.checked)
                            }
                            checked={Reer_Wheel_Type}
                            disabled={item.Rear_Whee_ld ? false : true}
                          />
                          <label htmlFor="rearWheelType">
                            Rear Wheel Type: &nbsp;
                            <span className="font-bold uppercase">
                              {item.Wheel_type}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-6">
                      <div className="flex">
                        <span className="font-bold">Rider Name:&nbsp;</span>
                        <span> {item.RiderName}</span>
                      </div>
                      <div>
                        <span className="font-bold">Mobile Number: &nbsp;</span>
                        <span> {item.MobileNumber}</span>
                      </div>
                      <div>
                        <span className="font-bold">
                          Alternate Mobile Number: &nbsp;
                        </span>
                        <span> {item.AlternateMobileNumber}</span>
                      </div>
                      <div>
                        <span className="font-bold">Email: &nbsp;</span>
                        <span> {item.Email}</span>
                      </div>
                      <div>
                        <span className="font-bold">Address: &nbsp;</span>
                        <span> {item.Address}</span>
                      </div>
                      <div>
                        <span className="font-bold">Vehicle Make: &nbsp;</span>
                        <span> {item.Brand_Name}</span>
                      </div>
                      <div>
                        <span className="font-bold">Vehicle Make: &nbsp;</span>
                        <span> {item.Model_Name}</span>
                      </div>
                      <div>
                        <span className="font-bold">Chasis No: &nbsp;</span>
                        <span> {item.Chasis_No ?? "----"}</span>
                      </div>
                      <div>
                        <span className="font-bold">Wheel Type: &nbsp;</span>
                        <span> {item.Wheel_type}</span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div className="mt-10 flex flex-col gap-10 w-full ">
              <p className="font-bold text-3xl">
                Upload the following Images:{" "}
              </p>
              <div className="flex flex-col md:gap-3 gap-10 ">
                <div className="flex md:w-11/12 w-full flex-col md:gap-0 gap-10 md:flex-row justify-between ">
                  <div className="flex md:w-[17rem] w-full  justify-between items-center relative">
                    <span className="text-2xl">Left:</span>

                    <div className="file">
                      <label htmlFor="left">+</label>
                      <input
                        type="file"
                        id="left"
                        name="left"
                        className="fileInput"
                        accept="image/*"
                        onChange={handleFileInputChange}
                      />
                    </div>

                    <div className="absolute -right-[25%] cursor-pointer">
                      <ImageViewModal selectedFile={selected_left_file} />
                    </div>
                  </div>

                  <div className="flex   md:w-[17rem] w-full justify-between  items-center relative">
                    <span className="text-2xl">Right:</span>

                    <div className="file ">
                      <label htmlFor="right">+</label>
                      <input
                        type="file"
                        id="right"
                        name="right"
                        className="fileInput"
                        accept="image/*"
                        onChange={handleFileInputChange}
                      />
                    </div>
                    <div className="absolute -right-[25%] cursor-pointer">
                      <ImageViewModal selectedFile={selected_right_file} />
                    </div>
                  </div>
                </div>
                <div className="flex  md:w-11/12 w-full flex-col md:gap-0 gap-10 md:flex-row justify-between">
                  <div className="flex md:w-[17rem] w-full   justify-between items-center relative ">
                    <span className="text-2xl">Front:</span>

                    <div className="file">
                      <label htmlFor="front">+</label>
                      <input
                        type="file"
                        id="front"
                        name="front"
                        className="fileInput"
                        accept="image/*"
                        onChange={handleFileInputChange}
                      />
                    </div>
                    <div className="absolute -right-[25%] cursor-pointer">
                      <ImageViewModal selectedFile={selected_front_file} />
                    </div>
                  </div>
                  <div className="flex md:w-[17rem] w-full  justify-between items-center relative">
                    <span className="text-2xl">Rear:</span>

                    <div className="file">
                      <label htmlFor="rear">+</label>
                      <input
                        type="file"
                        id="rear"
                        name="rear"
                        className="fileInput"
                        accept="image/*"
                        onChange={handleFileInputChange}
                      />
                    </div>
                    <div className="absolute -right-[25%] cursor-pointer">
                      <ImageViewModal selectedFile={selected_rear_file} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Button className="w-32 flex-none self-end" type="submit">
              Check In
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
