import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/user_slice";
import lmsReducer from "../features/lms_actions/lmsSlice";
import hrcReducer from "../features/hrc_actions/hrc_slice";
import adminReducer from "../features/admin_actions/admin_slice";
import accessReducer from "../features/access_slice/access_slice";
import { combineReducers } from "@reduxjs/toolkit";
const rootReducer = combineReducers({
  user: userReducer,
  lms: lmsReducer,
  hrc: hrcReducer,
  admin: adminReducer,
  access: accessReducer,
});
export const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
