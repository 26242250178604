import React, { useState } from "react";
import { lmsData, opentoaddData } from "../../../../models/lms_data_api";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Accordian from "../../Components/Accordian";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import Loader from "../../../../Global_Component/loader/loader";
import { handlePaginationData } from "../../../../lms_utility_func";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import PaginationControlled from "../../Components/pagination/pagination";

// import { filter_data_acc_user } from "../../../../lms_utility_func";
type FreshProps = {
  opentoAdd: opentoaddData;
  setOpentoAdd: Function;
};

export default function Fresh({ opentoAdd, setOpentoAdd }: FreshProps) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [row, setrow] = useState(10);
  const dispatch = useDispatch();
  const {
    lmsData: { fresh },
    appData: { dataAccToPage, loader, message, filterSearch },
  } = useSelector((state: RootState) => state.lms);
  React.useEffect(() => {
    if (!filterSearch) {
      if (fresh !== null) {
        handlePaginationData(fresh, page, row, "No records", true, dispatch);
      }
    } else {
      handlePaginationData(
        filterSearch,
        page,
        row,
        "No Match Found",
        false,
        dispatch
      );
    }
  }, [fresh, page, row]);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  // React.useEffect(() => {
  //   let user = searchParams.get("user");
  //   let Lead = searchParams.get("LeadId");
  //   if (user && fresh_leads.length > 0) {
  //     setvariable_data([...filter_data_acc_user(fresh_leads, Number(user))]);
  //   } else if (Lead) {
  //     setvariable_data(null);
  //     setSearchParams("");
  //   }
  // }, [searchParams, fresh_leads]);
  if (loader) {
    return (
      <div className="w-full h-[35rem] flex-auto flex justify-center items-center ">
        <Loader />
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-5 ">
          {dataAccToPage?.length ? (
            dataAccToPage.map((item) => (
              <Accordian
                opentoAdd={opentoAdd}
                setOpentoAdd={setOpentoAdd}
                item={item}
                key={item.LeadId}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-full w-full gap-2">
              <h1 className="text-2xl text-red-900 tracking-widest">
                {message}
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          )}
        </div>
        <div className="flex self-start w-full justify-between items-center gap-8  py-6">
          <div className="flex  ">
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel
                id="demo-simple-select-autowidth-label"
                color="success"
              >
                row
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={row}
                onChange={(e) => setrow(Number(e.target.value))}
                autoWidth
                color="success"
                label="row"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={80}>80</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <PaginationControlled
              handleChange={handleChange}
              page={page}
              row={row}
            />
          </div>
        </div>
      </div>
    );
  }
}
