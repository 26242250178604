import { useState, useEffect } from "react";
import MobileNavComponent from "../Components/mobile_nav/mobile_nav";
import NavContent from "../../../Global_Component/nav_content/nav_content";
import TopNav from "../Components/top_nav/top_nav";
import { Outlet } from "react-router-dom";
import SidebarMenu from "../../../data/sidebar_menu";
import DropBar from "../../../Global_Component/dropbar/dropbar";
import PassResetModal from "../../../Global_Component/dropbar/pass_rst_mdl";

export default function Home() {
  const [openNav, setOpenNav] = useState(false);
  const [close_side_nav, setclose_side_nav] = useState(false);
  const [drop_show, setdrop_show] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleNav = () => {
    setOpenNav((openNav) => !openNav);
  };

  useEffect(() => {
    if (window.innerWidth > 960) {
      setOpenNav(false);
      setclose_side_nav(true);
    }
    document.title = "HRC | GreenTiger Console";
  }, []);

  return (
    <div
      className="flex w-full flex-col h-screen "
      onClick={() => setdrop_show(false)}
    >
      {/* navbar */}
      <div className="w-full flex-none">
        <TopNav
          openTopNav={openNav}
          setTopNav={toggleNav}
          setdrop_show={setdrop_show}
        ></TopNav>

        <MobileNavComponent openSideNav={openNav} setSideNav={toggleNav}>
          <NavContent
            SidebarMenu={SidebarMenu}
            lms={false}
            close_side_nav={close_side_nav}
            setSideNav={toggleNav}
          />
        </MobileNavComponent>
      </div>

      {/* sidenav & content */}
      <div className="flex flex-auto w-full h-[60%]">
        {close_side_nav && (
          <div className=" flex-none w-56  overflow-hidden border-r bg-white shadow-lg hidden lg:inline-block">
            <NavContent
              SidebarMenu={SidebarMenu}
              lms={false}
              close_side_nav={close_side_nav}
              setSideNav={toggleNav}
            />
          </div>
        )}

        {/* set router here */}
        <div className="flex flex-auto w-[75%]  flex-col items-center  gap-8 h-full overflow-auto  relative">
          <Outlet />
        </div>
      </div>
      {drop_show && (
        <DropBar setdrop_show={setdrop_show} setOpen={setOpen} open={open} />
      )}
      <PassResetModal
        setdrop_show={setdrop_show}
        setOpen={setOpen}
        open={open}
      />
    </div>
  );
}
