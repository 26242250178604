import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Rider_data_type } from "../../../../models/API_data";

export default function RiderAccordion({ item }: { item: Rider_data_type }) {
  return (
    <div className="w-full ">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className=" w-full flex justify-center ">
            <div className="lg:w-48 w-32 flex-auto flex justify-center items-center">
              <div className="flex flex-col items-center">
                <span className="font-semibold text-sm md:text-base text-gray-700">
                  Name
                </span>
                <span className="text-sm md:text-base">{item.RiderName}</span>
              </div>
            </div>
            <div className=" lg:w-48 w-32 flex-auto flex justify-center items-center">
              <div className="flex flex-col items-center">
                <span className="font-semibold text-sm md:text-base text-gray-700">
                  Mobile
                </span>
                <span className="text-sm md:text-base">
                  {item.MobileNumber}
                </span>
              </div>
            </div>
            <div className=" lg:w-48 w-32 flex-auto flex justify-center items-center">
              <div className="flex flex-col items-center">
                <span className="font-semibold text-sm md:text-base text-gray-700">
                  Email
                </span>
                <span className="text-sm md:text-base">{item.Email}</span>
              </div>
            </div>
            <div className=" lg:w-48 w-32 flex-auto flex justify-center items-center">
              <div className="flex flex-col items-center">
                <span className="font-semibold text-sm md:text-base text-gray-700">
                  Reg No
                </span>
                <span className="text-sm md:text-base">
                  {item.VehicleRegistrationNumber}
                </span>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col gap-6">
            <div className=" w-full flex justify-center">
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="font-semibold text-sm md:text-base text-gray-700">
                    GST No
                  </span>
                  <span className="text-sm md:text-base">{item.GST_No}</span>
                </div>
              </div>
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="font-semibold text-sm md:text-base text-gray-700">
                    COI No
                  </span>
                  <span className="text-sm md:text-base">{item.COI_No}</span>
                </div>
              </div>
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="text-sm md:text-base font-semibold text-gray-700">
                    Aadhar No
                  </span>
                  <span className="text-sm md:text-base">{item.Aadhar_No}</span>
                </div>
              </div>
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="font-semibold text-sm md:text-base text-gray-700">
                    PAN No
                  </span>
                  <span className="text-sm md:text-base">{item.PAN_No}</span>
                </div>
              </div>
              <div className="flex-initial w-4"></div>
            </div>
            <div className=" w-full flex justify-center">
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="font-semibold text-sm md:text-base text-gray-700">
                    Address
                  </span>
                  <span className="text-sm md:text-base">{item.Address}</span>
                </div>
              </div>
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="font-semibold text-sm md:text-base text-gray-700">
                    AlternateMobileNumber
                  </span>
                  <span className="text-sm md:text-base">
                    {item.AlternateMobileNumber}
                  </span>
                </div>
              </div>
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="font-semibold text-sm md:text-base text-gray-700">
                    Created On
                  </span>
                  <span className="text-sm md:text-base">{item.CreatdOn}</span>
                </div>
              </div>
              <div className="w-48 flex-auto flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <span className="font-semibold text-sm md:text-base text-gray-700">
                    Updated On
                  </span>
                  <span className="text-sm md:text-base">{item.UpdateOn}</span>
                </div>
              </div>
              <div className="flex-initial w-4"></div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
