import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CustomDay from "../Calender";
import Fab from "@mui/material/Fab";
import { Dayjs } from "dayjs";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import { handlePaginationData } from "../../../../lms_utility_func";

type Anchor = "top" | "left" | "bottom" | "right";
interface follow_up_walking_drawer_props {
  value: number;
  page: number;
  row: number;
}

export default function FollowUpWakingDrawer({
  value,
  page,
  row,
}: follow_up_walking_drawer_props) {
  const [state, setState] = React.useState({
    right: false,
  });
  const {
    followUpWalkIn: { appData },
  } = useSelector((state: RootState) => state.lms);
  const dispatch = useDispatch();
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const handleCalendar = (
    selected_date: Dayjs | null,
    setselected_date: Function
  ) => {
    if (!(value === 1)) {
      let filter_data_by_caldr = appData?.filter(
        (item) =>
          new Date(item.NextFollowUp ?? "").setHours(0, 0, 0, 0) ===
          new Date(selected_date?.toString() ?? "").setHours(0, 0, 0, 0)
      );
      if (filter_data_by_caldr) {
        handlePaginationData(
          filter_data_by_caldr,
          page,
          row,
          "No match Found",
          false,
          dispatch
        );
      }
      setselected_date(selected_date);
    }
  };
  const list = (anchor: Anchor) => (
    <Box role="presentation">
      <div className="flex flex-col gap-8 p-4">
        <Tooltip title="close" placement="right">
          <IconButton
            sx={{ alignSelf: "flex-end" }}
            onClick={toggleDrawer("right", false)}
          >
            <CloseIcon
              color="success"
              sx={{
                fontSize: "2.5rem",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="remove filter" placement="top-start">
          <IconButton
            sx={{ cursor: "pointer", alignSelf: "flex-start" }}
            // onClick={() => setvariable_data(null)}
          >
            <FilterAltOffIcon
              color="success"
              sx={{
                fontSize: "2.5rem",
              }}
            />
          </IconButton>
        </Tooltip>

        <h1 className="text-center text-green-700 text-3xl">
          Select date to filter
        </h1>
        <Divider />
        <div className="cursor-pointer">
          <CustomDay handleCalendar={handleCalendar} />
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ backgroundColor: "#00aa13" }}
          onClick={toggleDrawer("right", true)}
        >
          <i className="fa-sharp fa-solid fa-calendar  text-gray-100 text-2xl hover:fa-shake"></i>
        </Fab>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
