import React, { useState, useEffect, ReactNode } from "react";
import AddButton from "../../Components/AddButton/AddButton";
import ViewButton from "../../Components/ViewButton/ViewButton";
import StickyHeadTable from "../../Components/StickyHeadTable/StickyHeadTable";
import { Button } from "@material-tailwind/react";
import {
  new_Inventory_dropdown_options_url,
  create_Inventory_for_component,
  new_inventory_supplier_dropdown,
} from "../../../../API/api";
import customFetch from "../../../../API/CustomFetch";
import {
  Inventory_componet_dropdown_type,
  new_inventory_supplier_dropdown_data_type,
} from "../../../../models/API_data";
import { columns } from "../../../../data/new_Inventory_table_colums";
import showToastMessage from "../../../../Tostify/Index";
import { ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import CircularProgress from "@mui/material/CircularProgress";

type NewInventoryProps = {
  // : string;
};

const NewInventory: React.FC<NewInventoryProps> = ({}) => {
  const [flag, setflag] = useState(true);
  const [compo_input, setcompo_input] = useState<string | ReactNode>("");
  const [Supplier_input, setSupplier_input] = useState("");
  const [lot_qty, setlot_qty] = useState("");
  const [Po_Id_input, setPo_Id_input] = useState("");
  const [date_input, setdate_input] = useState<Dayjs | null>(null);
  const [recieved_date_input, setrecieved_date_input] = useState<Dayjs | null>(
    null
  );
  const [rejected_input, setrejected_input] = useState("");

  const [Inventory_compo_drop_data, setInventory_compo_drop_data] = useState<
    [] | Inventory_componet_dropdown_type[]
  >([]);

  const [
    new_inventory_supplier_dropdown_data,
    setnew_inventory_supplier_dropdown_data,
  ] = useState<[] | new_inventory_supplier_dropdown_data_type[]>([]);
  //declaration for redux usecase
  const {
    inventory: {
      data: { new_inventory_table_data },
      loader,
    },
  } = useSelector((state: RootState) => state.hrc);

  useEffect(() => {
    let result = customFetch(new_Inventory_dropdown_options_url);
    result.then((data) => setInventory_compo_drop_data([...data.DATA]));

    customFetch(new_inventory_supplier_dropdown).then((data) =>
      setnew_inventory_supplier_dropdown_data([...data.DATA])
    );
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let data_to_add = {
      Component_Id: Number(compo_input),
      Supplier: Number(Supplier_input),
      Recieved_Date: recieved_date_input,
      Po_Id: Po_Id_input,
      Procurement_Date: date_input,
      Lot_Quantity: Number(lot_qty),
      Rejected_Quantity: Number(rejected_input),
    };
    let body = JSON.stringify(data_to_add);
    let result = customFetch(create_Inventory_for_component, body, {
      method: "POST",
    });
    result.then((data) => showToastMessage(data.SUCCESS, data.MESSAGE));
    setcompo_input("");
    setSupplier_input("");
    setrecieved_date_input(null);
    setPo_Id_input("");
    setdate_input(null);
    setlot_qty("");
    setrejected_input("");
  };

  return (
    <div className="w-full flex items-center flex-col gap-10">
      <ToastContainer />

      {flag ? (
        <div className="fixed sm:right-10 right-0 top-[92%] sm:top-[90%] z-10">
          <AddButton setflag={setflag} />
        </div>
      ) : (
        <div className="fixed sm:right-10 right-0 top-[92%] sm:top-[90%] z-10">
          <ViewButton setflag={setflag} />
        </div>
      )}
      {flag ? (
        <div className="w-full h-full flex justify-center items-center">
          {loader ? (
            <CircularProgress color="success" size={100} />
          ) : new_inventory_table_data?.length === 0 ? (
            <div className="flex  gap-4 items-center">
              <h1 className="text-2xl text-red-900 tracking-widest">
                No any available data
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          ) : (
            <div className="md:w-11/12 w-[96%]">
              <StickyHeadTable
                ColumnsData={columns}
                data={new_inventory_table_data}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="md:w-11/12 w-full  flex bg-gray-50 items-center rounded">
          <form
            className="flex  w-full p-4 flex-col   gap-10 "
            onSubmit={handleSubmit}
          >
            <div className="flex gap-10 flex-wrap ">
              <div className="w-[250px] sm:max-w-[350px] max-w-full  flex-auto">
                <Box sx={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Component
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Component"
                      value={compo_input?.toString()}
                      onChange={(e) => setcompo_input(e.target.value)}
                    >
                      {Inventory_compo_drop_data.map((item) => (
                        <MenuItem
                          value={item.Component_Id.toString()}
                          key={item.Component_Id}
                        >
                          {item.Product_Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="w-[250px] sm:max-w-[350px] max-w-full  flex-auto">
                <Box sx={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Supplier
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Supplier"
                      value={Supplier_input}
                      onChange={(e) => setSupplier_input(e.target.value)}
                    >
                      {new_inventory_supplier_dropdown_data.map((item) => (
                        <MenuItem value={item.Seller_Id} key={item.Seller_Id}>
                          {item.Seller_Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div className="w-[250px] sm:max-w-[350px] max-w-full  flex-auto">
                <TextField
                  id="outlined-basic"
                  label="Lot Quantity"
                  variant="outlined"
                  value={lot_qty}
                  onChange={(e) => setlot_qty(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="w-[250px] sm:max-w-[350px] max-w-full  flex-auto">
                <TextField
                  id="outlined-basic"
                  label="P. O. ID"
                  variant="outlined"
                  value={Po_Id_input}
                  onChange={(e) => setPo_Id_input(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="w-[250px] sm:max-w-[350px] max-w-full  flex-auto">
                <TextField
                  id="outlined-basic"
                  label="Rejected Quantity"
                  variant="outlined"
                  value={rejected_input}
                  onChange={(e) => setrejected_input(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="w-[250px] sm:max-w-[350px] max-w-full  flex-auto">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Produrement Date"
                    value={date_input}
                    onChange={(newValue) => setdate_input(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="w-[250px] sm:max-w-[350px] max-w-full  flex-auto">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Recieved Date"
                    value={recieved_date_input}
                    onChange={(newValue) => setrecieved_date_input(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <Button type="submit" className="self-end">
              Submit
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default NewInventory;
