import { Navbar, IconButton } from "@material-tailwind/react";
import Images from "../../../../data/images";
import Avatar from "@mui/material/Avatar";
import { deepOrange, lightGreen } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
function TopNavTypeTwo({
  openTopNav,
  setTopNav,
  setdrop_show,
}: {
  openTopNav: boolean;
  setTopNav: Function;
  setdrop_show: Function;
}) {
  const [name, setname] = useState("");
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      const email = user.email;
      setname(email?.[0] ?? "");
    }
  }, []);
  const navigate = useNavigate();

  return (
    <Navbar className="mx-auto max-w-full py-2 px-4 lg:px-8 lg:py-4 ">
      <div className="container mx-auto max-w-full flex items-center justify-between text-blue-gray-900  ">
        <IconButton
          variant="text"
          className="h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setTopNav()}
        >
          {openTopNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
        <img
          className="w-18 h-8 ml-2 lg:ml-0 mr-2 cursor-pointer"
          src={Images.brand_logo}
          alt="logo"
          onClick={() => navigate("/home")}
        />

        <Avatar
          sx={{ bgcolor: deepOrange[500] }}
          onClick={(e) => {
            e.stopPropagation();
            setdrop_show(true);
          }}
          className="cursor-pointer"
        >
          {name.toUpperCase()}
        </Avatar>
      </div>
    </Navbar>
  );
}
export default React.memo(TopNavTypeTwo);
