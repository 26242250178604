import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
interface ProductListProps {
  title: string;
  path: string;
  children: JSX.Element;
}

export default function ListVeiw({ title, path, children }: ProductListProps) {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full p-3 mt-5 flex flex-col gap-5 ">
      <div className="w-full flex justify-between">
        <h1 className="text-3xl text-[#00aa13]">{title}</h1>
        {title !== "SOW" && (
          <Fab
            variant="extended"
            color="success"
            onClick={() => navigate(path)}
          >
            Add {title}
            <AddCircleIcon sx={{ ml: 1 }} />
          </Fab>
        )}
      </div>
      {/* opertation space -- filter ---shearch ---sort */}
      <div className="flex justify-between">
        <IconButton>
          <FilterListIcon color="success" sx={{ fontSize: "2rem" }} />
        </IconButton>
        <TextField
          id="Search"
          label="Search"
          value={""}
          // onChange={}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </div>

      {children}
    </div>
  );
}
