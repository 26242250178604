import TextField from "@mui/material/TextField";
import { FormikProps } from "formik";
import React, { useCallback, useEffect } from "react";
import { PoItem, PurchaseOrder } from "./fomik_essential";
import MenuItem from "@mui/material/MenuItem";
import { sowMenuType } from "../../../../../models/product_data_model";
import { FieldArray, getIn } from "formik";
import { resolve } from "path";
import {
  purchase_stage_menu_response,
  purchase_stage_menu_type,
} from "../../../../../models/API_data";
import customFetchWithToken from "../../../../../API/CustomFetchWtihToken";
import { all_purchase_status_drop_menu_api } from "../../../../../API/api";

interface IObjectKeys {
  [key: string]: string | number | null | object | boolean;
}
interface dataToCalculateTotalAmountType extends IObjectKeys {
  igst: number;
  cgst: number;
  sgst: number;
  amount: number;
  quantity: number;
  discount: number;
}

type Props = {
  prop: FormikProps<PurchaseOrder>;
  sowMenu: sowMenuType[];
  product_data: PoItem[];
};

const PoForm = ({ prop, sowMenu, product_data }: Props) => {
  const {
    values,
    touched,
    handleBlur,
    handleChange,
    setValues,
    setFieldValue,
    errors,
    initialValues,
  } = prop;
  const [purchaseStatus, setPurchaseStatus] = React.useState<
    purchase_stage_menu_type[]
  >([]);
  const [total_price_after_discount, settotal_price_after_discount] =
    React.useState(0);
  React.useEffect(() => {
    customFetchWithToken(all_purchase_status_drop_menu_api, "").then(
      (response: purchase_stage_menu_response) =>
        setPurchaseStatus(response.DATA)
    );
  }, []);
  React.useEffect(() => {
    if (product_data.length > 0) {
      setValues({ ...initialValues, po_items: product_data });
    } else {
      setValues(initialValues);
    }
  }, [product_data]);
  const handleTotalAmount = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number,
      input: string
    ) => {
      const { value, name } = e.target;
      const { igst, cgst, sgst, discount, amount, quantity } =
        values.po_items[index];
      const dataToCalculateTotalAmount: dataToCalculateTotalAmountType = {
        igst: igst,
        cgst: cgst,
        sgst: sgst,
        amount: amount,
        quantity: quantity,
        discount: discount,
      };
      dataToCalculateTotalAmount[input] = value;

      const totalPrice =
        dataToCalculateTotalAmount.amount * dataToCalculateTotalAmount.quantity;
      const totalPriceAfterDiscount =
        totalPrice - totalPrice * (dataToCalculateTotalAmount.discount / 100);

      const cgstValue =
        totalPriceAfterDiscount * (dataToCalculateTotalAmount.cgst / 100);
      const sgstValue =
        totalPriceAfterDiscount * (dataToCalculateTotalAmount.sgst / 100);
      const IgstValue =
        totalPriceAfterDiscount * (dataToCalculateTotalAmount.igst / 100);
      const totalAmount =
        totalPriceAfterDiscount + cgstValue + sgstValue + IgstValue;
      const grandTotal = values.po_items.reduce((acc, curr, i) => {
        if (i === index) {
          curr.total_amount = totalAmount;
        }
        return acc + curr.total_amount;
      }, 0);
      settotal_price_after_discount(totalPriceAfterDiscount);
      setFieldValue("grand_total", grandTotal);
      setFieldValue(`po_items[${index}].total_amount`, totalAmount);

      setFieldValue(name, Number(value));
    },
    [values.po_items]
  );
  const handleShippingAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    let updatedGrandTotal = Number(value) + values?.grand_total;
    setFieldValue(name, Number(value));
    setFieldValue("grand_total", updatedGrandTotal);
  };
  const handleTds = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    let updatedGrandTotal =
      total_price_after_discount * (Number(value) / 100) + values.grand_total;
    setFieldValue(name, Number(value));
    setFieldValue("grand_total", updatedGrandTotal);
  };

  return (
    <div className="flex flex-col gap-8 ">
      <div className="flex gap-8 flex-wrap">
        <TextField
          label="SOW ID"
          name="sow_id"
          color="success"
          select
          helperText={touched.sow_id && errors.sow_id}
          error={touched.sow_id && !!errors.sow_id}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.sow_id}
          sx={{ minWidth: 250 }}
        >
          {sowMenu.map((item) => (
            <MenuItem value={item.sow_id}>{item.sow_reference_no}</MenuItem>
          ))}
        </TextField>
        <TextField
          label="Shipping Amount"
          name="shipping_amount"
          helperText={touched.shipping_amount && errors.shipping_amount}
          error={touched.shipping_amount && !!errors.shipping_amount}
          onChange={handleShippingAmount}
          onBlur={handleBlur}
          value={values.shipping_amount}
        />
        <TextField
          label="TDS %"
          name="tds"
          helperText={touched.tds && errors.tds}
          error={touched.tds && !!errors.tds}
          onChange={handleTds}
          onBlur={handleBlur}
          value={values.tds}
        />
        <TextField
          label="Purchase Status ID"
          name="purchase_status_id"
          select
          helperText={touched.purchase_status_id && errors.purchase_status_id}
          error={touched.purchase_status_id && !!errors.purchase_status_id}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.purchase_status_id}
          sx={{ minWidth: 250 }}
        >
          {purchaseStatus.map((item) => (
            <MenuItem value={item.Stage_Master_Id} key={item.Stage_Master_Id}>
              {item.Stage_Name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Grand Total"
          name="grand_total"
          value={values.grand_total}
        />
      </div>

      <div>
        <FieldArray name="po_items">
          {(props) => {
            return (
              <div className="flex flex-col gap-8">
                {values.po_items.length > 0 &&
                  values.po_items.map((value, index) => (
                    <div key={index} className="flex flex-col gap-8">
                      <h1 className="text-3xl text-[#00aa13]">
                        Product Detail {index + 1}
                      </h1>
                      <div className="flex gap-4 flex-wrap">
                        <TextField
                          name={`po_items[${index}].product_id`}
                          label={`Product ID `}
                          onChange={handleChange}
                          value={value.product_id}
                          onBlur={handleBlur}
                          helperText={
                            getIn(touched, `po_items[${index}].product_id`) &&
                            getIn(errors, `po_items[${index}].product_id`)
                          }
                          error={
                            getIn(touched, `po_items[${index}].product_id`) &&
                            Boolean(
                              getIn(errors, `po_items[${index}].product_id`)
                            )
                          }
                        />
                        <TextField
                          label={`Amount`}
                          name={`po_items[${index}].amount`}
                          onChange={(e) =>
                            handleTotalAmount(e, index, "amount")
                          }
                          onBlur={handleBlur}
                          value={values.po_items[index].amount}
                          helperText={
                            getIn(touched, `po_items[${index}].amount`) &&
                            getIn(errors, `po_items[${index}].amount`)
                          }
                          error={
                            getIn(touched, `po_items[${index}].amount`) &&
                            Boolean(getIn(errors, `po_items[${index}].amount`))
                          }
                        />
                        <TextField
                          label={`Quantity`}
                          name={`po_items[${index}].quantity`}
                          onChange={(e) =>
                            handleTotalAmount(e, index, "quantity")
                          }
                          onBlur={handleBlur}
                          value={values.po_items[index].quantity}
                          helperText={
                            getIn(touched, `po_items[${index}].quantity`) &&
                            getIn(errors, `po_items[${index}].quantity`)
                          }
                          error={
                            getIn(touched, `po_items[${index}].quantity`) &&
                            Boolean(getIn(errors, `po_items[${index}].quntity`))
                          }
                        />
                        <TextField
                          label={`Discount %`}
                          name={`po_items[${index}].discount`}
                          onChange={(e) =>
                            handleTotalAmount(e, index, "discount")
                          }
                          onBlur={handleBlur}
                          value={values.po_items[index].discount}
                          helperText={
                            getIn(touched, `po_items[${index}].discount`) &&
                            getIn(errors, `po_items[${index}].discount`)
                          }
                          error={
                            getIn(touched, `po_items[${index}].discount`) &&
                            Boolean(
                              getIn(errors, `po_items[${index}].discount`)
                            )
                          }
                        />
                        <TextField
                          label={`CGST %`}
                          name={`po_items[${index}].cgst`}
                          value={values.po_items[index].cgst}
                          aria-readonly
                        />
                        <TextField
                          label={`SGST %`}
                          name={`po_items[${index}].sgst`}
                          value={values.po_items[index].sgst}
                          aria-readonly
                        />
                        <TextField
                          label={`IGST %`}
                          name={`po_items[${index}].igst`}
                          value={values.po_items[index].igst}
                          aria-readonly
                        />
                        <TextField
                          label={`Total Amount`}
                          name={`po_items[${index}].total_amount`}
                          value={values.po_items[index].total_amount}
                          aria-readonly
                        />
                      </div>
                    </div>
                  ))}
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

export default PoForm;
