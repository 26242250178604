import * as Yup from "yup";
interface IObjectKeys {
  [key: string]: string | number | null | object | boolean;
}
export interface sellerType extends IObjectKeys {
  name: string;
  contact: string;
  address: string;
  gst_no: string;
  seller_code: string;
  igst: number | null;
  cgst: number | null;
  sgst: number | null;
  pan: string;
  account_no: string;
  ifsc_code: string;
  bank_name: string;
  beneficiary_name: string;
  account_type: null | number;
  poc_name: string;
  poc_mobileno: string;
  poc_email: string;
  product_category: null | number;
  city: null | number;
  state: null | number;
  country: null | number;
  pincode: null | number;
  createdBy: number;
  updatedBy: number;
  swift_code: string;
  bank_address: string;
}
const sellerSchema = Yup.object().shape({
  name: Yup.string().required("Please enter seller name"),
  contact: Yup.string()
    .required("Please enter seller contact number")
    .matches(/^[0-9]{10}$/, "Please enter valid contact number"),
  address: Yup.string().required("Please enter Address"),
  gst_no: Yup.string()
    .required("Please enter GST number")
    .matches(
      /\b\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}\b/,
      "Please enter valid GST number"
    ),
  seller_code: Yup.string().required("Please enter seller code"),
  igst: Yup.number().required("Please enter IGST value"),
  cgst: Yup.number().required("Please enter CGST value"),
  sgst: Yup.number().required("Please enter SGST value"),
  pan: Yup.string()
    .required("Please enter PAN number")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid PAN number"),
  account_no: Yup.string()
    .required("Please enter account number")
    .matches(/^\d{1,17}$/, "Please enter valid account number"),
  ifsc_code: Yup.string()
    .required("Please enter IFSC code")
    .matches(/^[A-Z]{5}[0-9]{6}$/, "Please enter valid IFSC code"),
  bank_name: Yup.string().required("Please enter bank name"),
  beneficiary_name: Yup.string().required("Please enter beneficiary name"),
  account_type: Yup.string().required("Please select account type"),
  poc_name: Yup.number().required("Please enter POC name"),
  poc_mobileno: Yup.string()
    .required("Please enter POC mobile number")
    .matches(/^[0-9]{10}$/, "Please enter valid mobile number"),
  poc_email: Yup.string()
    .required("Please enter POC email")
    .email("Please enter valid email address"),
  product_category: Yup.number().required("Please enter product category"),
  city: Yup.number().required("Please enter city"),
  state: Yup.number().required("Please enter state"),
  country: Yup.number().required("Please enter country"),
  pincode: Yup.number().required("Please enter pincode"),
  createdBy: Yup.number(),
  updatedBy: Yup.number(),
  swift_code: Yup.string()
    .required("Please enter swift code")
    .matches(
      /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
      "Please enter valid swift code"
    ),
  bank_address: Yup.string().required("Please enter bank address"),
});

const initialSeller: sellerType = {
  name: "",
  contact: "",
  address: "",
  gst_no: "",
  seller_code: "",
  igst: null,
  cgst: null,
  sgst: null,
  pan: "",
  account_no: "",
  ifsc_code: "",
  bank_name: "",
  beneficiary_name: "",
  account_type: null,
  poc_name: "",
  poc_mobileno: "",
  poc_email: "",
  product_category: null,
  city: null,
  state: null,
  country: null,
  pincode: null,
  createdBy: 1,
  updatedBy: 1,
  swift_code: "",
  bank_address: "",
};
export { sellerSchema, initialSeller };
