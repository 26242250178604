import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { sellerType } from "../../vendor_form/vendor_schema";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  categoryModel,
  updateType,
} from "../../../../../../models/product_data_model";
import {
  baseUrl,
  get_all_country_api,
  hrc_category_menu_api,
} from "../../../../../../API/api";
import customFetchWithToken from "../../../../../../API/CustomFetchWtihToken";
import {
  country_drop_menu_type,
  lms_city_menu_type,
  lms_state_menu_type,
} from "../../../../../../models/API_data";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";

type Props = {
  prop: FormikProps<sellerType>;
  update: updateType<sellerType>;
};
interface optionsType {
  id: number;
  label: string;
}

const UpdateVendor = (props: Props) => {
  const { values, handleChange, touched, errors, setValues } = props.prop;
  const [country_drop_menu, setcountry_drop_menu] = useState<
    optionsType[] | []
  >([]);
  const [state_drop_menu, setstate_drop_menu] = useState<optionsType[]>([]);
  const [city_drop_menu, setcity_drop_menu] = useState<optionsType[]>([]);
  const [category_menu, setcategory_menu] = React.useState<[] | optionsType[]>(
    []
  );
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);
  const fields = [
    {
      key: "name",
      label: "Name",
      fieldType: "text",
      error: touched.name && Boolean(errors.name),
      helperText: touched.name && errors.name,
    },
    {
      key: "contact",
      label: "Contact",
      fieldType: "text",
      error: touched.contact && Boolean(errors.contact),
      helperText: touched.contact && errors.contact,
    },
    {
      key: "address",
      label: "Address",
      fieldType: "text",
      error: touched.address && Boolean(errors.address),
      helperText: touched.address && errors.address,
    },
    {
      key: "gst_no",
      label: "GST No",
      fieldType: "text",
      error: touched.gst_no && Boolean(errors.gst_no),
      helperText: touched.gst_no && errors.gst_no,
    },
    {
      key: "seller_code",
      label: "Seller Code",
      fieldType: "text",
      error: touched.seller_code && Boolean(errors.seller_code),
      helperText: touched.seller_code && errors.seller_code,
    },
    {
      key: "igst",
      label: "IGST",
      fieldType: "",
      error: touched.igst && Boolean(errors.igst),
      helperText: touched.igst && errors.igst,
    },
    {
      key: "cgst",
      label: "CGST",
      fieldType: "",
      error: touched.cgst && Boolean(errors.cgst),
      helperText: touched.cgst && errors.cgst,
    },
    {
      key: "sgst",
      label: "SGST",
      fieldType: "",
      error: touched.sgst && Boolean(errors.sgst),
      helperText: touched.sgst && errors.sgst,
    },
    {
      key: "pan",
      label: "PAN",
      fieldType: "text",
      error: touched.pan && Boolean(errors.pan),
      helperText: touched.pan && errors.pan,
    },
    {
      key: "bank_name",
      label: "Bank Name",
      fieldType: "text",
      error: touched.bank_name && Boolean(errors.bank_name),
      helperText: touched.bank_name && errors.bank_name,
    },
    {
      key: "account_no",
      label: "Account No",
      fieldType: "text",
      error: touched.account_no && Boolean(errors.account_no),
      helperText: touched.account_no && errors.account_no,
    },
    {
      key: "ifsc_code",
      label: "IFSC Code",
      fieldType: "text",
      error: touched.ifsc_code && Boolean(errors.ifsc_code),
      helperText: touched.ifsc_code && errors.ifsc_code,
    },
    {
      key: "beneficiary_name",
      label: "Beneficiary name",
      fieldType: "text",
      error: touched.beneficiary_name && Boolean(errors.beneficiary_name),
      helperText: touched.beneficiary_name && errors.beneficiary_name,
    },
    {
      key: "account_type",
      label: "Account type",
      fieldType: "text",
      error: touched.account_type && Boolean(errors.account_type),
      helperText: touched.account_type && errors.account_type,
    },
    {
      key: "bank_address",
      label: "Bank Address",
      fieldType: "text",
      error: touched.bank_address && Boolean(errors.bank_address),
      helperText: touched.bank_address && errors.bank_address,
    },
    {
      key: "poc_name",
      label: "Poc name",
      fieldType: "text",
      error: touched.poc_name && Boolean(errors.poc_name),
      helperText: touched.poc_name && errors.poc_name,
    },
    {
      key: "poc_email",
      label: "Poc email",
      fieldType: "text",
      error: touched.poc_email && Boolean(errors.poc_email),
      helperText: touched.poc_email && errors.poc_email,
    },
    {
      key: "poc_mobileno",
      label: "Poc mobile number",
      fieldType: "text",
      error: touched.poc_mobileno && Boolean(errors.poc_mobileno),
      helperText: touched.poc_mobileno && errors.poc_mobileno,
    },
    {
      key: "product_category",
      label: "Product category",
      options: category_menu,
      fieldType: "select",
      error: touched.product_category && Boolean(errors.product_category),
      helperText: touched.product_category && errors.product_category,
    },

    {
      key: "country",
      label: "Country",
      fieldType: "select",
      options: country_drop_menu,
      error: touched.country && Boolean(errors.country),
      helperText: touched.country && errors.country,
    },
    {
      key: "state",
      label: "State",
      fieldType: "select",
      options: state_drop_menu,
      error: touched.state && Boolean(errors.state),
      helperText: touched.state && errors.state,
    },
    {
      key: "city",
      label: "City",
      fieldType: "select",
      options: city_drop_menu,
      error: touched.city && Boolean(errors.city),
      helperText: touched.city && errors.city,
    },

    {
      key: "pincode",
      label: "Pincode",
      fieldType: "text",
      error: touched.pincode && Boolean(errors.pincode),
      helperText: touched.pincode && errors.pincode,
    },
    {
      key: "swift_code",
      label: "Swift code",
      fieldType: "text",
      error: touched.swift_code && Boolean(errors.swift_code),
      helperText: touched.swift_code && errors.swift_code,
    },
  ];
  useEffect(() => {
    if (props.update.data) {
      setValues(props.update.data);
    }
  }, [props.update]);
  useEffect(() => {
    if (token_id) {
      customFetchWithToken(get_all_country_api, token_id).then((response) => {
        let country_options = response.DATA.map(
          (item: country_drop_menu_type) => {
            return {
              label: item.Country_Name,
              id: item.Country_Id,
            };
          }
        );
        setcountry_drop_menu(country_options);
      });
      customFetchWithToken(hrc_category_menu_api, token_id).then((response) => {
        let category_options = response.DATA.map((item: categoryModel) => {
          return {
            label: item.TypeName,
            id: item.TypeMasterId,
          };
        });
        setcategory_menu(category_options);
      });
    }
  }, [token_id]);
  useEffect(() => {
    if (values.country) {
      let state_url = `${baseUrl}/state/v1/get/${values.country}/state`;
      customFetchWithToken(state_url, "").then((response) => {
        let state_options = response.DATA.map((item: lms_state_menu_type) => {
          return {
            label: item.State,
            id: item.Id,
          };
        });
        setstate_drop_menu(state_options);
      });
    }
    if (values.state) {
      let city_url = `${baseUrl}/state/v1/get/${values.state}/city`;
      customFetchWithToken(city_url, "").then((response) => {
        let city_options = response.DATA.map((item: lms_city_menu_type) => {
          return {
            label: item.City,
            id: item.Id,
          };
        });
        setcity_drop_menu(city_options);
      });
    }
  }, [values.state, values.country]);

  console.log(errors);
  return (
    <div className="flex flex-col gap-5">
      {fields.map((field) => (
        <div key={field.key}>
          {field.fieldType === "select" ? (
            <TextField
              id={field.key}
              name={field.key}
              value={values[field.key] || ""}
              label={field.label}
              select
              onChange={handleChange}
              fullWidth
              error={field.error}
              helperText={field.helperText}
            >
              {field.options?.map((option: optionsType) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              id={field.key}
              name={field.key}
              key={field.key}
              label={field.label}
              type={field.fieldType}
              fullWidth
              value={values[field.key] || ""}
              onChange={handleChange}
              error={field.error}
              helperText={field.helperText}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default UpdateVendor;
