/* eslint-disable no-dupe-keys */
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { lmsData } from "../../../../models/lms_data_api";
import { ThemeProvider } from "@mui/material/styles";
import { custom_theme } from "../../../../custom_them/custom_them";

interface pagination_props {
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  row: number;
}

export default function PaginationControlled({
  handleChange,
  page,
  row,
}: pagination_props) {
  const location = useLocation();
  const {
    appData: { dataAccToPage, length },
  } = useSelector((state: RootState) => state.lms);

  return (
    <Stack spacing={2}>
      <ThemeProvider theme={custom_theme}>
        <Pagination
          count={length ?? 0}
          page={page}
          onChange={handleChange}
          color={"primary"}
          size="large"
          shape="rounded"
        />
      </ThemeProvider>
    </Stack>
  );
}
