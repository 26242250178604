import { useState } from "react";

function useInput(initialValue: string) {
  const [value, setValue] = useState(initialValue);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }
  ) {
    setValue(e.target.value);
  }

  return {
    value,
    onChange: handleChange,
  };
}
export default useInput;
