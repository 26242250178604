import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

type NotAccessPageProps = {
  // : string;
};

const NotAccessPage: React.FC<NotAccessPageProps> = ({}) => {
  const [, set] = useState<string>();
  const navigate = useNavigate();
  const handelLogOut = () => {
    const auth = getAuth();
    auth.signOut();
    navigate("/");
    window.location.reload();
  };
  useEffect(() => {
    // set();
  }, []);

  return (
    <div className="h-screen w-screen flex justify-center items-center ">
      <div className="flex flex-col items-center gap-4 w-[75%] ">
        <h1 className="text-4xl text-red-800"> Access Denied </h1>
        <Button variant="contained" onClick={handelLogOut}>
          Log Out
        </Button>
      </div>
    </div>
  );
};

export default NotAccessPage;
