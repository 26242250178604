import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
  height: "50vh",
  overflow: "auto",
  borderRadius: "0.6rem",
};
interface admin_modal_props {
  user_status: null | number;
  children: React.ReactNode;
  modal_cls_by_parent: boolean;
}
export default function AdminModal({
  user_status,
  children,
  modal_cls_by_parent,
}: admin_modal_props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    handleClose();
  }, [modal_cls_by_parent]);

  return (
    <div>
      {user_status === null ? (
        <Button sx={{ color: "darkblue" }} onClick={handleOpen}>
          Update Role
        </Button>
      ) : user_status === 0 ? (
        <Button sx={{ color: "green" }} onClick={handleOpen}>
          Activate
        </Button>
      ) : user_status === 1 ? (
        <Button sx={{ color: "red" }} onClick={handleOpen}>
          DeActivate
        </Button>
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-scroll">
          <div className="flex ">
            {children}
            <div className="justify-end cursor-pointer" onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
