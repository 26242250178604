import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TransVehicleIn from "./trans_vehicl_in";
import TransVehicleOut from "./trans_vehicle_out";
import { ThemeProvider } from "@mui/material/styles";
import { custom_theme } from "../../../../custom_them/custom_them";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import {
  transit_vehicle_api,
  trans_vehicle_out_lists_api,
} from "../../../../API/api";
import { settransit_data } from "../../../../features/hrc_actions/hrc_slice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-[20%] flex-auto"
    >
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  let urls = [transit_vehicle_api, trans_vehicle_out_lists_api];
  const dispatch = useDispatch();
  const { token_id } = useSelector((state: RootState) => state.user);
  React.useEffect(() => {
    if (token_id) {
      Promise.all(urls.map((url) => customFetchWithToken(url, token_id))).then(
        (response) => {
          const [res1, res2] = response;
          let data = {
            vehicle_in_data: res1.DATA,
            vehicle_out_data: res2.DATA,
          };
          dispatch(settransit_data(data));
        }
      );
    }
  }, [token_id]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginTop: "1rem",
        }}
      >
        <ThemeProvider theme={custom_theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Vehicle In" {...a11yProps(0)} />
            <Tab label="Vehicle Out" {...a11yProps(1)} />
          </Tabs>
        </ThemeProvider>
      </Box>
      <TabPanel value={value} index={0}>
        <TransVehicleIn />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TransVehicleOut />
      </TabPanel>
    </Box>
  );
}
