import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Sidemenu } from "../../../../models/lms_sidemenu";
import { useLocation } from "react-router-dom";
interface bottom_nav_props {
  menu: Sidemenu[];
}

export default function FixedBottomNavigation({ menu }: bottom_nav_props) {
  const ref = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: "1200",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          sx={{
            overflow: "auto",
            "@media (max-width:576px)": {
              justifyContent: "start",
            },
          }}
        >
          {menu.map((item) => {
            return (
              <BottomNavigationAction
                label={item.label}
                icon={item.icons}
                key={item.label}
                onClick={() => navigate(item.path)}
                sx={{
                  minWidth: "6.5rem",
                  color: path === item.path ? "#00aa13" : "gray",
                  background: path === item.path ? "#e7f4ea" : "none",
                }}
              />
            );
          })}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
