import { lmsData } from "../models/lms_data_api";
import { setHistoryData, setappData } from "../features/lms_actions/lmsSlice";

function getModifiedData(data: { DATA?: lmsData[] } | undefined): lmsData[] {
  const modifiedData = (data?.DATA ?? []).map((item) => {
    item.NextFollowUp = new Date(item?.NextFollowUp ?? "").toLocaleString(
      "default",
      {
        year: "numeric",
        month: "short",
        day: "numeric",
      }
    );
    item.UpdatedOn = new Date(item?.UpdatedOn ?? "").toLocaleString("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    item.CreatedOn = new Date(item?.CreatedOn ?? "").toLocaleString("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return item;
  });
  return modifiedData;
}

const handlePaginationData = (
  data: lmsData[],
  page: number,
  row: number,
  messsage: string,
  storeOld: boolean,
  dispatch: Function
) => {
  const startIndex = (page - 1) * row;
  const endIndex = Math.min(startIndex + row, data.length);
  let totalPage = Math.ceil(data?.length / row);
  if (storeOld) {
    dispatch(setHistoryData(data));
    dispatch(
      setappData({
        data: data.slice(startIndex, endIndex),
        message: messsage,
        length: totalPage,
        filterSearch: null,
      })
    );
  } else {
    dispatch(
      setappData({
        data: data.slice(startIndex, endIndex),
        message: messsage,
        length: totalPage,
        filterSearch: data,
      })
    );
  }
};

export { getModifiedData, handlePaginationData };
