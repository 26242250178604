import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ProductModel,
  shipingMethodModel,
  sowModel,
  vendorModel,
} from "../../models/product_data_model";
import produce from "immer";

import {
  job_Card_table_data_type,
  Inventory_table_data_type,
  vechileoptionType,
  new_Inventory_table_data_type,
  Inventory_componet_dropdown_type,
  purchase_data_type,
  trans_vehicle_data_type,
  Rider_data_type,
  hrc_gt_product_type,
  hrc_gt_product_model_type,
  hrc_gt_product_component_type,
} from "../../models/API_data";
import { productType } from "../../Modules/Hrc/Components/Forms/product_form/product_schema";
import { SowInitialValuesType } from "../../Modules/Hrc/Components/Forms/sop_form/sop_schema";
import { sellerType } from "../../Modules/Hrc/Components/Forms/vendor_form/vendor_schema";
// import { activate_user } from "../admin_actions/admin_slice";
// magic word
const shipping_method = "SHIPPING_METHOD";
const payment_method = "PAYMENT_METHOD";
const payment_terms = "PAYMENT_TERMS";
interface job_card_data_type {
  job_card_table_data: null | job_Card_table_data_type[];
  assign_inventory_table_data: null | Inventory_table_data_type[];
}
interface inventory_data_type {
  product_table_data: null | Inventory_componet_dropdown_type[];
  new_inventory_table_data: null | new_Inventory_table_data_type[];
}
interface trans_data_type {
  vehicle_in_data: null | trans_vehicle_data_type[];
  vehicle_out_data: null | trans_vehicle_data_type[];
}
interface view_data_type {
  model_data: null | hrc_gt_product_model_type;
  component_data: null | hrc_gt_product_component_type;
}
export interface initial_state_type {
  vehicle_in: {
    data: {
      vehicle_in_table_data: null | vechileoptionType[];
    };
    loader: boolean;
  };
  job_card: {
    data: job_card_data_type;
    loader: boolean;
  };
  purchase_order: {
    data: {
      purchase_order_data: null | purchase_data_type[];
    };
    loader: boolean;
  };
  inventory: {
    data: inventory_data_type;
    loader: boolean;
  };
  transit: {
    data: trans_data_type;
    loader: boolean;
  };
  rider: {
    data: {
      rider_data: null | Rider_data_type[];
    };
    loader: boolean;
  };
  product: {
    data: {
      hrc_data: null | hrc_gt_product_type[];
    };
    loader: boolean;
  };
  view_data: {
    data: view_data_type;

    loader: boolean;
  };
  new_product_data: {
    product_list: {
      loader: boolean;
      data: ProductModel[] | [];
    };
    sow_list: {
      loader: boolean;
      data: sowModel[] | [];
    };
    vendor_list: {
      loader: boolean;
      data: vendorModel[] | [];
    };
    shipping_method_dropmenu: {
      data: {
        all_data: shipingMethodModel[] | [];
        shipping_method: shipingMethodModel[] | [];
        payment_method: shipingMethodModel[] | [];
        payment_terms: shipingMethodModel[] | [];
      };
    };
  };
  form_data: {
    data: {
      product: productType | null;
      venodr: sellerType | null;
      sow: SowInitialValuesType | null;
    };
  };
}

const initialState: initial_state_type = {
  vehicle_in: {
    data: {
      vehicle_in_table_data: null,
    },
    loader: true,
  },

  job_card: {
    data: {
      job_card_table_data: null,
      assign_inventory_table_data: null,
    },
    loader: true,
  },
  purchase_order: {
    data: {
      purchase_order_data: null,
    },
    loader: true,
  },
  inventory: {
    data: {
      product_table_data: null,
      new_inventory_table_data: null,
    },
    loader: true,
  },
  transit: {
    data: {
      vehicle_in_data: null,
      vehicle_out_data: null,
    },
    loader: true,
  },
  rider: {
    data: {
      rider_data: null,
    },
    loader: true,
  },
  product: {
    data: {
      hrc_data: null,
    },
    loader: true,
  },
  view_data: {
    data: {
      model_data: null,
      component_data: null,
    },
    loader: true,
  },
  new_product_data: {
    product_list: {
      data: [],
      loader: true,
    },
    sow_list: {
      data: [],
      loader: true,
    },
    vendor_list: {
      data: [],
      loader: true,
    },
    shipping_method_dropmenu: {
      data: {
        all_data: [],
        shipping_method: [],
        payment_method: [],
        payment_terms: [],
      },
    },
  },
  form_data: {
    data: {
      product: null,
      venodr: null,
      sow: null,
    },
  },
};

export const hrcSlice = createSlice({
  name: "hrc",
  initialState,
  reducers: {
    set_vehicle_in_table_data: (
      state,
      action: PayloadAction<vechileoptionType[]>
    ) => {
      state.vehicle_in.data.vehicle_in_table_data = action.payload;
      state.vehicle_in.loader = false;
    },
    setjob_card_data: (state, action: PayloadAction<job_card_data_type>) => {
      return {
        ...state,
        job_card: {
          ...state.job_card,
          data: { ...action.payload },
          loader: false,
        },
      };
    },
    setinventory_data: (state, action: PayloadAction<inventory_data_type>) => {
      return {
        ...state,
        inventory: {
          ...state.job_card,
          data: { ...action.payload },
          loader: false,
        },
      };
    },

    setpurchase_order_data: (
      state,
      action: PayloadAction<purchase_data_type[]>
    ) => {
      state.purchase_order.data.purchase_order_data = action.payload;
      state.purchase_order.loader = false;
    },
    settransit_data: (state, action: PayloadAction<trans_data_type>) => {
      return {
        ...state,
        transit: {
          ...state.transit,
          data: { ...action.payload },
          loader: false,
        },
      };
    },
    setrider_data: (state, action: PayloadAction<Rider_data_type[]>) => {
      state.rider.data.rider_data = action.payload;
      state.rider.loader = false;
    },
    setproduct_data: (state, action: PayloadAction<hrc_gt_product_type[]>) => {
      return {
        ...state,
        product: {
          ...state.product,
          data: { hrc_data: [...action.payload] },
          loader: false,
        },
      };
    },
    deleteproduct_data: (state, action: PayloadAction<number>) => {
      const productIdToDelete = action.payload;
      const hrcData = state.product.data.hrc_data;

      if (hrcData && hrcData.length > 0) {
        const updatedHrcData = hrcData.filter(
          (item) => item.GT_Product_Id !== productIdToDelete
        );
        state.product.data.hrc_data = updatedHrcData;
      }
    },
    setview_data: (state, action: PayloadAction<view_data_type>) => {
      return {
        ...state,
        view_data: {
          ...state.view_data,
          data: { ...action.payload },
          loader: false,
        },
      };
    },
    set_product_list_data: (state, action: PayloadAction<ProductModel[]>) => {
      return produce(state, (draft) => {
        state.new_product_data.product_list.data = action.payload;
        state.new_product_data.product_list.loader = false;
      });
    },
    setsow_list_data: (state, action: PayloadAction<sowModel[]>) => {
      return produce(state, (draft) => {
        state.new_product_data.sow_list.data = action.payload;
        state.new_product_data.sow_list.loader = false;
      });
    },
    setvendor_list_data: (state, action: PayloadAction<vendorModel[]>) => {
      return produce(state, (draft) => {
        state.new_product_data.vendor_list.data = action.payload;
        state.new_product_data.vendor_list.loader = false;
      });
    },
    setshipping_method_menu: (
      state,
      action: PayloadAction<shipingMethodModel[]>
    ) => {
      return produce(state, (draft) => {
        draft.new_product_data.shipping_method_dropmenu.data = {
          all_data: action.payload,
          payment_method: action.payload.filter(
            (item) => item.CategoryType === payment_method
          ),
          payment_terms: action.payload.filter(
            (item) => item.CategoryType === payment_terms
          ),
          shipping_method: action.payload.filter(
            (item) => item.CategoryType === shipping_method
          ),
        };
      });
    },
    setproduct_form_data: (state, action: PayloadAction<productType>) => {
      return produce(state, (draft) => {
        draft.form_data.data.product = action.payload;
      });
    },
    setseller_form_data: (state, action: PayloadAction<sellerType>) => {
      return produce(state, (draft) => {
        draft.form_data.data.venodr = action.payload;
      });
    },
    setsow_form_data: (state, action: PayloadAction<SowInitialValuesType>) => {
      return produce(state, (draft) => {
        draft.form_data.data.sow = action.payload;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_vehicle_in_table_data,
  setjob_card_data,
  setinventory_data,
  setpurchase_order_data,
  settransit_data,
  setrider_data,
  setproduct_data,
  deleteproduct_data,
  setview_data,
  set_product_list_data,
  setsow_list_data,
  setvendor_list_data,
  setshipping_method_menu,
  setproduct_form_data,
  setseller_form_data,
  setsow_form_data,
} = hrcSlice.actions;

export default hrcSlice.reducer;
