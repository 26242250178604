import React, { useState } from "react";
import AddButton from "../../Components/AddButton/AddButton";
import ViewButton from "../../Components/ViewButton/ViewButton";
import StickyHeadTable from "../../Components/StickyHeadTable/StickyHeadTable";
import { Button } from "@material-tailwind/react";
import customFetch from "../../../../API/CustomFetch";
import { Inventory_product_add_url } from "../../../../API/api";
import { columns } from "../../../../data/Inventory_product_table";
import showToastMessage from "../../../../Tostify/Index";
import { ToastContainer } from "react-toastify";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import CircularProgress from "@mui/material/CircularProgress";

type ProductProps = {
  // : string;
};

const Component: React.FC<ProductProps> = ({}) => {
  const [flag, setflag] = useState(true);
  const [product_name, setproduct_name] = useState<string>("");
  const [product_code, setproduct_code] = useState<string>("");
  const [has_serial, sethas_serial] = useState(false);

  //declaration for redux usecase
  const {
    inventory: {
      data: { product_table_data },
      loader,
    },
  } = useSelector((state: RootState) => state.hrc);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let data_to_add = {
      Product_Name: product_name,
      Product_Code: product_code,
      hasSerial: has_serial ? 1 : 0,
    };

    let body = JSON.stringify(data_to_add);
    let result = customFetch(Inventory_product_add_url, body, {
      method: "POST",
    });
    result.then((data) => showToastMessage(data.SUCCESS, data.MESSAGE));
    setproduct_name("");
    setproduct_code("");
  };

  return (
    <div className="w-full flex items-center flex-col gap-10">
      <ToastContainer />

      {flag ? (
        <div className="fixed right-10 top-[90%] z-10">
          <AddButton setflag={setflag} />
        </div>
      ) : (
        <div className="fixed right-10 top-[90%] z-10">
          <ViewButton setflag={setflag} />
        </div>
      )}
      {flag ? (
        <div className="w-full h-full flex justify-center items-center">
          {loader ? (
            <CircularProgress color="success" size={100} />
          ) : product_table_data?.length === 0 ? (
            <div className="flex  gap-4 items-center">
              <h1 className="text-2xl text-red-900 tracking-widest">
                No any available data
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          ) : (
            <div className="md:w-11/12 w-[96%]">
              <StickyHeadTable
                ColumnsData={columns}
                data={product_table_data}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="w-11/12  flex bg-gray-50 p-8 items-center rounded">
          <form
            className="flex w-full flex-col gap-12  flex-wrap pr-4"
            onSubmit={handleSubmit}
          >
            <div className="w-full flex justify-evenly flex-wrap gap-6">
              <div className="min-w-[250px] max-w-[350]">
                <TextField
                  id="outlined-basic"
                  label="Product Name"
                  variant="outlined"
                  value={product_name}
                  onChange={(e) => setproduct_name(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="min-w-[250px] max-w-[350]">
                <TextField
                  id="outlined-basic"
                  label="Product Code"
                  variant="outlined"
                  value={product_code}
                  onChange={(e) => setproduct_code(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="flex gap-2 items-center w-48">
                <input
                  type="checkbox"
                  id="has-serial"
                  className="w-16 h-8"
                  checked={has_serial}
                  onChange={(e) => sethas_serial(e.target.checked)}
                />
                <label
                  htmlFor="has-serial"
                  className=" font-normal text-gray-700 text-xl "
                >
                  has serial
                </label>
              </div>
            </div>

            <Button type="submit" className="self-end">
              Submit
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Component;
