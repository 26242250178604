import React, { ReactNode } from "react";
import { Button } from "@material-tailwind/react";

import ViewButton from "../../Components/ViewButton/ViewButton";
import StickyHeadTable from "../../Components/StickyHeadTable/StickyHeadTable";
import { useState, useEffect } from "react";
import customFetch from "../../../../API/CustomFetch";
import showToastMessage from "../../../../Tostify/Index";
import { job_card_table_column_data } from "../../../../data/Job_Card_table_data";
import { ToastContainer } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import AddButton from "../../Components/AddButton/AddButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import CircularProgress from "@mui/material/CircularProgress";

import {
  vechileoptionType,
  bay_options_type,
  tech_options_type,
} from "../../../../models/API_data";
import {
  job_Card_dropdown_bay_url,
  job_Card_dropdown_techinician_url,
  job_Card_dropdown_vehicleReg_url,
  job_Card_Post_url,
} from "../../../../API/api";

export default function JobCardAdd() {
  const [flag, setflag] = useState(true);
  let [Vehicle_Profile_Id, setVehicle_Profile_Id] = useState<
    string | ReactNode
  >("");
  const [product_name, setproduct_name] = useState("");
  const [bay, setbay] = useState<string | ReactNode>("");
  const [technician, settechnician] = useState<string | ReactNode>("");
  const [vehicle_reg_options, setvehicle_reg_options] = useState<
    [] | vechileoptionType[]
  >([]);
  const [bay_options, setbay_options] = useState<[] | bay_options_type[]>([]);
  const [technician_options, settechnician_options] = useState<
    [] | tech_options_type[]
  >([]);

  //declaration for redux usecase;
  const {
    job_card: {
      data: { job_card_table_data },
      loader,
    },
  } = useSelector((state: RootState) => state.hrc);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let resposeData = {
      GT_ProductId: product_name,
      bayId: bay,
      technicianId: technician,
      VehicleProfileId: Vehicle_Profile_Id,
    };
    let body = JSON.stringify(resposeData);
    let result = customFetch(job_Card_Post_url, body, { method: "POST" });
    result.then((data) => showToastMessage(data.SUCCESS, data.MESSAGE));

    setproduct_name("");
    setVehicle_Profile_Id("");
    setbay("");
    settechnician("");
  };
  const handleRegNo = () => {
    let reg_data = customFetch(job_Card_dropdown_vehicleReg_url);
    reg_data.then((data) => setvehicle_reg_options([...data.DATA]));
  };
  const handleBay = () => {
    if (Vehicle_Profile_Id) {
      let select_data = vehicle_reg_options?.filter((item) => {
        if (item?.Vehicle_Profile_Id === Number(Vehicle_Profile_Id))
          return item;
      });

      let hrc_id = select_data[0]?.hrc_id;
      let bay_api_url = `${job_Card_dropdown_bay_url}${hrc_id}`;
      customFetch(bay_api_url).then((data) => {
        setbay_options([...data.DATA]);
      });
    }
  };
  const handleTech = () => {
    if (Vehicle_Profile_Id) {
      let select_data = vehicle_reg_options?.filter((item) => {
        if (item?.Vehicle_Profile_Id === Number(Vehicle_Profile_Id))
          return item;
      });

      let hrc_id = select_data[0]?.hrc_id;
      let techinicial_api_url = `${job_Card_dropdown_techinician_url}${hrc_id}`;
      customFetch(techinicial_api_url).then((data) => {
        settechnician_options([...data.DATA]);
      });
    }
  };

  useEffect(() => {
    vehicle_reg_options.map((item) => {
      if (item.Vehicle_Profile_Id === Number(Vehicle_Profile_Id)) {
        setproduct_name(item.Product_Name);
      }
    });
  }, [Vehicle_Profile_Id]);

  return (
    <div className="w-full flex items-center flex-col gap-10">
      <ToastContainer />

      {flag ? (
        <div className="fixed right-10 top-[90%] z-10">
          <AddButton setflag={setflag} />
        </div>
      ) : (
        <div className="fixed right-10 top-[90%] z-10">
          <ViewButton setflag={setflag} />
        </div>
      )}
      {flag ? (
        <div className="w-full h-full flex justify-center items-center">
          {loader ? (
            <CircularProgress color="success" size={100} />
          ) : job_card_table_data?.length === 0 ? (
            <div className="flex flex-col gap-8 items-center">
              <h1 className="text-2xl text-red-900 tracking-widest">
                No any available data
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          ) : (
            <div className="md:w-11/12 w-[96%]">
              <StickyHeadTable
                ColumnsData={job_card_table_column_data}
                data={job_card_table_data}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="w-11/12  py-12 flex flex-col bg-gray-50 gap-8 items-center  rounded">
          <form
            className="flex w-full gap-6 pr-4 flex-col"
            onSubmit={handleSubmit}
          >
            <div className="flex w-full justify-evenly flex-wrap gap-6 ">
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Vehicle Reg-No
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Vehicle_Profile_Id?.toString()}
                    label="Vehicle Reg-No"
                    onChange={(e) => setVehicle_Profile_Id(e.target.value)}
                    onFocus={handleRegNo}
                  >
                    {vehicle_reg_options.map((item) => (
                      <MenuItem
                        value={item.Vehicle_Profile_Id.toString()}
                        key={item.id}
                      >
                        {item.Registration_No}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    GT Product Id
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={product_name}
                    label="GT Product Id"
                  >
                    <MenuItem value={product_name}>{product_name}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Bay</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bay?.toString()}
                    label="Bay"
                    onChange={(e) => setbay(e.target.value)}
                    onFocus={handleBay}
                  >
                    {bay_options.map((item) => (
                      <MenuItem
                        value={item.Bay_Id.toString()}
                        key={item.Bay_Id}
                      >
                        {item.Bay_No}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Technician
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={technician?.toString()}
                    label="Technician"
                    onChange={(e) => settechnician(e.target.value)}
                    onFocus={handleTech}
                  >
                    {technician_options.map((item) => (
                      <MenuItem
                        value={item.technician_Id.toString()}
                        key={item.technician_Id}
                      >
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <Button type="submit" className="self-end ">
              Submit
            </Button>
          </form>
          {vehicle_reg_options.map((item) => {
            if (item.Vehicle_Profile_Id === Number(Vehicle_Profile_Id)) {
              // console.log(item);
              return (
                <div
                  className="flex  w-11/12 gap-[26.5rem] "
                  key={item.Vehicle_Profile_Id}
                >
                  <div className="flex flex-col gap-6   ">
                    <div>
                      <span className="font-bold">Rider Name: &nbsp;</span>
                      <span>{item.RiderName}</span>
                    </div>
                    <div>
                      <span className="font-bold">Mobile Number: &nbsp;</span>
                      <span>{item.MobileNumber}</span>
                    </div>
                    <div>
                      <span className="font-bold">
                        Alternate Mobile Number: &nbsp;
                      </span>
                      <span>{item.AlternateMobileNumber}</span>
                    </div>
                    <div>
                      <span className="font-bold">Email: &nbsp;</span>
                      <span>{item.Email}</span>
                    </div>
                    <div>
                      <span className="font-bold">Address: &nbsp;</span>
                      <span>{item.Address}</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6">
                    <div>
                      <span className="font-bold">Make: &nbsp;</span>
                      <span>{item.Brand_Name}</span>
                    </div>
                    <div>
                      <span className="font-bold">Model: &nbsp;</span>
                      <span>{item.Model_Name}</span>
                    </div>
                    <div>
                      <span className="font-bold">Chasis: &nbsp;</span>
                      <span>{item.Chasis_No}</span>
                    </div>
                    <div>
                      <span className="font-bold">Rear Wheel Type: &nbsp;</span>
                      <span>{item.Wheel_type}</span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
