/* eslint-disable jsx-a11y/img-redundant-alt */
import Images from "../../data/images";
import LogHome from "./log_home";
import ForgotPassword from "./forgot_pass";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

type loginPorp = {
  handleLogin: (
    e: React.FormEvent<HTMLFormElement>,
    email: string,
    setemail: Function,
    password: string,
    setpassword: Function
  ) => void;
};

export default function Login({ handleLogin }: loginPorp) {
  const [forgot_page, setforgot_page] = useState(false);
  // getting user from redux stores
  const { token_id } = useSelector((state: RootState) => state.user);
  if (token_id)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <img
          src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/loading.gif"
          alt="loader image"
          style={{
            width: "50%",
            height: "50%",
            objectFit: "cover",
            minWidth: "350px",
            minHeight: "350px",
          }}
        />
      </div>
    );
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <a
          href="/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="w-18 h-8 mr-2" src={Images.brand_logo} alt="logo" />
          {/* Flowbite */}
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          {/* <ForgotPassword setforgot_page={setforgot_page} /> */}
          <LogHome handleLogin={handleLogin} setforgot_page={setforgot_page} />
        </div>
      </div>
    </section>
  );
}
