import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  vechileoptionType,
  job_Card_table_data_type,
  Inventory_table_data_type,
  Inventory_componet_dropdown_type,
  new_Inventory_table_data_type,
} from "../../../../models/API_data";
import { Button } from "@material-tailwind/react";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "center";
  obj_id?: string;
  format?: (value: number) => string;
}
interface objType {
  [key: string]: string | number | null | object;
}

export default function StickyHeadTable({
  data,
  ColumnsData,
  button,
}: {
  data?:
    | vechileoptionType[]
    | job_Card_table_data_type[]
    | Inventory_table_data_type[]
    | Inventory_componet_dropdown_type[]
    | new_Inventory_table_data_type[]
    | null;
  ColumnsData?: Column[];
  button?: boolean;
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {ColumnsData?.map((column, index) => (
                <TableCell
                  key={`${column.id}-${index}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`key-${index}`}
                  >
                    {ColumnsData?.map((column, index) => {
                      let value = item[column.id];
                      if (typeof value === "object") {
                        let data: objType = { ...value };
                        value = data[column.obj_id ?? 0];
                      }
                      return (
                        <TableCell
                          align={column.align}
                          key={`key-${index}`}
                          style={{ minWidth: column.minWidth }}
                        >
                          {value?.toString()}
                        </TableCell>
                      );
                    })}
                    {button && (
                      <TableCell>
                        <Button>View more</Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
