import * as React from "react";
import ListVeiw from "../../Components/list_view/list_view";
import { set_product_list_data } from "../../../../features/hrc_actions/hrc_slice";
import { useDispatch, useSelector } from "react-redux";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import {
  hrc_product_list_api,
  hrc_update_product_api,
} from "../../../../API/api";
import { RootState } from "../../../../app/store";
import ProductAccordion from "../../Components/accord_prod/accord_prod";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { updateType } from "../../../../models/product_data_model";
import IconButton from "@mui/material/IconButton";
import UpdateForm from "../../Components/Forms/update_form/product_update/update_product";
import {
  productUpdateSchema,
  productUpdateType,
  productUpdateinitialValues,
} from "../../Components/Forms/update_form/product_update/formik_essentials";
import Button from "@mui/material/Button";
import { json } from "stream/consumers";
import showToastMessage from "../../../../Tostify/Index";
export interface ProductListProps {}

export default function ProductList(props: ProductListProps) {
  const [update, setupdate] = React.useState<updateType<productUpdateType>>({
    flag: false,
    data: null,
    id: 0,
  });
  const {
    hrc: {
      new_product_data: {
        product_list: { data, loader },
      },
    },
    user: { token_id },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (token_id)
      customFetchWithToken(hrc_product_list_api, token_id).then((data) =>
        dispatch(set_product_list_data([...data.DATA]))
      );
  }, [token_id]);
  return (
    <div className="flex w-full h-full gap-3">
      <div className="flex-auto w-full h-full ">
        <ListVeiw title="Product" path="/home/product/add">
          <div className="flex flex-col gap-4 flex-auto h-96 overflow-auto custom-scroll">
            {data.map((item) => (
              <ProductAccordion product={item} setupdate={setupdate} />
            ))}
          </div>
        </ListVeiw>
      </div>
      <div
        className={`flex-auto h-full overflow-auto bg-white ${
          update.flag
            ? " w-[550px] visible animate__animated animate__fadeIn"
            : "w-[0] hidden animate__fadeOutRight"
        } `}
      >
        <Formik
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            if (token_id) {
              let update_api = `${hrc_update_product_api}${update.id}`;
              let body = JSON.stringify(values);
              customFetchWithToken(
                update_api,
                token_id,
                { method: "PATCH" },
                body
              ).then(() => {
                showToastMessage(true, "Success");
                resetForm();
                setupdate({
                  ...update,
                  flag: false,
                });
                setSubmitting(false);
              });
            }
          }}
          initialValues={productUpdateinitialValues}
          validationSchema={productUpdateSchema}
        >
          {(props) => {
            return (
              <Form className="flex flex-col gap-4 w-full p-4 ">
                <div className="flex justify-between w-full items-center ">
                  <h1 className="text-xl text-[#00aa13]">Update</h1>
                  <IconButton
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      setupdate({
                        ...update,
                        flag: false,
                      });
                    }}
                    className="w-16 h-16"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <UpdateForm prop={props} update={update} />
                <Button variant="contained" type="submit" color="success">
                  Update
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
