import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { admin_users_data_type } from "../../models/API_data";

export interface admin_initial_state_type {
  admin: {
    data: {
      admin_data: admin_users_data_type[] | null | [];
    };
    loader: boolean;
  };
}
interface add_roles_payload_type {
  updated_role_data: admin_users_data_type;
  user_id: number;
}
interface delete_role_payload_type {
  user_id: number;
  role_mapping_id: number;
}

const initialState: admin_initial_state_type = {
  admin: {
    data: {
      admin_data: null,
    },
    loader: true,
  },
};
export const admin_slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmin_data: (state, action: PayloadAction<admin_users_data_type[]>) => {
      return {
        ...state,
        admin: {
          data: {
            admin_data: [...action.payload],
          },
          loader: false,
        },
      };
    },
    activate_user: (state, action: PayloadAction<admin_users_data_type>) => {
      const { admin } = state;
      // Create a copy of `admin_data`.
      if (admin.data.admin_data) {
        const admin_data = [...admin.data.admin_data];

        // Modify the matched item from the copied data.
        const data_to_update = action.payload;
        const modified_data = admin_data.map((item) => {
          if (item.UserId === data_to_update.UserId) {
            return { ...item, UserStatus: 1 };
          }
          return item;
        });

        // Return the new state with the modified data.
        return {
          ...state,
          admin: {
            ...admin,
            data: {
              ...admin.data,
              admin_data: modified_data,
            },
          },
        };
      }
    },
    deactivate_user: (state, action: PayloadAction<admin_users_data_type>) => {
      const { admin } = state;
      // Create a copy of `admin_data`.
      if (admin.data.admin_data) {
        const admin_data = [...admin.data.admin_data];

        // Modify the matched item from the copied data.
        const data_to_update = action.payload;
        const modified_data = admin_data.map((item) => {
          if (item.UserId === data_to_update.UserId) {
            return { ...item, UserStatus: 0 };
          }
          return item;
        });

        // Return the new state with the modified data.
        return {
          ...state,
          admin: {
            ...admin,
            data: {
              ...admin.data,
              admin_data: modified_data,
            },
          },
        };
      }
    },
    add_roles: (state, action: PayloadAction<add_roles_payload_type>) => {
      const { updated_role_data, user_id } = action.payload;
      const {
        admin: {
          data: { admin_data },
        },
      } = state;
      if (admin_data) {
        const updated_data = admin_data.map((item) => {
          if (item.UserId === user_id) {
            item = updated_role_data;
          }
          return item;
        });
        return {
          ...state,
          admin: {
            ...state.admin,
            data: {
              ...state.admin.data,
              admin_data: updated_data,
            },
          },
        };
      }
    },
    delete_role: (state, action: PayloadAction<delete_role_payload_type>) => {
      const {
        admin,
        admin: {
          data: { admin_data },
        },
      } = state;
      const { user_id, role_mapping_id } = action.payload;
      if (admin_data) {
        const updated_data = admin_data.map((item) => {
          if (item.UserId === user_id) {
            return {
              ...item,
              RoleMapping: item.RoleMapping.filter(
                (item) => item.UserRoleMappingId !== role_mapping_id
              ),
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          admin: {
            ...state.admin,
            data: {
              ...admin.data,
              admin_data: updated_data,
            },
          },
        };
      }
    },
  },
});
export const {
  setAdmin_data,
  activate_user,
  deactivate_user,
  add_roles,
  delete_role,
} = admin_slice.actions;
export default admin_slice.reducer;
