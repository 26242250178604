const baseUrl = process.env.REACT_APP_API_BASE_URL;
const lmsAllDataurl = `${baseUrl}/lms/v1/read`;
const VechileinOptionsurl = `${baseUrl}/vehicle/v1/all-vehicle`;
const vechileInPosturl = `${baseUrl}/hrc/vehicle-in/v1/add`;
const job_Card_dropdown_vehicleReg_url = `${baseUrl}/hrc/jobcard/v1/avl-vehicle`;
const vehicle_in_all_hrc = `${baseUrl}/hrc/vehicle-in/v1/get`;
const job_Card_dropdown_techinician_url = `${baseUrl}/hrc/jobcard/v1/technician-dropdown/`;
const job_Card_dropdown_bay_url = `${baseUrl}/hrc/jobcard/v1/bay-dropdown/`;
const job_Card_Post_url = `${baseUrl}/hrc/jobcard/v1/create`;
const job_Card_table_data_url = `${baseUrl}/hrc/jobcard/v1/fetch`;
const Inventory_product_add_url = `${baseUrl}/hrc/inventory/v1/add-component`;
const new_Inventory_dropdown_options_url = `${baseUrl}/hrc/inventory/v1/get-components`;
const create_Inventory_for_component = `${baseUrl}/hrc/inventory/v1/component-to-inventory`;
const create_inventory_from_job_card = `${baseUrl}/hrc/inventory/v1/create-inventory`;
const Inventory_allocation_table_data = `${baseUrl}/hrc/inventory/v1/get-inventory/`;
const new_Inventory_table_data_url = `${baseUrl}/hrc/inventory/v1/get-component-inventory`;
const assign_Inventory_dropdown = `${baseUrl}/hrc/inventory/v1/avl-component/`;
const lms_leadsource_dropdown_url = `${baseUrl}/lms/v1/lead-source`;
const vehicle_in_new_drop_menu = `${baseUrl}/hrc/vehicle-in/v1/avl-vehicle`;
const lms_leadstatus_drop_menu = `${baseUrl}/lms/v1/lead-status`;
const lms_vehicle_brand_menu = `${baseUrl}/vehicle-brand/v1/brand/get`;
const lms_state_drop_menu = `${baseUrl}/state/v1/get/1/state`;
const lms_add_lead = `${baseUrl}/lms/v1/add`;
const lms_update_lead = `${baseUrl}/lms/v1/update/`;
const import_csv_url = `${baseUrl}/lms/v1/bulk-upload`;
const new_inventory_supplier_dropdown = `${baseUrl}/hrc/mapping/v1/avl-seller`;
const purchase_data_url = `${baseUrl}/hrc/inventory/v1/get-purchase-order`;
// const purchase_items_post_url = `${baseUrl}/hrc/inventory/v1/create-purchase-order`;
const rider_data_url = `${baseUrl}/rider/v1/get`;
const add_rider_url = `${baseUrl}/rider/v1/add-rider`;
const repair_creteria_url = `${baseUrl}/lms/v1/each-question-cost`;
const repair_stage_url = `${baseUrl}/vehicle/v1/get-stage/`;
const update_stage_url = `${baseUrl}/lms/v1/update-stage`;
const vehicle_track_api = `${baseUrl}/vehicle/v1/get-vehicle-stage/`;
const all_vehicle_reg_api = `${baseUrl}/vehicle/v1/get-all-reg-no`;
const conversion_centers_api = `${baseUrl}/transit-ops/v1/get-conversion-centers`;
const experience_centers_api = `${baseUrl}/transit-ops/v1/get-experience-centers`;
const transit_vehicle_api = `${baseUrl}/transit-ops/v1/vehicle-ready-for-conversion`;
const all_users_api = `${baseUrl}/GT_users/v1/get-all-users`;
const post_vehicle_in_api = `${baseUrl}/transit-ops/v1/update/pickup-data/`;
const trans_vehicle_out_lists_api = `${baseUrl}/transit-ops/v1/vehicle-handover-ready`;
const vehicle_profile_add_api = `${baseUrl}/vehicle/v1/add`;
const wheel_type_dropdown_api = `${baseUrl}/vehicle/v1/get-rear-wheel-type`;
const user_type_api = `${baseUrl}/role/v1/get-roles`;
const vehicle_ready_to_in = `${baseUrl}/hrc/vehicle-in/v1/get/vehicle/avl-in`;
const component_acc_product_id_api = `${baseUrl}/hrc/mapping/v1/get-by-product/`;
const admin_get_users_api = `${baseUrl}/GT_users/v1/get-all-users`;
const admin_add_gt_user_api = `${baseUrl}/GT_users/v1/user/add`;
const admin_deactivate_user_api = `${baseUrl}/GT_users/v1/user/deactivate-user`;
const admin_activate_user_api = `${baseUrl}/GT_users/v1/user/reactivate-user`;
const admin_system_drop_api = `${baseUrl}/GT_users/v1/get-systems`;
const admin_role_drop_api = `${baseUrl}/GT_users/v1/get-roles`;
const admin_assign_role_api = `${baseUrl}/GT_users/v1/assign-roles/`;
const admin_delete_role_api = `${baseUrl}/GT_users/v1/user/remove-role/`;
const lms_sells_user_type_api = `${baseUrl}/lms/v1/get-user`;
const lms_lead_assign_user_api = `${baseUrl}/lms/v1/assign-lead/`;
const lms_walk_in_lead_api = `${baseUrl}/lms/v1/get/follow-up/walk-in`;
const hrc_products_data_api = `${baseUrl}/hrc/inventory/v1/get-product`;
const hrc_products_model_data_api = `${baseUrl}/hrc/inventory/v1/get/model/`;
const hrc_products_component_data_api = `${baseUrl}/hrc/mapping/v1/get-components/`;
const hrc_products_delete_api = `${baseUrl}/hrc/inventory/v1/delete-product/`;
const hrc_product_add_api = `${baseUrl}/hrc/inventory/v1/add-product`;
const hrc_model_dropdown_api = `${baseUrl}/vehicle/v1/get-vehicle-models`;
const hrc_prouduct_add_model_api = `${baseUrl}/hrc/mapping/v1/add/vehicle-product-mapping`;
const hrc_product_add_component_api = `${baseUrl}/hrc/mapping/v1/add`;
const hrc_product_delete_model_api = `${baseUrl}/hrc/mapping/v1/delete/product-vehicle`;
const hrc_product_delete_component_api = `${baseUrl}/hrc/mapping/v1/delete`;
const hrc_product_edit_api = `${baseUrl}/hrc/inventory/v1/edit-product/`;
const lms_get_users_api = `${baseUrl}/lms/v3/get-user`;
const lms_get_gender_drp_api = `${baseUrl}/gender/v1/get`;
const lms_get_profession_drp_api = `${baseUrl}/lms/v1/get-profession`;
const lms_sub_stages_drp_api = `${baseUrl}/lms/v1/get-sub-stages`;
// hrc-product-api
const hrc_product_list_api = `${baseUrl}/hrc/inventory/v1/get-components`;
const hrc_vendor_list_api = `${baseUrl}/hrc/mapping/v1/avl-seller`;
const hrc_sow_list_api = `${baseUrl}/sow/v1/get-all-sow`;
const hrc_shipping_details_menu = `${baseUrl}/shipping/v1/getAll`;
const hrc_category_menu_api = `${baseUrl}/hrc/mapping/v1/product-category`;
const hrc_currency_menu_api = `${baseUrl}/sow/v1/all-currency`;
const hrc_all_seller_acc_prod_cate_api = `${baseUrl}/hrc/mapping/v1/vendor/`;
const hrc_all_sow_acc_vendor_id_api = `${baseUrl}/sow/v1/sow/`;
const get_all_country_api = `${baseUrl}/country/v1/get/all`;
const product_menu_acc_seller_api = `${baseUrl}/hrc/inventory/v1/get/dropdown/products/`;
// hrc-product post api
const hrc_add_product_api = `${baseUrl}/hrc/inventory/v1/add-component`;
const hrc_update_product_api = `${baseUrl}/hrc/inventory/v1/update-component/`;
const hrc_add_vendor_api = `${baseUrl}/hrc/mapping/v1/add/seller`;
const hrc_update_vendor_api = `${baseUrl}/hrc/mapping/v1/update-vendor/`;
const hrc_add_sow_api = `${baseUrl}/sow/v1/create-sow`;
const hrc_update_sow_api = `${baseUrl}/sow/v1/edit/`;
const hrc_single_vendor_data_api = `${baseUrl}/hrc/mapping/v1/fetch-vendor/`;
const hrc_single_sow_data_api = `${baseUrl}/sow/v1/get/`;
const gen_account_type_menu_api = `${baseUrl}/bank/v1/get`;
const hrc_get_manual_testing_data_api = `${baseUrl}/vehicle-manual-testing/v2/get`;
const hrc_get_vehicle_riding_data_api = `${baseUrl}/rider-stats/v2/riding-info/recent-week/`;
const get_all_device_api = `${baseUrl}/hrc/device-master/v1/get/linked/all-devices`;
const get_rider_status_daily_data_api = `${baseUrl}/rider-stats/v2/get/riding-info/by-day/`;
const get_product_data_by_product_id = `${baseUrl}/hrc/inventory/v1/get/products/`;
const all_purchase_status_drop_menu_api = `${baseUrl}/hrc/inventory/v1/purchase-order-stage/dropdown`;
const create_purchase_order_api = `${baseUrl}/hrc/inventory/v1/create-purchase-order`;
const update_purchase_order_status = `${baseUrl}/hrc/inventory/v1/update/purchase-order/`;
const lms_lead_type_dropdown_api = `${baseUrl}/lms/v1/get/lead-types`;

export {
  baseUrl,
  lmsAllDataurl,
  VechileinOptionsurl,
  vechileInPosturl,
  job_Card_dropdown_bay_url,
  job_Card_dropdown_techinician_url,
  job_Card_dropdown_vehicleReg_url,
  job_Card_Post_url,
  job_Card_table_data_url,
  Inventory_product_add_url,
  new_Inventory_dropdown_options_url,
  create_Inventory_for_component,
  create_inventory_from_job_card,
  Inventory_allocation_table_data,
  new_Inventory_table_data_url,
  assign_Inventory_dropdown,
  lms_leadsource_dropdown_url,
  vehicle_in_new_drop_menu,
  lms_leadstatus_drop_menu,
  lms_vehicle_brand_menu,
  lms_state_drop_menu,
  lms_add_lead,
  lms_update_lead,
  import_csv_url,
  vehicle_in_all_hrc,
  new_inventory_supplier_dropdown,
  purchase_data_url,
  // purchase_items_post_url,
  rider_data_url,
  add_rider_url,
  repair_creteria_url,
  repair_stage_url,
  update_stage_url,
  vehicle_track_api,
  all_vehicle_reg_api,
  conversion_centers_api,
  experience_centers_api,
  transit_vehicle_api,
  all_users_api,
  post_vehicle_in_api,
  trans_vehicle_out_lists_api,
  vehicle_profile_add_api,
  wheel_type_dropdown_api,
  user_type_api,
  vehicle_ready_to_in,
  component_acc_product_id_api,
  admin_get_users_api,
  admin_add_gt_user_api,
  admin_deactivate_user_api,
  admin_activate_user_api,
  admin_system_drop_api,
  admin_role_drop_api,
  admin_assign_role_api,
  admin_delete_role_api,
  lms_sells_user_type_api,
  lms_lead_assign_user_api,
  lms_walk_in_lead_api,
  hrc_products_data_api,
  hrc_products_delete_api,
  hrc_products_model_data_api,
  hrc_products_component_data_api,
  hrc_product_add_api,
  hrc_model_dropdown_api,
  hrc_prouduct_add_model_api,
  hrc_product_add_component_api,
  hrc_product_delete_model_api,
  hrc_product_delete_component_api,
  hrc_product_edit_api,
  lms_get_users_api,
  lms_get_gender_drp_api,
  lms_get_profession_drp_api,
  lms_sub_stages_drp_api,
  hrc_product_list_api,
  hrc_vendor_list_api,
  hrc_sow_list_api,
  hrc_shipping_details_menu,
  hrc_category_menu_api,
  hrc_currency_menu_api,
  hrc_all_seller_acc_prod_cate_api,
  hrc_all_sow_acc_vendor_id_api,
  hrc_add_product_api,
  hrc_add_vendor_api,
  hrc_add_sow_api,
  hrc_single_vendor_data_api,
  get_all_country_api,
  hrc_single_sow_data_api,
  hrc_update_product_api,
  hrc_update_vendor_api,
  hrc_update_sow_api,
  gen_account_type_menu_api,
  hrc_get_manual_testing_data_api,
  hrc_get_vehicle_riding_data_api,
  get_all_device_api,
  get_rider_status_daily_data_api,
  product_menu_acc_seller_api,
  get_product_data_by_product_id,
  all_purchase_status_drop_menu_api,
  create_purchase_order_api,
  update_purchase_order_status,
  lms_lead_type_dropdown_api,
};
