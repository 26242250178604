import React, { useState, useEffect } from "react";

type HrcAnalyticsProps = {
  // : string;
};

const HrcAnalytics: React.FC<HrcAnalyticsProps> = ({}) => {
  const [, set] = useState<string>();
  useEffect(() => {
    // set();
  }, []);

  return (
    <div className="h-full w-full p-2">
      <iframe
        title="new powerbi dec"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=5151b034-e708-43e5-8260-8b1c03871ccd&autoAuth=true&ctid=6acfae4b-86a7-4ec0-9b0d-6bb473d1ea5c"
        frameBorder="0"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default HrcAnalytics;
