import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
export interface initialStateType {
  access_sections: {
    lms: boolean;
    hrc: boolean;
    admin: boolean;
  };
}
// defining initialState
const initialState: initialStateType = {
  access_sections: {
    lms: false,
    hrc: false,
    admin: false,
  },
};
// creating reducer
export const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    setAccessPage: (state, action: PayloadAction<initialStateType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});
export const { setAccessPage } = accessSlice.actions;
export default accessSlice.reducer;
