import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { vendorModel } from "../../../../models/product_data_model";
import IconButton from "@mui/material/IconButton";
interface vendorAccordionProps {
  vendor: vendorModel;
  setupdate: Function;
}

export default function VendorAccordion({
  vendor,
  setupdate,
}: vendorAccordionProps) {
  return (
    <div>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ borderBottom: "thin solid #00aa13" }}
        >
          <div className="grid grid-cols-6 w-full">
            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Name
              </span>
              <span className="truncate text-[16px] text-center ">
                {vendor?.name}
              </span>
            </div>
            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Seller Code
              </span>
              <span className="truncate text-[16px] text-center ">
                {vendor?.seller_code}
              </span>
            </div>
            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Pan Number
              </span>
              <span className="truncate text-[16px] text-center ">
                {vendor?.pan}
              </span>
            </div>
            <div className="flex flex-col ">
              <span className="truncate  text-[20px] text-gray-700 text-center">
                Contact Number
              </span>
              <span className="truncate text-[16px] text-center ">
                {vendor?.contact}
              </span>
            </div>
            <div className="flex flex-col ">
              <span className="truncate  text-[20px] text-gray-700 text-center">
                GST Number
              </span>
              <span className="truncate text-[16px] text-center ">
                {vendor?.gst_no}
              </span>
            </div>
            <div className="flex items-center justify-end w-fit ">
              <IconButton
                aria-label=""
                onClick={(e) => {
                  e.preventDefault();
                  setupdate({
                    flag: true,
                    data: vendor,
                    id: vendor?.seller_id,
                  });
                }}
                className="w-16 h-16"
              >
                <i className="fa-sharp fa-solid fa-pen text-[#00aa13]"></i>
              </IconButton>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate text text-[20px] text-gray-700 text-center">
                  POC Name
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.poc_name}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  POC Contact
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.poc_mobileno}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  POC Email
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.poc_email}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  City
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.city_name}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  State
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.state_name}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  Country
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.country_name}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  Pin Code
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.pincode}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  CGST
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.cgst}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  IGST
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.igst}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  SGST
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.sgst}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  Updated by
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.updatedby}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  Updated On
                </span>
                <span className="truncate text-[16px] text-center ">
                  {new Date(vendor?.updatedAt ?? "")?.toLocaleString(
                    "default",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  Vendor address
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor.address}
                </span>
              </div>
            </div>
            <h1>Bank Details:-</h1>
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate  text-[20px] text-gray-700 text-center">
                  Account Number
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.account_no}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  IFSC
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.ifsc_code}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Name
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.bank_name}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Beneficiary Name
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.beneficiary_name}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Address
                </span>
                <span className="truncate text-[16px] text-center ">
                  {vendor?.bank_address}
                </span>
              </div>
              {/* <div className="flex flex-col ">
                <span className="truncate text-[18px] text-gray-400 text-center">
                  Penalty Terms
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.}
                </span>
              </div> */}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
