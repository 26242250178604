import TextField from "@mui/material/TextField";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { updateSowInitialValueType } from "./formik_essential";
import { updateType } from "../../../../../../models/product_data_model";
import { RootState } from "../../../../../../app/store";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import customFetchWithToken from "../../../../../../API/CustomFetchWtihToken";
import { hrc_shipping_details_menu } from "../../../../../../API/api";
import { setshipping_method_menu } from "../../../../../../features/hrc_actions/hrc_slice";

type Props = {
  prop: FormikProps<updateSowInitialValueType>;
  update: updateType<updateSowInitialValueType>;
};

const UpdateSow = (props: Props) => {
  const { values, touched, errors, handleChange, setValues } = props.prop;
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.update.data) {
      setValues(props.update.data);
    }
  }, [props.update]);
  const {
    user: { token_id },
    hrc: {
      new_product_data: {
        shipping_method_dropmenu: {
          data: { payment_method, payment_terms, shipping_method },
        },
      },
    },
  } = useSelector((state: RootState) => state);
  useEffect(() => {
    if (token_id) {
      customFetchWithToken(hrc_shipping_details_menu, token_id).then((data) => {
        dispatch(setshipping_method_menu([...data.DATA]));
      });
    }
  }, [token_id]);
  console.log(errors);
  return (
    <div className="flex flex-col gap-6">
      <TextField
        id="payment_terms"
        name="payment_terms"
        label="Payment Terms"
        value={values.payment_terms}
        onChange={handleChange}
        error={touched.payment_terms && Boolean(errors.payment_terms)}
        helperText={touched.payment_terms && errors.payment_terms}
      />
      <TextField
        id="validity_sow"
        name="validity_sow"
        label="Validity SOW"
        value={values.validity_sow}
        onChange={handleChange}
        error={touched.validity_sow && Boolean(errors.validity_sow)}
        helperText={touched.validity_sow && errors.validity_sow}
      />
      <TextField
        id="order_projection"
        name="order_projection"
        label="Order Projection"
        value={values.order_projection}
        onChange={handleChange}
        error={touched.order_projection && Boolean(errors.order_projection)}
        helperText={touched.order_projection && errors.order_projection}
      />
      <TextField
        id="warranty"
        name="warranty"
        label="Warranty"
        value={values.warranty}
        onChange={handleChange}
        error={touched.warranty && Boolean(errors.warranty)}
        helperText={touched.warranty && errors.warranty}
      />
      <TextField
        id="terms_conditions"
        name="terms_conditions"
        label="Terms & Conditions"
        value={values.terms_conditions}
        onChange={handleChange}
        error={touched.terms_conditions && Boolean(errors.terms_conditions)}
        helperText={touched.terms_conditions && errors.terms_conditions}
      />
      <TextField
        id="time_policy"
        name="time_policy"
        label="Time Policy"
        value={values.time_policy}
        onChange={handleChange}
        error={touched.time_policy && Boolean(errors.time_policy)}
        helperText={touched.time_policy && errors.time_policy}
      />
      <TextField
        id="cost_ownership"
        name="cost_ownership"
        label="Cost ownership"
        value={values.cost_ownership}
        onChange={handleChange}
        error={touched.cost_ownership && Boolean(errors.cost_ownership)}
        helperText={touched.cost_ownership && errors.cost_ownership}
      />
      <TextField
        id="sow_reference_no"
        name="sow_reference_no"
        label="Sow Reference Number"
        value={values.sow_reference_no}
        onChange={handleChange}
        error={touched.sow_reference_no && Boolean(errors.sow_reference_no)}
        helperText={touched.sow_reference_no && errors.sow_reference_no}
      />
      <TextField
        id="replacement_time"
        name="replacement_time"
        label="Replacement time"
        value={values.replacement_time}
        onChange={handleChange}
        error={touched.replacement_time && Boolean(errors.replacement_time)}
        helperText={touched.replacement_time && errors.replacement_time}
      />
      <TextField
        id="delivery_time"
        name="delivery_time"
        label="Delivery Time"
        value={values.delivery_time}
        onChange={handleChange}
        error={touched.delivery_time && Boolean(errors.delivery_time)}
        helperText={touched.delivery_time && errors.delivery_time}
      />
      <TextField
        id="shipping_terms"
        name="shipping_terms"
        select
        label="Shipping Terms"
        value={values.shipping_terms}
        onChange={handleChange}
        error={touched.shipping_terms && Boolean(errors.shipping_terms)}
        helperText={touched.shipping_terms && errors.shipping_terms}
      >
        {payment_terms.map((item) => (
          <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
            {item.TypeName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="shipping_method"
        name="shipping_method"
        select
        label="Shipping Method"
        value={values.shipping_method}
        onChange={handleChange}
        error={touched.shipping_method && Boolean(errors.shipping_method)}
        helperText={touched.shipping_method && errors.shipping_method}
      >
        {shipping_method.map((item) => (
          <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
            {item.TypeName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="payment_method"
        name="payment_method"
        label="Payment method"
        select
        value={values.payment_method}
        onChange={handleChange}
        error={touched.payment_method && Boolean(errors.payment_method)}
        helperText={touched.payment_method && errors.payment_method}
      >
        {payment_method.map((item) => (
          <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
            {item.TypeName}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default UpdateSow;
