import React from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

type jobCardprops = {
  setflag: Function;
};

const AddButton = ({ setflag }: jobCardprops) => {
  return (
    <div>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ backgroundColor: "#00aa13" }}
        onClick={() => setflag(false)}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default AddButton;
