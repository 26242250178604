import React, { useState, useEffect } from "react";
import AdminNav from "./admin_nav";
import UserTable from "./user_table";
import AdminAddUserForm from "./admin_add_user_form";
import { setAdmin_data } from "../../features/admin_actions/admin_slice";
import { useDispatch, useSelector } from "react-redux";
import { admin_get_users_api } from "../../API/api";
import customFetchWithToken from "../../API/CustomFetchWtihToken";
import { RootState } from "../../app/store";
type AdminToolsProps = {
  // : string;
};

const AdminTools: React.FC<AdminToolsProps> = ({}) => {
  const [add_user, setadd_user] = useState(false);
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token_id) {
      customFetchWithToken(admin_get_users_api, token_id, {
        method: "GET",
      }).then((data) => dispatch(setAdmin_data(data.DATA)));
    }
  }, [token_id]);

  return (
    <div className="w-screen h-screen">
      <div className="w-full">
        <AdminNav setadd_user={setadd_user} />
      </div>
      <div className="w-full flex justify-center h-[90%] items-center">
        {!add_user ? (
          <div className="md:w-11/12 w-[96%] h-[85%] ">
            <UserTable />
          </div>
        ) : (
          <div className="w-[60%] shadow-inner border-2  border-green-700 h-[75%] bg-white relative flex flex-col justify-center ">
            <h1 className="absolute text-2xl text-green-700 font-bold bg-white -top-5 left-14">
              Add User
            </h1>
            <div className="w-[90%] self-center">
              <AdminAddUserForm />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminTools;
