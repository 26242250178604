import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { custom_theme } from "../../../../custom_them/custom_them";
import { ThemeProvider } from "@mui/material/styles";
import VehicleRidingInfo from "../../Components/vehicle_data_components/vehicle_riding_info";
import ManualTestingInfo from "../../Components/vehicle_data_components/manual_testing_info";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  device_id_drop_menu_type,
  device_id_response,
} from "../../../../models/vehicle_data_models";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { get_all_device_api } from "../../../../API/api";
import DailyInsight from "../../Components/vehicle_data_components/daily_insight";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className="w-full h-full "
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: "100%", height: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VehicleData() {
  const [value, setValue] = React.useState(0);
  const [selectedDevice, setSelectedDevice] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [deviceIdMenu, setDeviceIdMenu] = React.useState<
    device_id_drop_menu_type[]
  >([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    customFetchWithToken(get_all_device_api, "").then(
      (data: device_id_response) => setDeviceIdMenu(data.DATA)
    );
  }, []);

  return (
    <div className="p-3 w-full h-full ">
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <ThemeProvider theme={custom_theme}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Vehicle Riding Info" {...a11yProps(0)} />
              <Tab label="Manual Testing Info" {...a11yProps(1)} />
              <Tab label="Daily Insight" {...a11yProps(2)} />
            </Tabs>
          </ThemeProvider>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="flex flex-col gap-4">
            <TextField
              id="device id"
              label="Device Id"
              select
              value={selectedDevice}
              onChange={(e) => setSelectedDevice(e.target.value)}
              className="self-end "
              color="success"
              sx={{ minWidth: "250px" }}
            >
              {deviceIdMenu.map((item) => (
                <MenuItem value={item.IoTDeviceId} key={item.IoTDeviceId}>
                  {item.IoTDeviceId}
                </MenuItem>
              ))}
            </TextField>
            {selectedDevice && <VehicleRidingInfo deviceId={selectedDevice} />}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ManualTestingInfo />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="flex flex-col gap-8 ">
            <h1 className="text-2xl">
              {selectedDate
                ? `${new Date(selectedDate.toString()).toLocaleString(
                    "default",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}`
                : `${new Date().toLocaleString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}`}
            </h1>
            <div className="self-end min-w-[300px]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </LocalizationProvider>
            </div>

            <DailyInsight selectedDate={selectedDate} />
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
