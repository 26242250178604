import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { FormikValues, FormikHelpers, Formik, Form } from "formik";
import Button from "@mui/material/Button";
import {
  ProductSchema,
  initialProduct,
  productType,
} from "../Forms/product_form/product_schema";

import { FormContent } from "../../../../models/product_data_model";

interface ProductStepperProps {
  steps: string[];
  formContents: FormContent<any>[];
  dipatchData: (value: any, activeStep: number) => void;
}

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgba(131,58,180,1) 0%, rgba(60,253,29,1) 50%, rgba(252,176,69,1) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgba(131,58,180,1) 0%, rgba(60,253,29,1) 50%, rgba(252,176,69,1) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg,rgba(2,0,36,1) 0%, rgba(17,121,9,1) 35%, rgba(0,212,255,1) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg,radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%))",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <i className={`fa-brands fa-product-hunt ${active && "fa-fade"}`}></i>,
    2: <i className={`fa-solid fa-shop ${active && "fa-fade"}`}></i>,
    3: <i className={`fa-solid fa-list-check ${active && "fa-fade"} `}></i>,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
// const steps = ["Product Details", "Vendor Details", "SOW Details"];

export default function ProductStepper({
  formContents,
  dipatchData,
  steps,
}: ProductStepperProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepOptional = (step: number) => {
    return step === 3;
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className="w-full  h-full ">
        {formContents.map(
          (item, index) =>
            index === activeStep && (
              <Formik
                key={index}
                initialValues={item.initialValues}
                validationSchema={item.validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  console.log(values);
                  dipatchData(values, activeStep);
                  if (!(activeStep === steps.length - 1)) {
                    handleNext();
                  }
                  setSubmitting(false);
                }}
              >
                {(props) => {
                  return (
                    <Form className="flex flex-col gap-5 h-full w-full">
                      {item.content(props)}

                      <div className="self-end">
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          color="success"
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="success"
                          type="submit"
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )
        )}
      </div>
    </Stack>
  );
}
