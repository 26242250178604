import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FollowUpWakingDrawer from "../../Components/flw_wking_drwr/flw_wking_drwr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { lmsData, opentoaddData } from "../../../../models/lms_data_api";
import Accordian from "../../Components/Accordian";
import { Dayjs } from "dayjs";
import { ThemeProvider } from "@mui/material/styles";
import { custom_theme } from "../../../../custom_them/custom_them";
import Loader from "../../../../Global_Component/loader/loader";
import PaginationControlled from "../../Components/pagination/pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { setfollowUpWalkIn } from "../../../../features/lms_actions/lmsSlice";
import { handlePaginationData } from "../../../../lms_utility_func";

type follow_up_walkin_pros = {
  opentoAdd: opentoaddData;
  setOpentoAdd: Function;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  page: number;
  row: number;
  setrow: Function;
  setPage: Function;
}

interface tab_filterd_data_type {
  today: [] | lmsData[];
  missed: [] | lmsData[];
  planned: [] | lmsData[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, page, row, setPage, setrow, ...other } =
    props;
  const scroll_ref = React.useRef<HTMLDivElement>(null);
  React.useMemo(() => {
    scroll_ref.current?.scrollTo(0, 0);
    return null;
  }, [page, row]);
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full w-full  overflow-auto custom-scroll"
      ref={scroll_ref}
    >
      {value === index && (
        <>
          <Box
            sx={{
              p: 3,
              width: "100%",
              "@media (max-width:768px)": {
                p: 0.5,
              },
            }}
          >
            {children}
          </Box>
          <div className="flex self-start w-full justify-between items-center gap-8  py-4">
            <div className="flex">
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  color="success"
                >
                  row
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={row}
                  onChange={(e) => setrow(Number(e.target.value))}
                  autoWidth
                  color="success"
                  label="row"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={80}>80</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <PaginationControlled
                handleChange={handlePage}
                page={page}
                row={row}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FollowUpWalkin({
  opentoAdd,
  setOpentoAdd,
}: follow_up_walkin_pros) {
  //states
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [row, setrow] = React.useState(10);
  const dispatch = useDispatch();
  const [tabs_filtered_data, settabs_filtered_data] =
    React.useState<tab_filterd_data_type>({
      today: [],
      missed: [],
      planned: [],
    });

  //redux
  const {
    followUpWalkIn: { appData },
    lmsData: { followUp },
    appData: { dataAccToPage, loader, message },
  } = useSelector((state: RootState) => state.lms);

  // all functions

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // function to render data acc to tab
  const tab_data = (data: lmsData[] | null) => {
    if (loader) {
      return (
        <div className="w-full h-[35rem] flex-auto flex justify-center items-center ">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col gap-5 ">
          {dataAccToPage?.length ? (
            dataAccToPage.map((item) => (
              <Accordian
                opentoAdd={opentoAdd}
                setOpentoAdd={setOpentoAdd}
                item={item}
                key={item.LeadId}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-full w-full gap-2">
              <h1 className="text-2xl text-red-900 tracking-widest">
                {message}
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          )}
        </div>
      );
    }
  };

  function filterData(follow_up_walk_in: lmsData[]) {
    return new Promise((resolve, reject) => {
      let todayTimestamp = new Date().setHours(0, 0, 0, 0);
      const todayList = follow_up_walk_in.filter(
        (item) =>
          new Date(item?.NextFollowUp ?? "").getTime() === todayTimestamp
      );
      const missedList = follow_up_walk_in.filter(
        (item) => new Date(item?.NextFollowUp ?? "").getTime() < todayTimestamp
      );
      const plannedList = follow_up_walk_in.filter(
        (item) => new Date(item?.NextFollowUp ?? "").getTime() > todayTimestamp
      );

      const DATA = {
        today: todayList,
        missed: missedList,
        planned: plannedList,
      };

      if (DATA) {
        resolve(DATA);
      } else {
        reject("Error occurred while filtering data");
      }
    });
  }
  React.useEffect(() => {
    const walk_in_data =
      followUp?.filter((item) => {
        if (item.LeadStatus === 43) {
          return item;
        }
      }) || [];
    walk_in_data.map((item) => console.log(item.LeadStatus));
    dispatch(setfollowUpWalkIn(walk_in_data));
  }, []);

  React.useEffect(() => {
    if (appData) {
      filterData(appData)
        .then((result: any) => {
          settabs_filtered_data({
            ...tabs_filtered_data,
            ...result,
          });
        })
        .catch((err: Error) => console.log(err));
    }
  }, [appData]);
  React.useEffect(() => {
    if (value === 0 && appData) {
      handlePaginationData(appData, page, row, "No records", true, dispatch);
    } else if (value === 1) {
      handlePaginationData(
        tabs_filtered_data.today,
        page,
        row,
        "No records",
        true,
        dispatch
      );
    } else if (value === 2) {
      handlePaginationData(
        tabs_filtered_data.missed,
        page,
        row,
        "No records",
        true,
        dispatch
      );
    } else if (value === 3) {
      handlePaginationData(
        tabs_filtered_data.planned,
        page,
        row,
        "No records",
        true,
        dispatch
      );
    }
  }, [value, page, row, followUp]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        id="follow-up-walkin-title"
        className="w-full border-b-2 border-green-700 mb-6 pt-8 flex gap-5"
      >
        <div className="w-48 h-10 bg-green-700 rounded rounded-b-none flex justify-center items-center text-white">
          <h1>Walk In</h1>
        </div>
      </div>
      {!(value === 1) && (
        <div className="absolute  right-5 lg:bottom-14  bottom-16">
          <FollowUpWakingDrawer value={value} page={page} row={row} />
        </div>
      )}
      <Box
        sx={{
          height: "46px",
        }}
      >
        <ThemeProvider theme={custom_theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={`All(${appData?.length})`}
              {...a11yProps(0)}
              sx={{
                color: "gray",
                "@media (max-width:576px)": {
                  fontSize: "0.78rem",
                  minWidth: "1.2rem",
                },
              }}
            />
            <Tab
              label={`Today(${tabs_filtered_data.today.length})`}
              {...a11yProps(1)}
              sx={{
                color: "gray",
                "@media (max-width:576px)": {
                  fontSize: "0.78rem",
                  minWidth: "1.2rem",
                },
              }}
            />
            <Tab
              label={`Missed(${tabs_filtered_data.missed.length})`}
              {...a11yProps(2)}
              sx={{
                color: "gray",
                "@media (max-width:576px)": {
                  fontSize: "0.78rem",
                  minWidth: "1.2rem",
                },
              }}
            />
            <Tab
              label={`Planned(${tabs_filtered_data.planned.length})`}
              {...a11yProps(3)}
              sx={{
                color: "gray",
                "@media (max-width:576px)": {
                  fontSize: "0.78rem",
                  minWidth: "1.2rem",
                },
              }}
            />
          </Tabs>
        </ThemeProvider>
      </Box>
      {Object.keys(tabs_filtered_data).map((_, index) => {
        return (
          <TabPanel
            value={value}
            index={index}
            page={page}
            row={row}
            setPage={setPage}
            setrow={setrow}
          >
            {tab_data(dataAccToPage)}
          </TabPanel>
        );
      })}
    </Box>
  );
}
