import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import Table from "@react-pdf/renderer";
import TableHeader from "@react-pdf/renderer";
import TableBody from "@react-pdf/renderer";

type Props = {};

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    padding: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    width: 100,
    height: 100,
    marginRight: 10,
  },
  companyDetails: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  companyName: {
    fontWeight: "bold",
    fontSize: 16,
  },
  companyAddress: {
    fontSize: 12,
    marginBottom: 10,
  },
  title: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 20,
  },
  table: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
});
const order = {
  products: [
    {
      name: "motor",
      description: "two coils",
      quantity: 3,
      unitPrice: 300,
    },
  ],
};
const PurchaseExports = (props: Props) => {
  return (
    <PDFViewer className="w-full h-screen">
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.companyDetails}>
              <Image
                source="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/hrc_lms_content/gt_fav_icon.png"
                style={styles.logo}
              />
              <Text style={styles.companyName}>
                Green Tiger Mobility Pvt Ltd
              </Text>
              <Text style={styles.companyAddress}>
                62/1/15,2nd Floor Kodichikkanahalli Main Road, Bommanahalli
                Village, Begur Hubli, GSTIN/UIN: 29AAICG6260B1ZO State Name :
                Karnataka, Code : 29 E-Mail : info@greentiger.in
              </Text>
            </View>
            <View>
              <Text>Purchase Order</Text>
              <View>
                <Text>Date: {new Date().toString()} </Text>
                <Text>PO ID: </Text>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Product</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Type</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Period</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Price</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>React-PDF</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>3 User </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>5€</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PurchaseExports;
