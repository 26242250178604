import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import UpdateIcon from "@mui/icons-material/Update";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import About from "../../Modules/about_app/about";

type DropBarProps = {
  setdrop_show: Function;
  setOpen: Function;
  open: boolean;
};

const DropBar: React.FC<DropBarProps> = ({ setdrop_show, setOpen, open }) => {
  const navigate = useNavigate();
  const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(true);
    setdrop_show(false);
  };

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    const auth = getAuth();
    auth.signOut();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  const {
    access: {
      access_sections: { admin },
    },
  } = useSelector((state: RootState) => state);
  return (
    <div
      className="w-[250px] shadow-2xl bg-white absolute top-14 py-6 right-10 rounded-sm  border border-gray-200 cursor-pointer"
      style={{ zIndex: "2000" }}
    >
      <div
        className="flex justify-between py-3 px-6 hover:bg-gray-500"
        onClick={handleLogout}
      >
        <LogoutIcon />
        <span>LogOut</span>
      </div>
      <div
        className="flex justify-between py-3 px-6 hover:bg-gray-500"
        onClick={handleOpen}
      >
        <UpdateIcon />
        <span>Update Password</span>
      </div>

      {admin && (
        <div
          className="flex justify-between py-3 px-6 hover:bg-gray-500"
          onClick={(e) => {
            e.stopPropagation();
            navigate("/admin");
          }}
        >
          <AdminPanelSettingsIcon />
          <span>Admin</span>
        </div>
      )}
      <About />
    </div>
  );
};

export default React.memo(DropBar);
