import * as yup from "yup";
interface IObjectKeys {
  [key: string]: string | number | null | object | boolean;
}
const purchase_order_validation_shema = yup.object().shape({
  sow_id: yup.mixed().nullable(),
  shipping_amount: yup.number().required(),
  tds: yup.number().required(),
  purchase_status_id: yup.number().required(),
  createdBy: yup.number().required(),
  grand_total: yup.number().required(),
  po_items: yup
    .array()
    .of(
      yup.object().shape({
        product_id: yup.number().required("product Id is required"),
        quantity: yup
          .number()
          .notOneOf([0], "Qty can not be 0")
          .required("quantity is required"),
        amount: yup.number().required("Amount is required"),
        total_amount: yup.number(),
        discount: yup.number(),
        created_by: yup.number(),
      })
    )
    .default([])
    .required("po items is required"),
});
const purchase_order_initial_value: PurchaseOrder = {
  sow_id: null,
  shipping_amount: 0,
  tds: 0,
  purchase_status_id: null,
  createdBy: 1,
  grand_total: 0,
  po_items: [],
};
export interface PurchaseOrder {
  sow_id: number | null;
  shipping_amount: number | null;
  tds: number | null;
  purchase_status_id: number | null;
  createdBy: number | null;
  grand_total: number;
  po_items: PoItem[];
}

export interface PoItem extends IObjectKeys {
  product_id: number;
  quantity: number;
  cgst: number;
  igst: number;
  sgst: number;
  amount: number;
  total_amount: number;
  discount: number;
  created_by: number;
}

// const purchase_order_form_initial_schema = yup.object().shape({
//   Component_Id: yup.number().required(),
//   CreatedBy_Name: yup.string().required(),
//   Product_Code: yup.string().required(),
//   Product_Name: yup.string().required(),
//   category: yup.number().required(),
//   cgst_percentage: yup.number().required(),
//   createdAt: yup.mixed().nullable(),
//   createdBy: yup.number().required(),
//   currency: yup.number().required(),
//   description: yup.mixed().nullable(),
//   discount: yup.mixed().nullable(),
//   hasSerial: yup.number().required(),
//   hsn_no: yup.mixed().nullable(),
//   igst_percentage: yup.number().required(),
//   image: yup.mixed().nullable(),
//   isActive: yup.number().required(),
//   lead_time: yup.mixed().nullable(),
//   payment_terms: yup.mixed().nullable(),
//   price_per_unit: yup.mixed().nullable(),
//   ratings: yup.number().nullable(),
//   sgst_percentage: yup.number().required(),
//   tax: yup.mixed().nullable(),
//   updatedAt: yup.date().required(),
//   updatedBy: yup.number().required(),
//   updatedBy_Name: yup.string().required(),
//   weight_kg: yup.number().nullable(),
// });
// const purchase_order_form_initial_value: purchase_order_get_product_info = {
//   Component_Id: 0,
//   CreatedBy_Name: "",
//   Product_Code: "",
//   Product_Name: "",
//   category: 0,
//   cgst_percentage: 0,
//   createdAt: null,
//   createdBy: 0,
//   currency: 0,
//   description: null,
//   discount: null,
//   hasSerial: 0,
//   hsn_no: null,
//   igst_percentage: 0,
//   image: null,
//   isActive: 0,
//   lead_time: null,
//   payment_terms: null,
//   price_per_unit: null,
//   ratings: null,
//   sgst_percentage: 0,
//   tax: null,
//   updatedAt: new Date(),
//   updatedBy: 0,
//   updatedBy_Name: "",
//   weight_kg: null,
// };

export { purchase_order_validation_shema, purchase_order_initial_value };

export interface purchase_order_get_product_response {
  DATA: purchase_order_get_product_info[];
  MESSAGE: string;
  SUCCESS: boolean;
}

export interface purchase_order_get_product_info {
  Component_Id: number;
  CreatedBy_Name: string;
  Product_Code: string;
  Product_Name: string;
  category: number;
  cgst_percentage: number;
  createdAt: null | string;
  createdBy: number;
  currency: number;
  description: null | string;
  discount: null | string;
  hasSerial: number;
  hsn_no: null | string;
  igst_percentage: number;
  image: null;
  isActive: number;
  lead_time: null;
  payment_terms: null;
  price_per_unit: null;
  ratings: null | number;
  sgst_percentage: number;
  tax: null;
  updatedAt: Date;
  updatedBy: number;
  updatedBy_Name: string;
  weight_kg: null | number;
}
