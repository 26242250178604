import React, { useState, useEffect } from "react";
import TopNav from "../../Components/top_nav/top_nav";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TimelineIcon from "@mui/icons-material/Timeline";
import VehicleTrack from "../../Components/Stepper/Steepper";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tooltip from "@mui/material/Tooltip";
import VerticalLinearStepper from "../../Components/vertical_setpper/vertical_stepper";
import { useNavigate } from "react-router-dom";
import { stepper_data_type } from "../../../../models/API_data";
import customFetch from "../../../../API/CustomFetch";
import { vehicle_track_api, all_vehicle_reg_api } from "../../../../API/api";
type AppProps = {};

interface seller_options_type {
  label: string;
  id: number;
}
interface dropdown_api_type {
  VehicleProfileId: number;
  Registration_No: string;
}

const VehicleStatus: React.FC<AppProps> = ({}) => {
  const [drop_show, setdrop_show] = useState(false);

  const [openNav, setOpenNav] = useState(false);
  const [reg_no_options, setreg_no_options] = useState<
    seller_options_type[] | []
  >([]);
  const [stepper_data, setstepper_data] = useState<[] | stepper_data_type[]>(
    []
  );
  const [reg_input, setreg_input] = useState("");
  const navigate = useNavigate();
  const [vertical_stepper, setVertical_stepper] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 576) {
      setVertical_stepper(true);
    }

    customFetch(all_vehicle_reg_api).then((data) => {
      let options = data.DATA.map((item: dropdown_api_type) => {
        return {
          label: `${item.Registration_No} `,
          id: item.VehicleProfileId,
        };
      });
      setreg_no_options([...options]);
    });
  }, []);
  useEffect(() => {
    if (!(reg_input === "")) {
      let url = `${vehicle_track_api}${reg_input}`;
      customFetch(url).then((data) => {
        setstepper_data([...data.DATA]);
        console.log(data.DATA);
      });
    }
  }, [reg_input]);

  return (
    <div className="w-full flex flex-col h-screen gap-5 relative">
      <div>
        <TopNav
          openTopNav={openNav}
          setTopNav={setOpenNav}
          setdrop_show={setdrop_show}
        />
      </div>
      <div className="flex flex-col gap-12">
        <div className="flex justify-center">
          <div className="flex gap-4 items-center">
            <TimelineIcon
              sx={{ fontSize: "3rem", color: "#00aa13", fontWeight: "bold" }}
            />

            <h1 className="text-3xl font-bold text-[#00aa13]">
              Vehicle Status
            </h1>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center">
          <h1 className="text-xl">Search by reg-no to track</h1>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={reg_no_options}
            onChange={(e, newValue) => setreg_input(newValue?.label ?? "")}
            sx={{ minWidth: 300, width: "35%" }}
            renderInput={(params) => (
              <TextField {...params} label="Enter Reg-No" color="success" />
            )}
          />
        </div>
        {reg_input && stepper_data.length > 0 && (
          <div className="mt-0  sm:mt-16  ">
            {vertical_stepper ? (
              <div className="w-full flex justify-center">
                <VerticalLinearStepper
                  data={stepper_data}
                  reg_input={reg_input}
                />
              </div>
            ) : (
              <VehicleTrack data={stepper_data} reg_input={reg_input} />
            )}
          </div>
        )}
        <div className="absolute top-[90%] right-10">
          <Tooltip title="back">
            <div
              className="w-16 h-16 rounded-full bg-white shadow-xl flex justify-center items-center  backdrop-saturate-200 backdrop-blur-2xl bg-opacity-80 border border-white/80 hover:bg-gray-200 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default VehicleStatus;
