import React, { useState, useEffect, useCallback } from "react";
import AddButton from "../../Components/AddButton/AddButton";
import ViewButton from "../../Components/ViewButton/ViewButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import PurchaseDetails from "./purchase_details";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import customFetch from "../../../../API/CustomFetch";
import showToastMessage from "../../../../Tostify/Index";
import { ToastContainer } from "react-toastify";
import {
  baseUrl,
  create_purchase_order_api,
  get_product_data_by_product_id,
  hrc_all_sow_acc_vendor_id_api,
  product_menu_acc_seller_api,
} from "../../../../API/api";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import CircularProgress from "@mui/material/CircularProgress";
import { setpurchase_order_data } from "../../../../features/hrc_actions/hrc_slice";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import {
  new_inventory_supplier_dropdown,
  purchase_data_url,
} from "../../../../API/api";
import {
  new_inventory_supplier_dropdown_data_type,
  purchase_order_api_response,
  purchase_product_dropdown_type,
} from "../../../../models/API_data";
import {
  avl_seller_api_response,
  avl_seller_menu_type,
  product_menu_response,
  sowMenuType,
} from "../../../../models/product_data_model";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import Checkbox from "@mui/material/Checkbox";
import { Form, Formik } from "formik";
import {
  PoItem,
  purchase_order_get_product_info,
  purchase_order_initial_value,
  purchase_order_validation_shema,
} from "../../Components/Forms/po_form/fomik_essential";
import PoForm from "../../Components/Forms/po_form/po_fom";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  // : string;
}
interface dynamictype {
  Component_Id: string | null | number;
  Quantity: string | number;
  Amount: string | number;
  Total_Amount: string | number;
}
interface autocomplete_type {
  label: string;
  id: number;
}

const PurchaseOrder: React.FC<Props> = ({}) => {
  const [flag, setflag] = useState(true);
  const [all_avl_seller, setall_avl_seller] = useState<avl_seller_menu_type[]>(
    []
  );
  const [selected_sow, setselected_sow] = useState("");
  const navigate = useNavigate();
  const [product_data, setproduct_data] = useState<PoItem[]>([]);
  const [available_sow, setavailable_sow] = useState<sowMenuType[]>([]);
  const [selected_product, setselected_product] = useState<autocomplete_type[]>(
    []
  );
  const [product_drop, setproduct_drop] = useState<
    [] | purchase_product_dropdown_type[]
  >([]);
  const [seller_input, setseller_input] = useState("");
  const [selected_seller_data, setselected_seller_data] =
    useState<null | avl_seller_menu_type>(null);
  const [seller_options, setseller_options] = useState<
    [] | autocomplete_type[]
  >([]);
  const [product_options, setproduct_options] = useState<
    [] | autocomplete_type[]
  >([]);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const dispatch = useDispatch();
  const { loader } = useSelector(
    (state: RootState) => state.hrc.purchase_order
  );
  React.useEffect(() => {
    customFetch(purchase_data_url).then((data: purchase_order_api_response) => {
      dispatch(setpurchase_order_data([...data.DATA]));
    });
  }, []);
  useEffect(() => {
    if (seller_input) {
      let url = `${hrc_all_sow_acc_vendor_id_api}${seller_input}`;
      customFetchWithToken(url, "").then((data) => setavailable_sow(data.DATA));
      let selected_seller = all_avl_seller.filter(
        (selller) => selller.seller_id === Number(seller_input)
      );

      setselected_seller_data(selected_seller[0]);
    }
  }, [seller_input]);
  useEffect(() => {
    if (flag) {
      customFetch(new_inventory_supplier_dropdown).then(
        (data: avl_seller_api_response) => {
          let options = data.DATA.map((item) => {
            return {
              label: `${item.name} (${item.seller_code})`,
              id: item.seller_id,
            };
          });
          options.push({
            label: "Add New Seller",
            id: -1,
          });
          setseller_options([...options]);
          setall_avl_seller(data.DATA);
        }
      );
    }
  }, [flag]);
  useEffect(() => {
    if (seller_input) {
      setselected_product([]);
      let url = `${product_menu_acc_seller_api}${seller_input}`;
      customFetchWithToken(url, "").then((response: product_menu_response) => {
        let options = response.DATA.map((item) => {
          return {
            label: `${item.Product_Name} (${item.Product_Code})`,
            id: item.Component_Id,
          };
        });
        options.push({
          label: "Add New Product",
          id: -1,
        });
        setproduct_options([...options]);
      });
    }
  }, [seller_input]);

  useEffect(() => {
    const fetchData = async () => {
      let productData = [];
      for (const item of selected_product) {
        let url = `${get_product_data_by_product_id}${item.id}`;
        let data = await customFetchWithToken(url, "");
        let filter_data = data.DATA.map(
          (item: purchase_order_get_product_info) => {
            return {
              product_id: item.Component_Id,
              quantity: 0,
              amount: item.price_per_unit ?? 0,
              total_amount: 0,
              discount: item.discount,
              sgst: item.sgst_percentage ?? 0,
              cgst: item.cgst_percentage ?? 0,
              igst: item.igst_percentage ?? 0,
              created_by: 1,
            };
          }
        );
        productData.push(filter_data[0]);
      }
      setproduct_data([...productData]);
    };

    fetchData();
  }, [selected_product]);
  return (
    <div className="w-full flex justify-center bg-gray-50 h-[53.9rem] overflow-auto p-4 lg:p-8">
      <ToastContainer />
      {flag ? (
        <div className="fixed md:right-10 right-0 top-[91%] md:top-[90%] z-100">
          <AddButton setflag={setflag} />
        </div>
      ) : (
        <div
          className="fixed md:right-10 right-0 top-[91%] md:top-[90%] "
          style={{ zIndex: "2000" }}
        >
          <ViewButton setflag={setflag} />
        </div>
      )}
      {flag ? (
        <div className=" lg:w-full w-[98%] ">
          {loader ? (
            <div className="w-full h-full flex justify-center items-center">
              <CircularProgress color="success" size={100} />
            </div>
          ) : (
            <PurchaseDetails />
          )}
        </div>
      ) : (
        <div className="flex justify-center w-full relative ">
          <div className=" w-11/12  rounded border border-green-700 overflow-auto z-10 shadow shadow-green-500/100 ">
            <label className="absolute -top-[2%] left-[10%] bg-gray-50 z-[1200] text-2xl font-bold text-green-700">
              Create P.O
            </label>
            <div className="flex flex-col gap-5 p-6">
              <h1 className="text-xl font-bold mt-5">
                Select Seller and Product to proceed
              </h1>
              <div className="w-full flex gap-8 ">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={seller_options}
                  onChange={(e, newValue) => {
                    setseller_input(String(newValue?.id));
                    if (newValue?.id === -1) {
                      navigate("/home/vendor/add");
                    }
                  }}
                  sx={{ minWidth: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Seller" color="success" />
                  )}
                />

                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={product_options}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  style={{ minWidth: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product"
                      placeholder="Products"
                      color="success"
                    />
                  )}
                  onChange={(e, value) => {
                    setselected_product(value);
                  }}
                  value={selected_product}
                />
              </div>
              {seller_input && (
                <Formik
                  initialValues={purchase_order_initial_value}
                  validationSchema={purchase_order_validation_shema}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    setSubmitting(true);
                    const updatedPOItems = values.po_items.map((item) => {
                      const { cgst, igst, sgst, ...filterdValue } = item;
                      return filterdValue;
                    });
                    let dataToSumbit = { ...values, po_items: updatedPOItems };
                    let body = JSON.stringify(dataToSumbit);
                    customFetchWithToken(
                      create_purchase_order_api,
                      "",
                      { method: "POST" },
                      body
                    )
                      .then((_) => {
                        showToastMessage(true, "Success");
                        resetForm();
                        setSubmitting(false);
                      })
                      .catch((_) => {
                        showToastMessage(false, "Somthing Went Wrong");
                        setSubmitting(false);
                      });
                  }}
                >
                  {(props) => {
                    return (
                      <Form className="flex flex-col gap-5">
                        <PoForm
                          prop={props}
                          sowMenu={available_sow}
                          product_data={product_data}
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          className="self-end"
                          disabled={props.isSubmitting}
                        >
                          submit
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseOrder;
