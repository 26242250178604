import React, { ReactNode } from "react";
import { Button } from "@material-tailwind/react";
import ViewButton from "../../Components/ViewButton/ViewButton";
import BasicTable from "../../Components/Tabel_job_assign_Inventory/BasicTable";
import { useState, useEffect } from "react";
import showToastMessage from "../../../../Tostify/Index";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import AddButton from "../../Components/AddButton/AddButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer } from "react-toastify";
import {
  create_inventory_from_job_card,
  assign_Inventory_dropdown,
  component_acc_product_id_api,
  job_Card_table_data_url,
} from "../../../../API/api";
import customFetch from "../../../../API/CustomFetch";
import {
  job_Card_table_data_type,
  inventory_components_acc_product_id_type,
} from "../../../../models/API_data";

export default function JobCardAssign() {
  const [flag, setflag] = useState(true);
  const [job_Card_data, setjob_Card_data] = useState<
    job_Card_table_data_type[] | []
  >([]);
  const [job_card_selected_option, setjob_card_selected_option] = useState<
    null | ReactNode | string
  >("");
  const [component_data, setcomponent_data] = useState<
    [] | inventory_components_acc_product_id_type[]
  >([]);

  //decalaration for redux usecase
  const {
    job_card: {
      data: { assign_inventory_table_data },
      loader,
    },
  } = useSelector((state: RootState) => state.hrc);
  let test: any = [];

  const handle_subform_data = (
    data1: ReactNode | string,
    data2: number,
    id: number,
    setserial_input: Function,
    setqty_input: Function,
    index: number
  ) => {
    let data_obj = {
      ComponentId: id,
      Serial_No: data1?.toString(),
      Quantity: data2,
    };
    // data_from_components.push(data_obj);
    test[index] = data_obj;
  };
  let [selected_data, setselected_data] = useState<
    job_Card_table_data_type[] | []
  >([]);
  useEffect(() => {
    customFetch(job_Card_table_data_url).then((data) =>
      setjob_Card_data([...data.DATA])
    );

    // customFetch(Inventory_allocation_table_data).then((data) =>
    //   dispatch(setassign_inventory_table_data([...data.DATA]))
    // );
  }, []);
  const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // let d: data_from_componentsType[] = [];
    // Object.keys(test).forEach((k: string) => d.push(test[k]));
    let data_to_add = {
      JobCardId: job_Card_data[Number(job_card_selected_option)].JobCardId,
      Components_Data: test,
    };
    let body = JSON.stringify(data_to_add);
    let result = customFetch(create_inventory_from_job_card, body, {
      method: "POST",
    });
    result.then((data) => showToastMessage(data.SUCCESS, data.MESSAGE));
  };
  useEffect(() => {
    if (job_card_selected_option) {
      selected_data = job_Card_data.filter((item) => {
        if (item.JobCardId === job_card_selected_option) {
          return item;
        }
      });
      setselected_data(selected_data);
      let product_id = selected_data[0].GT_ProductId;
      let api_url = `${component_acc_product_id_api}${product_id}`;
      customFetch(api_url).then((data) => setcomponent_data([...data.DATA]));
    }
  }, [job_card_selected_option]);

  return (
    <div className="w-full flex items-center gap-10 flex-col">
      <ToastContainer />

      {flag ? (
        <div className="fixed sm:right-10 -right-2 top-[92%]  sm:top-[90%] z-10">
          <AddButton setflag={setflag} />
        </div>
      ) : (
        <div className="fixed sm:right-10 -right-2 top-[92%]  sm:top-[90%]  z-10">
          <ViewButton setflag={setflag} />
        </div>
      )}
      {flag ? (
        <div className="w-full h-full flex justify-center items-center">
          {loader ? (
            <CircularProgress color="success" size={100} />
          ) : assign_inventory_table_data?.length === 0 ? (
            <div className="flex flex-col gap-8 items-center">
              <h1 className="text-2xl text-red-900 tracking-widest">
                No any available data
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          ) : (
            <div className="md:w-11/12 w-[96%]">
              <BasicTable rows={assign_inventory_table_data} />
            </div>
          )}
        </div>
      ) : (
        <div className="bg-gray-50 sm:w-11/12 w-[96%] p-8 rounded flex items-center">
          <form
            action=""
            className="flex w-full flex-col items-start gap-x-6 gap-y-10"
            onSubmit={handleForm}
          >
            <div className="w-full flex items-center gap-5 md:gap-0 justify-between flex-wrap md:flex-nowrap">
              <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Job Card
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Job Card"
                    value={job_card_selected_option}
                    onChange={(e) =>
                      setjob_card_selected_option(e.target.value)
                    }
                  >
                    {job_Card_data.map((item, index) => (
                      <MenuItem key={index} value={item.JobCardId}>
                        {" "}
                        {item.JobCard}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <div className="flex flex-col gap-4">
                <span>Technician Name</span>
                <span>
                  {job_card_selected_option
                    ? selected_data[0]?.Technician.Name
                    : null}
                </span>
              </div>
              <div className="flex flex-col gap-4">
                <span>Product Id</span>
                <span>
                  {job_card_selected_option
                    ? selected_data[0]?.GT_ProductId
                    : null}
                </span>
              </div>
              <div className="flex flex-col gap-4">
                <span>Bay No</span>
                <span>
                  {job_card_selected_option
                    ? selected_data[0]?.Bay.Bay_No
                    : null}
                </span>
              </div>
            </div>

            {component_data.length === 0 ? (
              <div>
                <h1 className="text-2xl font-bold text-green-700">
                  Select job card to proceed further:
                </h1>
              </div>
            ) : (
              component_data[0].component.map((item, i) => (
                <SubForm
                  title={item.Component_Name}
                  key={item.Component_Id}
                  id={item.Component_Id}
                  index={i}
                  handle_subform_data={handle_subform_data}
                />
              ))
            )}
            <Button type="submit" className="self-end">
              Submit
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
type SubFormProp = {
  title: string;
  id: number;
  handle_subform_data: (
    data1: ReactNode | string,
    data2: number,
    id: number,
    setserial_input: Function,
    setqty_input: Function,
    index: number
  ) => void;
  index: number;
};
type drop_data = {
  Component_Id: number;
  Product_Name: string;
  Product_Code: string;
  Lot_Quantity: number;
  Rejected_Quantity: number;
  Serial_No: string;
  id: number;
};
const SubForm = ({ title, id, handle_subform_data, index }: SubFormProp) => {
  const [serial_input, setserial_input] = useState<string | ReactNode>("");
  const [qty_input, setqty_input] = useState("");
  const [dropdown_data, setdropdown_data] = useState<[] | drop_data[]>([]);
  const handleFocus = (id: number) => {
    let result = customFetch(`${assign_Inventory_dropdown}${id}`);
    result.then((data) => {
      setdropdown_data([...data.DATA]);
    });
  };
  useEffect(() => {
    handle_subform_data(
      serial_input,
      Number(qty_input),
      id,
      setserial_input,
      setqty_input,
      index
    );
  }, [serial_input, qty_input]);
  return (
    <div className="w-full flex gap-3 sm:gap-[5.3rem] items-center">
      <label htmlFor="component" className="flex-none w-20">
        {title}
      </label>
      <div className="flex lg:gap-[5.3rem] gap-6 flex-wrap lg:flex-nowrap  w-11/12">
        <div className="min-w-[180px] max-w-[250px] flex-auto">
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Serial No</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={serial_input?.toString()}
                label="Serial No"
                onChange={(e) => {
                  setserial_input(e.target.value);
                }}
                onFocus={() => handleFocus(id)}
              >
                {dropdown_data.map((item) => (
                  <MenuItem value={item.Serial_No}>{item.Serial_No}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="min-w-[180px] max-w-[250px] flex-auto">
          <TextField
            id="outlined-basic"
            label="Qty"
            variant="outlined"
            value={qty_input}
            required
            onChange={(e) => {
              setqty_input(e.target.value);
            }}
            sx={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
