import { MdElectricBike } from "react-icons/md";
import InventoryIcon from "@mui/icons-material/Inventory";
import WorkIcon from "@mui/icons-material/Work";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import TimelineIcon from "@mui/icons-material/Timeline";
import BarChartIcon from "@mui/icons-material/BarChart";

const SidebarMenu = [
  { icons: <MdElectricBike />, label: "Vehicle In", path: "/home" },
  { icons: <WorkIcon />, label: "Job Card", path: "/home/job-card" },
  {
    icons: <ShoppingBasketIcon />,
    label: "Purchase Order",
    path: "/home/purchase",
  },
  { icons: <InventoryIcon />, label: "Inventory", path: "/home/inventory" },
  { icons: <InventoryIcon />, label: "Transit", path: "/home/transit" },
  { icons: <InventoryIcon />, label: "Add Rider", path: "/add-rider" },
  { icons: <TimelineIcon />, label: "Track Vehicle", path: "/track-vehicle" },
  { icons: <BarChartIcon />, label: "Analytics", path: "/home/analytics" },
  {
    icons: <i className="fa-brands fa-product-hunt text-3xl"></i>,
    label: "Product",
    path: "/home/product",
  },
  {
    icons: <i className="fa-brands fa-product-hunt text-3xl"></i>,
    label: "Vehicle Data",
    path: "/home/vehicle-data",
  },
];

export default SidebarMenu;
