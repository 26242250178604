import React, { useState, useEffect, useRef } from "react";
import {
  purchase_data_type,
  purchase_stage_menu_response,
  purchase_stage_menu_type,
} from "../../../../models/API_data";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from "@mui/icons-material/Percent";
import { Tooltip, IconButton } from "@mui/material";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { all_purchase_status_drop_menu_api } from "../../../../API/api";
import PurchaseStatusModel from "../purchase_status_model/purchase_status_model";
import { Link } from "react-router-dom";

interface AppProps {
  item: purchase_data_type;
}

const PurchaseAccord: React.FC<AppProps> = ({ item }) => {
  const [flag, setflag] = useState(false);

  const [purchasStageMenu, setpurchasStageMenu] = useState<
    purchase_stage_menu_type[]
  >([]);
  let calcultated_amounts_per_product = item.products.map((product) => {
    const total_amount = Math.floor(product.amount * product.quantity);
    const discount_amount = Math.floor(total_amount * (product.discount / 100));
    const net_amount = Math.floor(total_amount - discount_amount);
    const cgst_amount = Math.floor(net_amount * (product.cgst / 100));
    const sgst_amount = Math.floor(net_amount * (product.sgst / 100));
    const igst_amount = Math.floor(net_amount * (product.igst / 100));
    const sub_total = Math.floor(
      net_amount + cgst_amount + igst_amount + sgst_amount
    );
    return {
      total_discount: discount_amount,
      net_amount: net_amount,
      cgst_amount: cgst_amount,
      igst_amount: igst_amount,
      sgst_amount: sgst_amount,
      sub_total: sub_total,
      total_amount: total_amount,
    };
  });

  const calculateTotal = (data: any[], properties: string) => {
    let total = data.reduce((acc, curr) => {
      return acc + curr[properties];
    }, 0);
    return total;
  };
  const tds_amount =
    calculateTotal(calcultated_amounts_per_product, "net_amount") *
    (item.tds / 100);
  useEffect(() => {
    customFetchWithToken(all_purchase_status_drop_menu_api, "").then(
      (response: purchase_stage_menu_response) =>
        setpurchasStageMenu(response.DATA)
    );
  }, []);

  return (
    <div>
      <div className="w-full rounded bg-white ">
        <div
          className=" p-4 border-b border-[#00aa13] cursor-pointer"
          onClick={() => setflag(!flag)}
        >
          {/* checkbox-container  */}
          <div className="w-full flex-auto gap-y-4 flex-wrap sm:flex-nowrap flex justify-center sm:justify-evenly items-center text-black">
            <div className="form-check w-5  flex-auto ">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#00aa13] checked:border-[#00aa13] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
            </div>
            {/* name */}
            <div className="flex-auto flex flex-col  w-32 sm:w-48">
              <span className="text-gray-700  text-center sm:text-start">
                Seller Name
              </span>
              <span className=" text-center sm:text-start">
                {item.seller_name}
              </span>
            </div>
            <div className=" flex flex-col  w-32 sm:w-48 flex-auto">
              <span className="text-gray-700  text-center sm:text-start">
                SoW Reference
              </span>
              <span className=" text-center sm:text-start truncate">
                {item.sow_reference_no}
              </span>
            </div>

            {/* email  */}
            <div className=" flex-auto flex flex-col  w-32 sm:w-48">
              <span className="text-gray-700  text-center sm:text-start">
                PO No
              </span>
              <span className=" text-center sm:text-start">{item.po_no}</span>
            </div>

            <div className=" flex-auto flex flex-col w-32 sm:w-48">
              <span className="text-gray-700  text-center sm:text-start">
                Items
              </span>
              <span className=" text-center sm:text-start">
                {item.products.length}
              </span>
            </div>
            <Tooltip title="click to change status">
              <div
                className="flex flex-auto items-center justify-center w-32"
                onClick={(e) => e.stopPropagation()}
              >
                <PurchaseStatusModel
                  id={item.id}
                  status_menu={purchasStageMenu}
                  status_id={item.purchase_status_id}
                  status_name={item.purchase_status}
                />
              </div>
            </Tooltip>

            {/* code  */}
            <div className="mr-3" onClick={(e) => e.preventDefault()}>
              <Link
                to={`/home/product/doucment/name-${item.id}`}
                target="_blank"
              >
                <IconButton aria-label="" onClick={(e) => e.stopPropagation()}>
                  <i className="fa-solid fa-file-export text-2xl text-[#00aa13]"></i>
                </IconButton>
              </Link>
            </div>

            <div className="flex-none text-center ">
              <i
                className={`fa-solid fa-chevron-down ${flag && "rotate-180"}`}
              ></i>
            </div>
          </div>
        </div>
        {flag && (
          <div className="mt-2">
            <div>
              <div className="accordion-body py-4 sm:px-5 px-2 flex flex-col gap-8 ">
                <div className="flex flex-col gap-6 w-[450px]">
                  <div className="w-full  bg-green-200 p-3">
                    <span>Billed to</span>
                  </div>
                  <div className="w-full">
                    <h1 className="text-xl font-bold">{item.seller_name}</h1>
                    <p>{`${item.seller_address},${item.seller_city},${item.seller_state},${item.seller_country}`}</p>
                    <p>Pin-{item.seller_pincode}</p>
                    <p>
                      <span className="text-md font-bold">GSTN-</span>
                      {item.gst_no}
                    </p>
                    <p>POC:-</p>
                    <p>name:-{item.seller_poc_name}</p>
                    <p>email:-{item.seller_poc_email}</p>
                    <p>mobile no:-{item.seller_poc_mobileno}</p>
                  </div>
                </div>
                <div className="flex flex-col gap-8">
                  <h1 className="font-bold text-xl">Shipping Terms:</h1>
                  <div className="flex flex-col gap-8 bg-blue-gray-50 pb-6">
                    <div className="flex w-full justify-around bg-green-200 p-3">
                      <span>Shipping Terms</span>
                      <span>Shipping Method</span>
                      <span>Delivery Date</span>
                    </div>
                    <div className="flex w-full justify-around">
                      <span>{item.shipping_terms}</span>
                      <span>{item.shipping_method}</span>
                      <span>
                        {new Date(
                          item?.delivery_time_date ?? ""
                        ).toLocaleString("default", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                </div>
                <span className="text-xl font-bold">Product Details:</span>
                <div className="flex flex-col gap-8  bg-blue-gray-50 pb-6">
                  <div className="flex w-full justify-around bg-green-200 p-3">
                    <span>Product Name</span>
                    <span>Product Code</span>
                    <span>Qty</span>
                    <span>Amount</span>
                    <span>Total Amount</span>
                  </div>
                  {item.products.map((product, i) => {
                    return (
                      <div className="flex flex-col gap-8">
                        <div className="flex w-full justify-around ">
                          <span className="font-bold">
                            {product.product_name}
                          </span>
                          <span className="font-bold">
                            {product.product_code}
                          </span>
                          <span className="font-bold">{product.quantity}</span>
                          <span className="font-bold">{product.amount}</span>
                          <span className="font-bold">
                            {calcultated_amounts_per_product[i].total_amount}
                          </span>
                        </div>
                        <div className="self-end w-[30%] flex flex-col gap-6 ">
                          <div className="w-full flex justify-between ">
                            <span>IGST Percentage:</span>
                            <div className="flex gap-2 items-center w-48">
                              <div className="flex gap-2">
                                <CurrencyRupeeIcon sx={{ width: "20px" }} />

                                <span>
                                  {
                                    calcultated_amounts_per_product[i]
                                      .igst_amount
                                  }
                                </span>
                              </div>
                              <div className="flex">
                                <span>({product.igst})</span>
                                <PercentIcon sx={{ width: "20px" }} />
                              </div>
                            </div>
                          </div>
                          <div className="w-full flex justify-between ">
                            <span>CGST Percentage:</span>
                            <div className="flex gap-2 items-center w-48">
                              <div className="flex gap-2">
                                <CurrencyRupeeIcon sx={{ width: "20px" }} />

                                <span>
                                  {
                                    calcultated_amounts_per_product[i]
                                      .cgst_amount
                                  }
                                </span>
                              </div>
                              <div className="flex">
                                <span>({product.cgst})</span>
                                <PercentIcon sx={{ width: "20px" }} />
                              </div>
                            </div>
                          </div>
                          <div className="w-full flex justify-between ">
                            <span>SGST Percentage:</span>
                            <div className="flex gap-2 items-center w-48">
                              <div className="flex gap-2">
                                <CurrencyRupeeIcon sx={{ width: "20px" }} />

                                <span>
                                  {
                                    calcultated_amounts_per_product[i]
                                      .sgst_amount
                                  }
                                </span>
                              </div>
                              <div className="flex">
                                <span>({product.sgst})</span>
                                <PercentIcon sx={{ width: "20px" }} />
                              </div>
                            </div>
                          </div>
                          <div className="w-full flex justify-between ">
                            <span>Discount:</span>
                            <div className="flex gap-2 items-center w-48">
                              <div className="flex gap-2">
                                <CurrencyRupeeIcon sx={{ width: "20px" }} />

                                <span>
                                  {
                                    calcultated_amounts_per_product[i]
                                      .total_discount
                                  }
                                </span>
                              </div>
                              <div className="flex">
                                <span>({product.discount})</span>
                                <PercentIcon sx={{ width: "20px" }} />
                              </div>
                            </div>
                          </div>
                          <div className="w-full flex justify-between ">
                            <span className="text-xl font-bold">Total</span>
                            <div className="flex gap-2 items-center w-48 border-t border-[#00aa13]">
                              <div className="flex gap-2  ">
                                <CurrencyRupeeIcon sx={{ width: "20px" }} />

                                <span>
                                  {calcultated_amounts_per_product[i].sub_total}
                                </span>
                              </div>
                              {/* <div className="flex">
                              <span>({product.discount})</span>
                              <PercentIcon sx={{ width: "20px" }} />
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex gap-5">
                  <div className=" w-[60%] h-fit flex flex-col bg-blue-gray-50">
                    <div className=" bg-green-200 p-3">
                      <span>Special notes and instructions</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-xl font-bold text-gray-700">
                        Payment Terms
                      </span>
                      <span>-{item.payment_terms}</span>
                      <span>{item.terms_conditons}</span>
                    </div>
                  </div>
                  <div className="flex-auto flex justify-center items-center">
                    <div className="w-[60%] flex flex-col gap-4">
                      <div className=" flex justify-between">
                        <span className="text-xl text-gray-600">SubTotal</span>
                        <div className="flex gap-2">
                          <CurrencyRupeeIcon sx={{ width: "20px" }} />

                          <span>
                            {calculateTotal(
                              calcultated_amounts_per_product,
                              "total_amount"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className=" flex justify-between">
                        <span className="text-xl text-gray-600">IGST</span>
                        <div className="flex gap-2">
                          <CurrencyRupeeIcon sx={{ width: "20px" }} />

                          <span>
                            {calculateTotal(
                              calcultated_amounts_per_product,
                              "igst_amount"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className=" flex justify-between">
                        <span className="text-xl text-gray-600">SGST</span>
                        <div className="flex gap-2">
                          <CurrencyRupeeIcon sx={{ width: "20px" }} />

                          <span>
                            {calculateTotal(
                              calcultated_amounts_per_product,
                              "sgst_amount"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className=" flex justify-between">
                        <span className="text-xl text-gray-600">CGST</span>
                        <div className="flex gap-2">
                          <CurrencyRupeeIcon sx={{ width: "20px" }} />

                          <span>
                            {calculateTotal(
                              calcultated_amounts_per_product,
                              "cgst_amount"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className=" flex justify-between">
                        <span className="text-xl text-gray-600">Discount</span>
                        <div className="flex gap-2">
                          <CurrencyRupeeIcon sx={{ width: "20px" }} />

                          <span>
                            {calculateTotal(
                              calcultated_amounts_per_product,
                              "total_discount"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className=" flex justify-between">
                        <span className="text-xl text-gray-600">TDS</span>
                        <div className="flex gap-4">
                          <div className="flex gap-2">
                            <span>{item.tds}</span>
                            <PercentIcon sx={{ width: "20px" }} />
                          </div>
                          <div className="flex gap-3">
                            <CurrencyRupeeIcon sx={{ width: "20px" }} />

                            <span>{tds_amount}</span>
                          </div>
                        </div>
                      </div>
                      <div className=" flex justify-between">
                        <span className="text-xl text-gray-600">
                          Shipping Amount
                        </span>
                        <div className="flex gap-2">
                          <CurrencyRupeeIcon sx={{ width: "20px" }} />

                          <span>{item.shipping_amount ?? 0}</span>
                        </div>
                      </div>
                      <div className=" flex justify-between mt-5">
                        <span className="text-3xl text-gray-600">
                          Grand Total
                        </span>
                        <div className="flex gap-2 items-center">
                          <CurrencyRupeeIcon sx={{ width: "40px" }} />

                          <span className="text-3xl">{item.grand_total}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="underline text-2xl">PAYMENT DETAILS:-</span>
                  <span className="">Beneficiary:-{item.beneficiary}</span>
                  <span className="">Account Type:-{item.account_type}</span>
                  <span className="">
                    Payment Method:-{item.payment_method}
                  </span>
                  <span className="">Bank Name:-{item.seller_bank_name}</span>
                  <span className="">Bank Address:-{item.bank_address}</span>
                  <span className="">Swift Code:-{item.swift_code}</span>
                  <span className="">IFSC:-{item.account_ifsc}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchaseAccord;
