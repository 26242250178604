import React from "react";
import TextField from "@mui/material/TextField";
import { FormikProps } from "formik";
import { useSelector } from "react-redux";
import {
  productUpdateType,
  productUpdateinitialValues,
} from "./formik_essentials";
import MenuItem from "@mui/material/MenuItem";
import {
  ProductModel,
  categoryModel,
  updateType,
} from "../../../../../../models/product_data_model";
import { RootState } from "../../../../../../app/store";
import customFetchWithToken from "../../../../../../API/CustomFetchWtihToken";
import { hrc_category_menu_api } from "../../../../../../API/api";

type Props = {
  prop: FormikProps<productUpdateType>;
  update: updateType<productUpdateType>;
};

const UpdateForm = (props: Props) => {
  const { values, handleChange, touched, errors, setValues } = props.prop;
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);
  const [category_menu, setcategory_menu] = React.useState<
    [] | categoryModel[]
  >([]);
  React.useEffect(() => {
    if (token_id) {
      customFetchWithToken(hrc_category_menu_api, token_id).then((response) =>
        setcategory_menu(response.DATA)
      );
    }
  }, [token_id, values]);

  React.useEffect(() => {
    if (props.update.data) {
      setValues(props.update.data);
    }
  }, [props.update]);
  console.log(errors);

  return (
    <div className="flex w-full flex-col gap-6">
      <TextField
        id="Product_Name"
        name="Product_Name"
        label={" Product Name"}
        value={values.Product_Name}
        onChange={handleChange}
        helperText={touched.Product_Name && errors.Product_Name}
        error={touched.Product_Name && Boolean(errors.Product_Name)}
      />
      <TextField
        id="Product_Code"
        name="Product_Code"
        label={" Product Code"}
        value={values.Product_Code}
        onChange={handleChange}
        helperText={touched.Product_Code && errors.Product_Code}
        error={touched.Product_Code && Boolean(errors.Product_Code)}
      />
      <TextField
        id="hasSerial"
        name="hasSerial"
        label={" Has Serial"}
        value={values.hasSerial}
        onChange={handleChange}
        helperText={touched.hasSerial && errors.hasSerial}
        error={touched.hasSerial && Boolean(errors.hasSerial)}
      />

      <TextField
        id="weight"
        name="weight"
        label={"Weight"}
        value={values.weight_kg}
        onChange={handleChange}
        helperText={touched.weight_kg && errors.weight_kg}
        error={touched.weight_kg && Boolean(errors.weight_kg)}
      />
      <TextField
        id="hsn_no"
        name="hsn_no"
        label={" HSN Number"}
        value={values.hsn_no}
        onChange={handleChange}
        helperText={touched.hsn_no && errors.hsn_no}
        error={touched.hsn_no && Boolean(errors.hsn_no)}
      />
      <TextField
        id="price_per_unit"
        name="price_per_unit"
        label={" Price Per Unit"}
        value={values.price_per_unit}
        onChange={handleChange}
        helperText={touched.price_per_unit && errors.price_per_unit}
        error={touched.price_per_unit && Boolean(errors.price_per_unit)}
      />
      <TextField
        id="category"
        name="category"
        label={"Category"}
        value={values.category}
        select
        onChange={handleChange}
        helperText={touched.category && errors.category}
        error={touched.category && Boolean(errors.category)}
      >
        {category_menu.map((item) => (
          <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
            {item.TypeName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="discount"
        name="discount"
        label={"Discount"}
        value={values.discount}
        onChange={handleChange}
        helperText={values.discount ? " " : "Enter the discount of the product"}
        error={!values.discount}
      />
      <TextField
        id="cgst_percentage"
        name="cgst_percentage"
        label={"CGST Percentage"}
        value={values.cgst_percentage}
        onChange={handleChange}
        helperText={touched.cgst_percentage && errors.cgst_percentage}
        error={touched.cgst_percentage && Boolean(errors.cgst_percentage)}
      />
      <TextField
        id="igst_percentage"
        name="igst_percentage"
        label={"IGST Percentage"}
        value={values.igst_percentage}
        onChange={handleChange}
        helperText={touched.igst_percentage && errors.igst_percentage}
        error={touched.igst_percentage && Boolean(errors.igst_percentage)}
      />
      <TextField
        id="sgst_percentage"
        name="sgst_percentage"
        label={"SGST percentage"}
        value={values.sgst_percentage}
        onChange={handleChange}
        helperText={touched.sgst_percentage && errors.sgst_percentage}
        error={touched.sgst_percentage && Boolean(errors.sgst_percentage)}
      />
      <TextField
        id="Selling_Price"
        name="Selling_Price"
        label={"Selling price"}
        value={values.Selling_Price}
        onChange={handleChange}
        helperText={touched.Selling_Price && errors.Selling_Price}
        error={touched.Selling_Price && Boolean(errors.Selling_Price)}
      />
      <TextField
        id="Purchase_Price"
        name="Purchase_Price"
        label={" Purchase price"}
        value={values.Purchase_Price}
        onChange={handleChange}
        helperText={touched.Purchase_Price && errors.Purchase_Price}
        error={touched.Purchase_Price && Boolean(errors.Purchase_Price)}
      />
    </div>
  );
};

export default UpdateForm;
