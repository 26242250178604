import * as React from "react";
import ListVeiw from "../../Components/list_view/list_view";
import SowAccordion from "../../Components/accord_sow/accord_sow";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import { setsow_list_data } from "../../../../features/hrc_actions/hrc_slice";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { hrc_sow_list_api, hrc_update_sow_api } from "../../../../API/api";
import { updateType, vendorModel } from "../../../../models/product_data_model";
import { Form, Formik } from "formik";
import IconButton from "@mui/material/IconButton";
import UpdateSow from "../../Components/Forms/update_form/update_sow/update_sow";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  updateSowInitialValue,
  updateSowInitialValueType,
  updateSowSchema,
} from "../../Components/Forms/update_form/update_sow/formik_essential";
import showToastMessage from "../../../../Tostify/Index";

export interface SowProps {}

export default function Sow(props: SowProps) {
  const [update, setupdate] = React.useState<
    updateType<updateSowInitialValueType>
  >({
    flag: false,
    data: null,
    id: 0,
  });
  const dispatch = useDispatch();
  const {
    hrc: {
      new_product_data: {
        sow_list: { data, loader },
      },
    },
    user: { token_id },
  } = useSelector((state: RootState) => state);
  React.useEffect(() => {
    if (token_id)
      customFetchWithToken(hrc_sow_list_api, token_id).then((data) =>
        dispatch(setsow_list_data([...data.DATA]))
      );
  }, [token_id]);
  return (
    <div className="flex w-full h-full gap-3">
      <div className="flex-auto w-full h-full ">
        <ListVeiw title="SOW" path="/home/sow/add">
          <div className="flex flex-col gap-4 flex-auto h-96 overflow-auto custom-scroll">
            {data?.map((item) => (
              <SowAccordion sow={item} setupdate={setupdate} />
            ))}
          </div>
        </ListVeiw>
      </div>
      <div
        className={`flex-auto h-full overflow-auto bg-white ${
          update.flag
            ? " w-[550px] visible animate__animated animate__fadeIn"
            : "w-[0] hidden animate__fadeOutRight"
        } `}
      >
        <Formik
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            if (token_id) {
              let update_sow_url = `${hrc_update_sow_api}${update.id}`;
              console.log(values);
              let body = JSON.stringify(values);
              console.log(body);
              customFetchWithToken(
                update_sow_url,
                token_id,
                { method: "PATCH" },
                body
              )
                .then(() => {
                  showToastMessage(true, "Success");
                  resetForm();
                  setSubmitting(false);
                  setupdate({
                    ...update,
                    flag: false,
                  });
                })
                .catch((response) =>
                  showToastMessage(response.SUCCESS, response.MESSAGE)
                );
            }
          }}
          initialValues={updateSowInitialValue}
          validationSchema={updateSowSchema}
        >
          {(props) => {
            return (
              <Form className="flex flex-col gap-4 w-full p-4 ">
                <div className="flex justify-between w-full items-center ">
                  <h1 className="text-xl text-[#00aa13]">Update</h1>
                  <IconButton
                    aria-label="close"
                    onClick={(e) => {
                      e.preventDefault();
                      setupdate({
                        ...update,
                        flag: false,
                      });
                    }}
                    className="w-16 h-16"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <UpdateSow prop={props} update={update} />
                <Button variant="contained" type="submit" color="success">
                  Update
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
