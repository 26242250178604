import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { FormikProps, useFormik } from "formik";
import { initialSeller, sellerType } from "./vendor_schema";
import customFetchWithToken from "../../../../../API/CustomFetchWtihToken";
import {
  baseUrl,
  gen_account_type_menu_api,
  get_all_country_api,
  hrc_single_vendor_data_api,
} from "../../../../../API/api";
import { useDispatch } from "react-redux";
import {
  country_drop_menu_type,
  lms_state_menu_type,
  lms_city_menu_type,
} from "../../../../../models/API_data";
import {
  account_menu_response_type,
  account_menu_type,
} from "../../../../../models/product_data_model";
import { toEditorSettings } from "typescript";

interface vendorProps {
  children?: JSX.Element;
  props: FormikProps<sellerType>;
  selected_vendor?: string;
}

function VendorForm({ children, props, selected_vendor }: vendorProps) {
  const [country_drop_menu, setcountry_drop_menu] = useState<
    country_drop_menu_type[] | []
  >([]);
  const [state_drop_menu, setstate_drop_menu] = useState<lms_state_menu_type[]>(
    []
  );
  const [city_drop_menu, setcity_drop_menu] = useState<lms_city_menu_type[]>(
    []
  );
  const [acccount_type, setaccount_type] = useState<account_menu_type[]>([]);
  const { values, handleChange, errors, touched, setFieldValue, setValues } =
    props;
  const {
    user: { token_id },
    hrc: {
      form_data: {
        data: { product },
      },
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (product?.category) {
      initialSeller.product_category = product.category;
    }
    if (token_id) {
      customFetchWithToken(get_all_country_api, "").then((response) =>
        setcountry_drop_menu(response.DATA)
      );
      customFetchWithToken(gen_account_type_menu_api, token_id).then(
        (response: account_menu_response_type) => setaccount_type(response.DATA)
      );
    }
  }, [token_id]);
  useEffect(() => {
    if (values.country) {
      let state_url = `${baseUrl}/state/v1/get/${values.country}/state`;
      customFetchWithToken(state_url, "").then((response) =>
        setstate_drop_menu([...response.DATA])
      );
    }
    if (values.state) {
      let city_url = `${baseUrl}/state/v1/get/${values.state}/city`;
      customFetchWithToken(city_url, "").then((response) =>
        setcity_drop_menu([...response.DATA])
      );
    }
  }, [values.state, values.country]);

  useEffect(() => {
    if (selected_vendor && token_id && selected_vendor !== "Add") {
      let vendor_url = `${hrc_single_vendor_data_api}${selected_vendor}`;
      customFetchWithToken(vendor_url, token_id).then((response) => {
        let vendor_data = { ...response.DATA[0] };
        delete vendor_data.seller_id;
        delete vendor_data.state_name;
        delete vendor_data.city_name;
        setValues(vendor_data);
      });
    } else {
      setValues(initialSeller);
    }
  }, [selected_vendor]);

  return (
    <div className="w-full flex flex-col gap-9">
      {/* Form title  */}
      <header className="flex justify-between w-full">
        <h1 className="text-3xl text-[#00aa13] "> Vendor Details</h1>
        {children}
      </header>
      {selected_vendor && (
        <main className="w-full ">
          <div className="w-full flex flex-col  gap-10  pb-4">
            <div className="w-full flex flex-col gap-4 ">
              <h1 className="text-2xl">Basic Detalis:-</h1>
              <div className="flex gap-8 flex-wrap w-full ">
                <TextField
                  id="seller_code"
                  name="seller_code"
                  label="Seller Code"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.seller_code}
                  onChange={handleChange}
                  error={touched.seller_code && Boolean(errors.seller_code)}
                  helperText={touched.seller_code && errors.seller_code}
                />
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  id="contact"
                  name="contact"
                  label="Phone"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.contact}
                  onChange={handleChange}
                  error={touched.contact && Boolean(errors.contact)}
                  helperText={touched.contact && errors.contact}
                />
                <TextField
                  id="pan"
                  name="pan"
                  label="Pan number"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.pan}
                  onChange={handleChange}
                  error={touched.pan && Boolean(errors.pan)}
                  helperText={touched.pan && errors.pan}
                />
                <TextField
                  id="gst_no"
                  name="gst_no"
                  label="GST number"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.gst_no}
                  onChange={handleChange}
                  error={touched.gst_no && Boolean(errors.gst_no)}
                  helperText={touched.gst_no && errors.gst_no}
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <h1 className="text-2xl">Address Details:-</h1>
              <div className="flex flex-wrap w-full gap-8">
                <TextField
                  id="country"
                  name="country"
                  label="Country"
                  select
                  variant="outlined"
                  focused={!!values.country}
                  sx={{ minWidth: "220px" }}
                  value={values.country}
                  onChange={handleChange}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                >
                  {country_drop_menu.map((country) => (
                    <MenuItem
                      value={country.Country_Id}
                      key={country.Country_Id}
                    >
                      <span>
                        {" "}
                        {country.Country_Name}{" "}
                        <span className="ml-2">{country.Country_Code}</span>
                      </span>
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="pincode"
                  name="pincode"
                  label="Pin Code"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.pincode}
                  onChange={handleChange}
                  error={touched.pincode && Boolean(errors.pincode)}
                  helperText={touched.pincode && errors.pincode}
                />
                <TextField
                  id="state"
                  name="state"
                  select
                  label="State"
                  variant="outlined"
                  focused={!!values.state}
                  sx={{ minWidth: "220px" }}
                  value={values.state}
                  onChange={handleChange}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                >
                  {state_drop_menu.map((state) => (
                    <MenuItem value={state.Id} key={state.Id}>
                      {state.State}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  select
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.city}
                  focused={!!values.city}
                  onChange={handleChange}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {city_drop_menu.map((city) => (
                    <MenuItem value={city.Id} key={city.Id}>
                      {city.City}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  multiline
                  rows={4}
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.address}
                  onChange={handleChange}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                />
              </div>
            </div>
            <div className="w-full flex flex-col  gap-4">
              <h1 className="text-2xl">Bank Details:-</h1>
              <div className="flex flex-wrap gap-8">
                <TextField
                  id="account_type"
                  name="account_type"
                  label="Acccount Type"
                  select
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.account_type}
                  onChange={handleChange}
                  error={touched.account_type && Boolean(errors.account_type)}
                  helperText={touched.account_type && errors.account_type}
                >
                  {acccount_type.map((item) => (
                    <MenuItem value={item.TypeMasterId} key={item.TypeMasterId}>
                      {item.TypeName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="bank_name"
                  name="bank_name"
                  label="Bank Name"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.bank_name}
                  onChange={handleChange}
                  error={touched.bank_name && Boolean(errors.bank_name)}
                  helperText={touched.bank_name && errors.bank_name}
                />
                <TextField
                  id="account_no"
                  name="account_no"
                  label="Account No"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.account_no}
                  onChange={handleChange}
                  error={touched.account_no && Boolean(errors.account_no)}
                  helperText={touched.account_no && errors.account_no}
                />
                <TextField
                  id="ifsc_code"
                  name="ifsc_code"
                  label="IFSC Code"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.ifsc_code}
                  onChange={handleChange}
                  error={touched.ifsc_code && Boolean(errors.ifsc_code)}
                  helperText={touched.ifsc_code && errors.ifsc_code}
                />
                <TextField
                  id="swift_code"
                  name="swift_code"
                  label="Swift Code"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.swift_code}
                  onChange={handleChange}
                  error={touched.swift_code && Boolean(errors.swift_code)}
                  helperText={touched.swift_code && errors.swift_code}
                />
                <TextField
                  id="beneficiary_name"
                  name="beneficiary_name"
                  label="Benficiary Name"
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.beneficiary_name}
                  onChange={handleChange}
                  error={
                    touched.beneficiary_name && Boolean(errors.beneficiary_name)
                  }
                  helperText={
                    touched.beneficiary_name && errors.beneficiary_name
                  }
                />
                <TextField
                  id="bank_address"
                  name="bank_address"
                  label="Bank Address"
                  multiline
                  variant="outlined"
                  sx={{ minWidth: "220px" }}
                  value={values.bank_address}
                  onChange={handleChange}
                  error={touched.bank_address && Boolean(errors.bank_address)}
                  helperText={touched.bank_address && errors.bank_address}
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <h1 className="text-2xl">POC Details:-</h1>
              <div className="flex flex-wrap gap-8 ">
                <TextField
                  id="poc_name"
                  name="poc_name"
                  label="POC Name"
                  variant="outlined"
                  color="success"
                  sx={{ minWidth: "220px" }}
                  value={values.poc_name}
                  onChange={handleChange}
                  error={touched.poc_name && Boolean(errors.poc_name)}
                  helperText={touched.poc_name && errors.poc_name}
                />
                <TextField
                  id="poc_mobileno"
                  name="poc_mobileno"
                  label="Contact"
                  variant="outlined"
                  color="success"
                  sx={{ minWidth: "220px" }}
                  value={values.poc_mobileno}
                  onChange={handleChange}
                  error={touched.poc_mobileno && Boolean(errors.poc_mobileno)}
                  helperText={touched.poc_mobileno && errors.poc_mobileno}
                />
                <TextField
                  id="poc_email"
                  name="poc_email"
                  label="Email"
                  variant="outlined"
                  color="success"
                  sx={{ minWidth: "220px" }}
                  value={values.poc_email}
                  onChange={handleChange}
                  error={touched.poc_email && Boolean(errors.poc_email)}
                  helperText={touched.poc_email && errors.poc_email}
                />
              </div>
            </div>
            <div className="w-full grid  gap-4">
              <h1 className="text-2xl">Tax Details:-</h1>
              <div className="grid grid-flow-col  w-fit gap-8 ">
                <TextField
                  id="cgst"
                  name="cgst"
                  label="CGST"
                  variant="outlined"
                  color="success"
                  sx={{ minWidth: "220px" }}
                  value={values.cgst}
                  onChange={handleChange}
                  error={touched.cgst && Boolean(errors.cgst)}
                  helperText={touched.cgst && errors.cgst}
                />
                <TextField
                  id="sgst"
                  name="sgst"
                  label="SGST"
                  variant="outlined"
                  color="success"
                  sx={{ minWidth: "220px" }}
                  value={values.sgst}
                  onChange={handleChange}
                  error={touched.sgst && Boolean(errors.sgst)}
                  helperText={touched.sgst && errors.sgst}
                />

                <TextField
                  id="igst"
                  name="igst"
                  label="IGST"
                  variant="outlined"
                  color="success"
                  sx={{ minWidth: "220px" }}
                  value={values.igst}
                  onChange={handleChange}
                  error={touched.igst && Boolean(errors.igst)}
                  helperText={touched.igst && errors.igst}
                />
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
}

export default React.memo(VendorForm);
