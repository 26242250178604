import * as Yup from "yup";
const productUpdateinitialValues: productUpdateType = {
  Product_Name: "",
  Product_Code: "",
  hasSerial: "",
  weight_kg: "",
  hsn_no: "",
  price_per_unit: "",
  category: "",
  discount: "",
  sgst_percentage: "",
  igst_percentage: "",
  cgst_percentage: "",
  Selling_Price: "",
  Purchase_Price: "",
};
const productUpdateSchema = Yup.object({
  Product_Name: Yup.string().required("Required"),
  Product_Code: Yup.string().required("Required"),
  hasSerial: Yup.string().required("Required"),
  weight_kg: Yup.string().required("Required"),
  hsn_no: Yup.string().required("Required"),
  price_per_unit: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  discount: Yup.string().required("Required"),
  cgst_percentage: Yup.string().required("Required"),
  igst_percentage: Yup.string().required("Required"),
  sgst_percentage: Yup.string().required("Required"),
  Selling_Price: Yup.string().required("Required"),
  Purchase_Price: Yup.string().required("Required"),
});
export interface productUpdateType {
  Product_Name: string;
  Product_Code: string;
  hasSerial: string;
  weight_kg: string;
  hsn_no: string;
  price_per_unit: string;
  category: string;
  discount: string;
  sgst_percentage: string;
  igst_percentage: string;
  cgst_percentage: string;
  Purchase_Price: string;
  Selling_Price: string;
}

export { productUpdateSchema, productUpdateinitialValues };
