import { Topmenu } from "../models/lms_topmenu";
import LanguageIcon from "@mui/icons-material/Language";
import AddAlertIcon from "@mui/icons-material/AddAlert";
const topmenuItems: Topmenu[] = [
  { label: "All", path: "/lms", icons: <LanguageIcon /> },
  {
    label: "Fresh",
    path: "/lms/fresh",
    icons: <i className="fa-solid fa-spray-can-sparkles"></i>,
  },
  {
    label: "Follow Up",
    path: "/lms/follow-up",
    icons: <AddAlertIcon />,
  },
  {
    label: "Won",
    path: "/lms/won",
    icons: <i className="fa-solid fa-won-sign"></i>,
  },
  {
    label: "Closed",
    path: "/lms/closed",
    icons: <i className="fa-solid fa-lock"></i>,
  },
];
export default topmenuItems;
