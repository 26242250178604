import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import { StepIconProps } from "@mui/material/StepIcon";
import { stepper_data_type } from "../../../../models/API_data";
import { useState, useEffect } from "react";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";

const steps = [
  {
    label: "Conversion Process",
  },
  {
    label: "Inspection",
  },

  {
    label: "HRC",
  },
  {
    label: "Conversion Process",
  },
  {
    label: "Vehicle Out",
  },
];

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#00aa13",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00aa13",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);
function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}
interface vertical_stepper_props {
  data: stepper_data_type[] | [];
  reg_input: string;
}

export default function VerticalLinearStepper({
  data,
  reg_input,
}: vertical_stepper_props) {
  const [activeStep, setActiveStep] = useState(0);
  const [desc, setdesc] = useState("");
  useEffect(() => {
    if (data[0].Stage_number === 9) {
      setdesc(data[0].Details);
      setActiveStep(5);
    } else if (data[0].Stage_number === 7 || data[0].Stage_number === 8) {
      setdesc(data[0].Details);
      setActiveStep(4);
    } else if (
      data[0].Stage_number === 5 ||
      data[0].Stage_number === 6 ||
      data[0].Stage_number === 32 ||
      data[0].Stage_number === 33 ||
      data[0].Stage_number === 34 ||
      data[0].Stage_number === 35 ||
      data[0].Stage_number === 36 ||
      data[0].Stage_number === 4
    ) {
      setdesc(data[0].Details);
      setActiveStep(3);
    } else if (
      data[0].Stage_number === 1 ||
      data[0].Stage_number === 2 ||
      data[0].Stage_number === 3
    ) {
      setdesc(data[0].Details);
      setActiveStep(2);
    } else if (
      data[0].Stage_number === 10 ||
      data[0].Stage_number === 14 ||
      data[0].Stage_number === 11 ||
      data[0].Stage_number === 16 ||
      data[0].Stage_number === 15
    ) {
      setdesc(data[0].Details);
      setActiveStep(2);
    }
  }, [reg_input, data]);

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{desc}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
