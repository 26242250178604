import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import showToastMessage from "../../../../Tostify/Index";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { useMemo } from "react";
import { lms_lead_assign_user_api } from "../../../../API/api";
import { getAuth } from "firebase/auth";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { setAssignData } from "../../../../features/lms_actions/lmsSlice";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface assign_modal_props {
  LeadId?: number;
  userId?: number;
  mobile_view?: boolean;
}

function AssignModal({ LeadId, userId, mobile_view }: assign_modal_props) {
  const {
    lmsUsers: { users },
    assignedData: { dataToAssigned },
  } = useSelector(
    (state: RootState) => state.lms,
    (prevs, next) =>
      prevs.assignedData.dataToAssigned === next.assignedData.dataToAssigned
  );

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const memoizedDispatch = useMemo(() => {
    return dispatch;
  }, [dispatch]);

  const [token, settoken] = React.useState("");
  const [selected_user, setselected_user] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const auth = getAuth();
    const current_user = auth.currentUser;
    current_user?.getIdToken().then((token_id) => settoken(token_id));
  }, []);

  const handleAssign = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (dataToAssigned.length > 0 && LeadId) {
      handleOpen();
    } else if (LeadId) {
      dispatch(setAssignData([LeadId]));
    } else if (dataToAssigned.length > 0) {
      handleOpen();
    } else {
      showToastMessage(false, "select lead to assign");
    }
  };

  const handleConfirm = () => {
    let body = JSON.stringify({
      lead: dataToAssigned,
    });

    if (!(selected_user === "" && dataToAssigned.length)) {
      let url = `${lms_lead_assign_user_api}${selected_user}`;
      customFetchWithToken(url, token, { method: "POST" }, body).then(
        (data) => {
          setselected_user("");
          showToastMessage(data.SUCCESS, "FAILED TO ASSIGN");
          handleClose();
          window.location.reload();
        }
      );
    } else {
      showToastMessage(false, "Select user");
    }
  };
  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    memoizedDispatch(setAssignData([]));
    handleClose();
  };

  return (
    <div>
      <Tooltip title={"assign"} placement="bottom">
        <IconButton onClick={handleAssign}>
          <i className="fa-solid fa-users text-[#00aa13] text-[1.2rem]"></i>
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl text-green-700">
                Select sales user to assign
              </h1>
              <span className="cursor-pointer">
                <CloseIcon onClick={handleCancel} />
              </span>
            </div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" color="success">
                  Sales Users
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selected_user}
                  label="Sales Users"
                  color="success"
                  onChange={(e) => setselected_user(e.target.value)}
                >
                  {users?.map((item) => {
                    if (item.userId === userId) return null;
                    return (
                      <MenuItem key={item.userId} value={item.userId}>
                        {item.userName}(
                        <div className="flex gap-1">
                          {item.RoleMapping.map((data, index) => {
                            return (
                              <span key={data.roleId}>
                                {data.roleName}{" "}
                                <span>
                                  {index < item.RoleMapping.length - 1 && ","}
                                </span>{" "}
                              </span>
                            );
                          })}
                        </div>
                        )
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Button variant="contained" color="success" onClick={handleConfirm}>
              Confirm
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
export default React.memo(AssignModal);
