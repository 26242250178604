import React, { useMemo, useState } from "react";
import FloatButton from "../Components/FloatButton";
import LmsForm from "../Components/Form";
import { Outlet } from "react-router-dom";
import TopNav from "../Components/lms_top_nav";
import NavContent from "../../../Global_Component/nav_content/nav_content";
import SidemenuItems from "../../../data/lms_sidemenu";
import { lmsData } from "../../../models/lms_data_api";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ExcelExport, { ExcelExportConfig } from "../../../Excel_exports";
import DialogUPload from "../Components/Dialog_upload";
import { baseUrl } from "../../../API/api";
import MobileNavComponent from "../../Hrc/Components/mobile_nav/mobile_nav";
import FixedBottomNavigation from "../Components/bottom_navigation/BottomNavigation";
import topmenuItems from "../../../data/lms_topmenu";
import customFetchWithToken from "../../../API/CustomFetchWtihToken";
import DropBar from "../../../Global_Component/dropbar/dropbar";
import PassResetModal from "../../../Global_Component/dropbar/pass_rst_mdl";
import AssignModal from "../Components/assign_model/assign_modal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import SearchIcon from "@mui/icons-material/Search";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import TopNavType2 from "../Pages/Analytics/analytics_top_nav";
import {
  setAssignData,
  setLmsData,
} from "../../../features/lms_actions/lmsSlice";
import { setLmsUserData } from "../../../features/lms_actions/lmsSlice";
import { handlePaginationData } from "../../../lms_utility_func";
import Tooltip from "@mui/material/Tooltip";
import {
  lmsAllDataurl,
  lms_leadsource_dropdown_url,
  lms_leadstatus_drop_menu,
  lms_state_drop_menu,
  lms_vehicle_brand_menu,
  wheel_type_dropdown_api,
  lms_get_users_api,
  lms_get_gender_drp_api,
  lms_get_profession_drp_api,
} from "../../../API/api";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import FilterPopOver from "../Components/lms_filter/filter_popover";
type PermanentCompoProps = {
  setOpentoAdd: Function;
  opentoAdd: {
    flag: boolean;
    update: null | lmsData;
    add: boolean;
  };
};

export default function LmsHome(props: PermanentCompoProps) {
  const location = useLocation();
  const [uplaod_dailog, setuplaod_dailog] = useState(false);
  const [search_input, setsearch_input] = useState("");
  const [openNav, setOpenNav] = useState(false);
  const [show_side_bar, setshow_side_bar] = useState(false);
  const [drop_show, setdrop_show] = useState(false);
  const [open_update_modal, setOpen_update_modal] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const urls = [
    lms_leadsource_dropdown_url,
    lms_leadstatus_drop_menu,
    lms_vehicle_brand_menu,
    lms_state_drop_menu,
    wheel_type_dropdown_api,
    lms_get_gender_drp_api,
    lms_get_profession_drp_api,
  ];
  const {
    lmsData: { all, fresh, followUp, won, closed },
    appData: { dataAccToPage },
    historyData: { data: oldData },
    assignedData: { dataToAssigned },
    lmsUsers: { users },
  } = useSelector((state: RootState) => state.lms);
  const { token_id } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [row, setrow] = useState(10);
  const [mobile_veiw, setmobile_veiw] = useState(false);
  const [select_check_input, setselect_check_input] = useState(false);
  const scroll_ref = useRef<HTMLDivElement>(null);
  let path = location.pathname;
  useEffect(() => {
    setshowCancelIcon(false);
    setsearch_input("");
  }, [path]);

  const toggleNav = () => {
    setOpenNav((openNav) => !openNav);
  };
  const [showCancelIcon, setshowCancelIcon] = React.useState(false);
  const [show_general_nav, setshow_general_nav] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 998) {
      setOpenNav(false);
      setshow_side_bar(true);
    } else if (window.innerWidth < 576) {
      setmobile_veiw(true);
    }
    if (token_id) {
      customFetchWithToken(lmsAllDataurl, token_id, { method: "GET" }).then(
        (data) => {
          dispatch(setLmsData({ data: data.DATA, leadType: [2] }));
        }
      );
      customFetchWithToken(lms_get_users_api, token_id, {
        method: "GET",
      }).then((data) => dispatch(setLmsUserData(data.DATA)));
    }
    document.title = "LMS | GreenTiger Console";
  }, []);
  const handleExcelDownload = () => {
    let path = location.pathname;
    if (path === "/lms") {
      let d: ExcelExportConfig = {
        content: all,
        fileName: "lms_data",
      };
      ExcelExport(d)
        .then((d: void) => {})
        .catch((e: any) => {});
    } else if (path === "/lms/fresh") {
      let data_to_download: ExcelExportConfig = {
        content: fresh,
        fileName: "fresh_data",
      };
      ExcelExport(data_to_download)
        .then((d: void) => {})
        .catch((e: any) => {});
    } else if (path === "/lms/follow-up") {
      let follow_up_download: ExcelExportConfig = {
        content: followUp,
        fileName: "follow_up_all",
      };
      ExcelExport(follow_up_download)
        .then((d: void) => {})
        .catch((e: any) => {});
    } else if (path === "/lms/won") {
      let won_data_download: ExcelExportConfig = {
        content: won,
        fileName: "won",
      };
      ExcelExport(won_data_download)
        .then((d: void) => {})
        .catch((e: any) => {});
    } else if (path === "/lms/closed") {
      let closed_data_download: ExcelExportConfig = {
        content: closed,
        fileName: "closed",
      };
      ExcelExport(closed_data_download)
        .then((d: void) => {})
        .catch((e: any) => {});
    }
  };
  // search lead logic
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    setsearch_input(value);
    if (value !== "") {
      let url = `${baseUrl}/lms/v1/searching/${value}`;

      customFetchWithToken(url, token_id ?? "", { method: "GET" }).then(
        (data) => {
          handlePaginationData(
            data.DATA,
            page,
            row,
            "No match Found",
            false,
            dispatch
          );
        }
      );
    } else if (oldData) {
      handlePaginationData(oldData, page, row, "No records", false, dispatch);
    }
  };

  useEffect(() => {
    if (scroll_ref.current != null) {
      scroll_ref.current.scrollTop = 0;
    }
  }, [dataAccToPage]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setshowCancelIcon(true);
  };
  const handleBlure = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!search_input) {
      setshowCancelIcon(false);
    }
  };
  const handleClickShowIcons = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showCancelIcon && oldData?.length) {
      setsearch_input("");
      handlePaginationData(oldData, page, row, "NO Records", false, dispatch);
      setshowCancelIcon(false);
    } else {
      setshowCancelIcon(true);
    }
  };
  useEffect(() => {
    let path = location.pathname;
    if (
      path === "/lms/analytics" ||
      path === "/lms/my-follow-up" ||
      path === "/lms/follow-up-walk-in" ||
      path === "/lms/customers" ||
      path === "/lms/repairing-criteria"
    ) {
      setshow_general_nav(true);
    } else {
      setshow_general_nav(false);
    }
  }, [location.pathname]);

  const memoizedDispatch = useMemo(() => {
    return dispatch;
  }, [dispatch]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!select_check_input) {
      let leadIdsArray = dataAccToPage?.map((item) => item.LeadId);
      if (leadIdsArray) memoizedDispatch(setAssignData([...leadIdsArray]));
    } else {
      memoizedDispatch(setAssignData([]));
    }
    setselect_check_input((prevState) => !prevState);
  };
  useEffect(() => {
    if (select_check_input && dataToAssigned.length === 0) {
      setselect_check_input(false);
    }
  }, [dataToAssigned]);

  return (
    <div
      className="w-full h-screen flex flex-col relative "
      onClick={() => setdrop_show(false)}
    >
      <div>
        {show_general_nav ? (
          <TopNavType2
            openTopNav={openNav}
            setTopNav={setOpenNav}
            setdrop_show={setdrop_show}
          />
        ) : (
          <TopNav
            openNav={openNav}
            setOpenNav={setOpenNav}
            setdrop_show={setdrop_show}
          />
        )}

        <MobileNavComponent openSideNav={openNav} setSideNav={toggleNav}>
          <NavContent
            SidebarMenu={SidemenuItems}
            lms={true}
            close_side_nav={show_side_bar}
            setSideNav={toggleNav}
          />
        </MobileNavComponent>
      </div>

      <div className="flex flex-auto  h-[40%]  w-full">
        {show_side_bar && props.opentoAdd.flag && (
          <div className="flex-none w-48  ">
            <NavContent
              SidebarMenu={SidemenuItems}
              lms={true}
              close_side_nav={show_side_bar}
              setSideNav={toggleNav}
            />
          </div>
        )}

        <div
          className={`flex-auto w-[30%] h-full flex flex-col  px-2 sm:px-4  bg-blue-gray-50 ${
            !show_side_bar && !props.opentoAdd.flag && "hidden"
          } `}
        >
          {!(
            location.pathname === "/lms/my-follow-up" ||
            location.pathname === "/lms/analytics" ||
            location.pathname === "/lms/follow-up-walk-in" ||
            location.pathname === "/lms/customers" ||
            location.pathname === "/lms/repairing-criteria"
          ) && (
            <div className="w-full m-auto  flex h-24 relative items-center gap-2">
              {/* {!mobile_veiw && (
                <>
                  {" "}
                  <i
                    className="fa-solid fa-download  text-2xl text-green-700   cursor-pointer"
                    onClick={handleExcelDownload}
                  ></i>
                  <i
                    className="fa-solid fa-upload text-2xl text-green-700   cursor-pointer"
                    onClick={() => {
                      setuplaod_dailog(true);
                    }}
                  ></i>
                </>
              )} */}
              <Tooltip title="select all" placement="bottom">
                <IconButton>
                  <Checkbox
                    {...label}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    color="success"
                    checked={select_check_input}
                    onChange={handleSelectAll}
                  />
                </IconButton>
              </Tooltip>

              {dataToAssigned.length > 0 && (
                <div className="flex-none">
                  <AssignModal mobile_view={mobile_veiw} />
                </div>
              )}
              <FilterPopOver page={page} row={row} />

              <DialogUPload
                uplaod_dailog={uplaod_dailog}
                setuplaod_dailog={setuplaod_dailog}
              />
              <div className="justify-self-end flex-auto flex justify-center  w-60 absolute right-0">
                <form className="w-full">
                  <FormControl
                    sx={{ m: 1, width: "100%", minWidth: "200px" }}
                    variant="outlined"
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      color="success"
                    >
                      Search
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      value={search_input}
                      onChange={handleSearch}
                      onFocus={handleFocus}
                      onBlur={handleBlure}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowIcons}
                            edge="end"
                          >
                            {showCancelIcon ? (
                              <CloseIcon sx={{ fontSize: "2.3rem" }} />
                            ) : (
                              <SearchIcon sx={{ fontSize: "2.3rem" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="search"
                      color="success"
                    />
                  </FormControl>
                </form>
              </div>
            </div>
          )}

          <div
            className="h-full w-full flex-auto  overflow-auto custom-scroll scroll-smooth "
            ref={scroll_ref}
          >
            <Outlet />
          </div>
        </div>

        {!props.opentoAdd.flag && (
          <div className="h-full  overflow-auto  flex-none w-[100%] lg:w-[450px] custom-scroll ">
            <LmsForm
              setOpentoAdd={props.setOpentoAdd}
              opentoAdd={props.opentoAdd}
            />
          </div>
        )}
        {!show_side_bar &&
          props.opentoAdd.flag &&
          (!(
            path === "/lms/my-follow-up" ||
            path === "/lms/follow-up-walk-in" ||
            path === "/lms/analytics"
          ) ? (
            <div>
              <FixedBottomNavigation menu={topmenuItems} />
            </div>
          ) : location.pathname === "/lms/my-follow-up" ||
            location.pathname === "/lms/follow-up-walk-in" ? (
            <div>
              <FixedBottomNavigation menu={SidemenuItems} />
            </div>
          ) : null)}
      </div>

      {props.opentoAdd.flag &&
        !(
          location.pathname === "/lms/my-follow-up" ||
          location.pathname === "/lms/analytics" ||
          location.pathname === "/lms/follow-up-walk-in" ||
          location.pathname === "/lms/customers" ||
          location.pathname === "/lms/repairing-criteria"
        ) && (
          <div className="fixed lg:bottom-14 right-0 lg:right-10 bottom-20 z-10">
            {!mobile_veiw ? (
              <FloatButton
                opentoAdd={props.opentoAdd}
                setOpentoAdd={props.setOpentoAdd}
              />
            ) : (
              <Box
                sx={{
                  height: 320,
                  transform: "translateZ(0px)",
                  flexGrow: 1,
                }}
              >
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                  }}
                  icon={<SpeedDialIcon />}
                >
                  <SpeedDialAction
                    icon={<AddIcon color="success" />}
                    tooltipTitle={"bulk import"}
                    onClick={() =>
                      props.setOpentoAdd({
                        ...props.opentoAdd,
                        add: true,
                        flag: false,
                        update: "",
                      })
                    }
                  />
                  <SpeedDialAction
                    icon={
                      <i className="fa-solid fa-download  text-2xl text-green-700   cursor-pointer"></i>
                    }
                    tooltipTitle={"bulk import"}
                    onClick={handleExcelDownload}
                  />
                  <SpeedDialAction
                    icon={
                      <i className="fa-solid fa-upload text-2xl text-green-700   cursor-pointer"></i>
                    }
                    tooltipTitle={"bulk import"}
                    onClick={() => {
                      setuplaod_dailog(true);
                    }}
                  />
                </SpeedDial>
              </Box>
            )}
          </div>
        )}
      {drop_show && (
        <DropBar
          setdrop_show={setdrop_show}
          setOpen={setOpen_update_modal}
          open={open_update_modal}
        />
      )}
      <PassResetModal
        setdrop_show={setdrop_show}
        open={open_update_modal}
        setOpen={setOpen_update_modal}
      />
    </div>
  );
}
