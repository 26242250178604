interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "Component_Id", label: "Component Id", minWidth: 80 },
  {
    id: "Component_Name",
    label: "Component Name",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Component_Code",
    label: "Component Code",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Seller_Name",
    label: "Seller Name",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },

  {
    id: "Po_Id",
    label: "Po Id",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Procurement_Date",
    label: "Procurement Date",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Recieved_Date",
    label: "Recieved Date",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Lot_Quantity",
    label: "Lot Quantity",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Rejected_Quantity",
    label: "Rejected Quantity",
    minWidth: 100,
    format: (value: number) => value.toLocaleString("en-US"),
  },
];
export { columns };
