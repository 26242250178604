import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

type Props = {};

const Customer = (props: Props) => {
  const {
    lmsData: { won },
  } = useSelector((state: RootState) => state.lms);
  return (
    <div className="w-full h-full flex flex-col  ">
      <div className="h-18 flex justify-end w-full p-4 ">
        <form className="w-[325px]">
          <FormControl
            sx={{ width: "100%", minWidth: "200px" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password" color="success">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              // value={search_input}
              // onChange={handleSearch}
              // onFocus={handleFocus}
              // onBlur={handleBlure}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={handleClickShowIcons}
                    edge="end"
                  >
                    {/* {showCancelIcon ? (
                              <CloseIcon sx={{ fontSize: "2.3rem" }} />
                            ) : (
                              <SearchIcon sx={{ fontSize: "2.3rem" }} />
                            )} */}
                  </IconButton>
                </InputAdornment>
              }
              label="search"
              color="success"
            />
          </FormControl>
        </form>
      </div>
      <div className="flex flex-col gap-4 w-full flex-auto overflow-auto custom-scroll">
        {won?.map((item) => {
          return (
            <div
              className="grid grid-cols-5 bg-white py-3 w-full"
              key={item.LeadId}
            >
              <div className="flex flex-col ">
                <span className="text-gray-700 text-lg text-center">
                  Regitration Number
                </span>
                <span className="text text-center">
                  {item.VehicleRegistrationNumber}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="text-gray-700 text-lg text-center">Brand</span>
                <span className="text text-center">{item.Brand_Name}</span>
              </div>
              <div className="flex flex-col ">
                <span className="text-gray-700 text-lg text-center">Model</span>
                <span className="text text-center">{item.Model_Name}</span>
              </div>
              <div className="flex flex-col ">
                <span className="text-gray-700 text-lg text-center">
                  Vehicle Make Year
                </span>
                <span className="text text-center">{item.MfgYr}</span>
              </div>
              <div className="flex flex-col ">
                <span className="text-gray-700 text-lg text-center">
                  Updated On
                </span>
                <span className="text text-center">
                  {" "}
                  {new Date(item?.UpdatedOn ?? "").toLocaleString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Customer);
