import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NewInventory from "./New_Inventory";
import Component from "./component";
import { ThemeProvider } from "@mui/material/styles";
import { custom_theme } from "../../../../custom_them/custom_them";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  new_Inventory_table_data_url,
  new_Inventory_dropdown_options_url,
} from "../../../../API/api";
import customFetchWithToken from "../../../../API/CustomFetchWtihToken";
import { setinventory_data } from "../../../../features/hrc_actions/hrc_slice";
import { new_Inventory_table_data_type } from "../../../../models/API_data";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Inventory() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { token_id } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const urls = [
    new_Inventory_dropdown_options_url,
    new_Inventory_table_data_url,
  ];
  React.useEffect(() => {
    if (token_id) {
      Promise.all(urls.map((url) => customFetchWithToken(url, token_id))).then(
        (response) => {
          const [res1, res2] = response;
          let modifiedData = res2.DATA.map(
            (item: new_Inventory_table_data_type) => {
              item.Procurement_Date = new Date(
                item.Procurement_Date
              ).toLocaleString("default", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });
              item.Recieved_Date = new Date(item.Recieved_Date).toLocaleString(
                "default",
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }
              );
              return item;
            }
          );
          let data = {
            product_table_data: res1.DATA,
            new_inventory_table_data: modifiedData,
          };
          dispatch(setinventory_data(data));
        }
      );
    }
  }, [token_id]);

  return (
    <Box sx={{ width: "100%" }} className="mt-16">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <ThemeProvider theme={custom_theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Component" {...a11yProps(0)} />
            <Tab label="New Inventory" {...a11yProps(1)} />
          </Tabs>
        </ThemeProvider>
      </Box>
      <TabPanel value={value} index={0}>
        <Component />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NewInventory />
      </TabPanel>
    </Box>
  );
}
