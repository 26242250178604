import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import Papa from "papaparse";
import { import_csv_url } from "../../../API/api";
import showToastMessage from "../../../Tostify/Index";
import { ToastContainer } from "react-toastify";
import customFetchWithToken from "../../../API/CustomFetchWtihToken";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

export default function DialogUPload({
  setuplaod_dailog,
  uplaod_dailog,
}: {
  setuplaod_dailog: Function;
  uplaod_dailog: boolean;
}) {
  const [data, setData] = useState<any>([]);
  const {
    user: { token_id },
  } = useSelector((state: RootState) => state);
  const handleClose = () => {
    setuplaod_dailog(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          let data_to_send: any = [];
          results.data.map((item: any) => {
            let arr = [];
            for (let key in item) {
              arr.push(item[`${key}`]);
            }
            data_to_send.push(arr);
          });
          setData([...data_to_send]);
        },
      });
    }
  };
  const handleConfirm = () => {
    let data_to_upload = {
      data: data,
    };
    let body = JSON.stringify(data_to_upload);
    if (token_id) {
      customFetchWithToken(
        import_csv_url,
        token_id,
        { method: "POST" },
        body
      ).then((data) => showToastMessage(data.SUCCESS, data.MESSAGE));
      setuplaod_dailog(false);
    }
  };
  return (
    <div>
      <ToastContainer />
      <Dialog open={uplaod_dailog} onClose={handleClose}>
        <DialogTitle>Choose File</DialogTitle>
        <div className="w-100 p-4 flex justify-center ">
          <div className="flex gap-4 flex-col w-11/12">
            <label htmlFor="csvInput" style={{ display: "block" }}>
              Enter CSV File
            </label>
            <input
              id="csvInput"
              type="file"
              accept=".csv"
              onChange={handleChange}
            />
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
