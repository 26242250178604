import React, { useState, useEffect } from "react";

type AnalyticsProps = {
  // : string;
};

const Analytics: React.FC<AnalyticsProps> = ({}) => {
  const [, set] = useState<string>();
  useEffect(() => {
    // set();
  }, []);

  return (
    <div className="h-full w-full flex-auto">
      <iframe
        title="new powerbi dec"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=011a55c3-b91f-4681-98fc-357b0d10f03e&autoAuth=true&ctid=6acfae4b-86a7-4ec0-9b0d-6bb473d1ea5c"
        frameBorder="0"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default Analytics;
