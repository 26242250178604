import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomDay from "./Calender";
import { lmsData, opentoaddData } from "../../../models/lms_data_api";
import Accordian from "./Accordian";
import { useState, useEffect } from "react";
import { Dayjs } from "dayjs";
import MobileCalendar from "./mobile_calendar";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { ThemeProvider } from "@mui/material/styles";
import { custom_theme } from "../../../custom_them/custom_them";
import Loader from "../../../Global_Component/loader/loader";
import PaginationControlled from "./pagination/pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { handlePaginationData } from "../../../lms_utility_func";
import { useDispatch } from "react-redux";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  page: number;
  row: number;
  setrow: Function;
  setPage: Function;
}
interface tab_filterd_data_type {
  today: [] | lmsData[];
  missed: [] | lmsData[];
  planned: [] | lmsData[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, page, row, setPage, setrow, ...other } =
    props;

  const scroll_ref = React.useRef<HTMLDivElement>(null);

  const {
    appData: { dataAccToPage, loader, message },
    lmsData: { followUp },
  } = useSelector((state: RootState) => state.lms);
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  React.useMemo(() => {
    scroll_ref.current?.scrollTo(0, 0);
    return null;
  }, [page, row]);

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={`h-40 w-full overflow-auto  ${
        value !== index && "hidden"
      } custom-scroll flex-auto flex flex-col  `}
      ref={scroll_ref}
    >
      {value === index && (
        <>
          <Box
            sx={{
              p: 3,
              width: "100%",
            }}
          >
            {children}
          </Box>
          <div className="flex self-start w-full justify-between items-center gap-8  py-4">
            <div className="flex">
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  color="success"
                >
                  row
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={row}
                  onChange={(e) => setrow(Number(e.target.value))}
                  autoWidth
                  color="success"
                  label="row"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={80}>80</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <PaginationControlled
                handleChange={handlePage}
                page={page}
                row={row}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface myfollowup {
  opentoAdd: opentoaddData;
  setOpentoAdd: Function;
}

export default function MyFollowTab({ opentoAdd, setOpentoAdd }: myfollowup) {
  const {
    appData: { dataAccToPage, loader, message },
    lmsData: { followUp },
  } = useSelector((state: RootState) => state.lms);
  const [value, setValue] = useState(0);
  const [show_side, setshow_side] = useState(true);
  const [openNav, setOpenNav] = useState(false);
  const [tabs_filtered_data, settabs_filtered_data] =
    React.useState<tab_filterd_data_type>({
      today: [],
      missed: [],
      planned: [],
    });
  const [page, setPage] = useState(1);
  const [row, setrow] = useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setOpenNav(true);
        setshow_side(false);
      } else {
        setshow_side(true);
        setOpenNav(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function filterData(data: lmsData[]) {
    return new Promise((resolve, reject) => {
      let todayTimestamp = new Date().setHours(0, 0, 0, 0);
      const todayList = data.filter(
        (item) =>
          new Date(item?.NextFollowUp ?? "").setHours(0, 0, 0, 0) ===
          todayTimestamp
      );
      const missedList = data.filter(
        (item) =>
          new Date(item?.NextFollowUp ?? "").setHours(0, 0, 0, 0) <
          todayTimestamp
      );
      const plannedList = data.filter(
        (item) =>
          new Date(item?.NextFollowUp ?? "").setHours(0, 0, 0, 0) >
          todayTimestamp
      );

      const DATA = {
        today: todayList,
        missed: missedList,
        planned: plannedList,
      };

      if (DATA) {
        resolve(DATA);
      } else {
        reject("Error occurred while filtering data");
      }
    });
  }
  const handleCalendar = (
    selected_date: Dayjs | null,
    setselected_date: Function
  ) => {
    if (!(value === 1)) {
      let filter_data_by_caldr = followUp?.filter(
        (item) =>
          new Date(item.NextFollowUp ?? "").setHours(0, 0, 0, 0) ===
          new Date(selected_date?.toString() ?? "").setHours(0, 0, 0, 0)
      );
      if (filter_data_by_caldr) {
        handlePaginationData(
          filter_data_by_caldr,
          page,
          row,
          "No match Found",
          false,
          dispatch
        );
      }
      setselected_date(selected_date);
    }
  };

  const tab_data = (data: lmsData[] | null) => {
    if (loader) {
      return (
        <div className="w-full h-[35rem] flex-auto flex justify-center items-center ">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col gap-5 ">
          {data?.length ? (
            data.map((item) => (
              <Accordian
                opentoAdd={opentoAdd}
                setOpentoAdd={setOpentoAdd}
                item={item}
                key={item.LeadId}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-full w-full gap-2">
              <h1 className="text-2xl text-red-900 tracking-widest">
                {message}
              </h1>
              <span className="text-4xl">😭</span>
            </div>
          )}
        </div>
      );
    }
  };
  useEffect(() => {
    if (followUp) {
      if (followUp?.length > 0) {
        filterData(followUp)
          .then((result: any) => {
            settabs_filtered_data({
              ...tabs_filtered_data,
              ...result,
            });
          })
          .catch((err: Error) => console.log(err));
      }
    }
  }, [followUp]);
  useEffect(() => {
    if (value === 0 && followUp) {
      handlePaginationData(followUp, page, row, "No records", true, dispatch);
    } else if (value === 1) {
      handlePaginationData(
        tabs_filtered_data.today,
        page,
        row,
        "No records",
        true,
        dispatch
      );
    } else if (value === 2) {
      handlePaginationData(
        tabs_filtered_data.missed,
        page,
        row,
        "No records",
        true,
        dispatch
      );
    } else if (value === 3) {
      handlePaginationData(
        tabs_filtered_data.planned,
        page,
        row,
        "No records",
        true,
        dispatch
      );
    }
  }, [value, page, row, followUp]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexShrink: 1,
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "72%",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div className="w-full border-b-2 border-green-700 mb-6 pt-8 flex gap-5">
          <div className="w-48 h-10 bg-green-700 rounded rounded-b-none flex justify-center items-center text-white">
            <span>My Follow UP</span>
          </div>
          {!show_side && (
            <MobileCalendar>
              <div className="border border-green-700 shadow-2xl shadow-green-900 sm:w-auto w-[90%] ">
                <CustomDay handleCalendar={handleCalendar} />
              </div>
            </MobileCalendar>
          )}
        </div>
        <ThemeProvider theme={custom_theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={`All ( ${followUp?.length})`}
              {...a11yProps(0)}
              sx={{
                flexShrink: "1",
                minWidth: "85px",
                "@media (max-width:576px)": {
                  fontSize: "0.7rem",
                  padding: "0",
                },
              }}
            />
            <Tab
              label={`Today Follow Ups (${tabs_filtered_data.today.length})`}
              {...a11yProps(1)}
              sx={{
                flexShrink: "1",
                minWidth: "85px",
                "@media (max-width:576px)": {
                  fontSize: "0.7rem",
                  padding: "0",
                },
              }}
            />
            <Tab
              label={`Missed Follow Ups (${tabs_filtered_data.missed.length}) `}
              {...a11yProps(2)}
              sx={{
                flexShrink: "1",
                minWidth: "85px",
                "@media (max-width:576px)": {
                  fontSize: "0.7rem",
                  padding: "0",
                },
              }}
            />
            <Tab
              label={`Planned Follow Ups (${tabs_filtered_data.planned.length})`}
              {...a11yProps(3)}
              sx={{
                flexShrink: "1",
                minWidth: "85px",
                "@media (max-width:576px)": {
                  fontSize: "0.7rem",
                  padding: "0",
                },
              }}
            />
          </Tabs>
        </ThemeProvider>
        {Object.keys(tabs_filtered_data).map((_, index) => {
          return (
            <TabPanel
              value={value}
              page={page}
              row={row}
              setPage={setPage}
              setrow={setrow}
              index={index}
            >
              {tab_data(dataAccToPage)}
            </TabPanel>
          );
        })}
      </Box>
      {show_side && opentoAdd.flag && (
        <div className="flex-auto shadow-2xl pt-4 flex flex-col gap-8 w-[320px] items-center  h-full  ">
          <div className="w-full h flex gap-5 border-b-2 border-green-700 p-4 justify-center items-center">
            <i className="fa-solid fa-calendar-days text-2xl text-green-700"></i>
            <span>Followup Calendar</span>
          </div>
          <div className=" shadow-2xl shadow-green-900 ">
            <CustomDay handleCalendar={handleCalendar} />
          </div>
        </div>
      )}
    </Box>
  );
}
