import { Sidemenu } from "../models/lms_sidemenu";
import BarChartIcon from "@mui/icons-material/BarChart";
const SidemenuItems = [
  {
    icons: <i className="fa-solid fa-user"></i>,
    label: "Lead List",
    path: "/lms",
  },
  {
    icons: <i className="fa-solid fa-calendar"></i>,
    label: "My Follow-ups",
    path: "/lms/my-follow-up",
  },
  {
    icons: <i className="fa-solid fa-person-walking fa-fade"></i>,
    label: "Walk In",
    path: "/lms/follow-up-walk-in",
  },
  {
    icons: <BarChartIcon />,
    label: "Analytics",
    path: "/lms/analytics",
  },
  {
    icons: <BarChartIcon />,
    label: "Customers",
    path: "/lms/customers",
  },
  // {
  //   icons: <i className="fa-solid fa-bullhorn"></i>,
  //   label: "Marketing",
  //   path: "",
  // },
  // {
  //   icons: <i className="fa-solid fa-spa"></i>,
  //   label: "Drip Marketing ",
  //   path: "",
  // },

  {
    icons: <i className="fa-solid fa-wrench"></i>,
    label: "Repairing Criteria",
    path: "/lms/repairing-criteria",
  },
];

export default SidemenuItems;
