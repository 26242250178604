import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import TranForm from "./tran_form";
import CloseIcon from "@mui/icons-material/Close";
import { trans_vehicle_data_type } from "../../../../models/API_data";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import CircularProgress from "@mui/material/CircularProgress";

type TransVehicleInProps = {
  // : string;
};
interface filled_data_type {
  open_form: boolean;
  item_t_add: trans_vehicle_data_type | null;
}

const TransVehicleIn: React.FC<TransVehicleInProps> = ({}) => {
  const {
    transit: {
      data: { vehicle_in_data },
      loader,
    },
  } = useSelector((state: RootState) => state.hrc);

  const [form_data, setform_data] = useState<filled_data_type>({
    open_form: false,
    item_t_add: null,
  });
  if (loader)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <CircularProgress color="success" size={100} />
      </div>
    );
  return (
    <div className=" w-full h-full flex ">
      {vehicle_in_data?.length ?? -1 > 0 ? (
        <div className="w-[55%] h-full flex flex-col flex-auto  gap-8 p-4  overflow-auto custom-scroll shadow-2xl  ">
          {vehicle_in_data?.map((item) => {
            return (
              <Accordion
                sx={{
                  borderBottom: "1px solid green",
                  width: "100%",
                }}
                key={item.VehilceId}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className=" grid w-full lg:grid-cols-5 grid-cols-2 gap-5 justify-items-center content-evenly ">
                    <div className="flex flex-col  items-center gap-1  break-words">
                      <span>Rider Name</span>
                      <span>{item.RiderName}</span>
                    </div>
                    <div className="flex flex-col items-center gap-1  break-all ">
                      <span className="break-words">Reg NO</span>
                      <span>{item.Registration_No}</span>
                    </div>
                    <div className="flex flex-col items-center gap-1  break-all">
                      <span>Mobile Number</span>
                      <span>{item.MobileNumber}</span>
                    </div>
                    <div className="flex flex-col items-center gap-1   break-all">
                      <span>Experience_Center</span>
                      <span>{item.Experience_Center}</span>
                    </div>
                    <div className="justify-self-end lg:justify-self-auto lg:col-span-1 col-span-2 ">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          setform_data({
                            ...form_data,
                            item_t_add: { ...item },
                            open_form: true,
                          })
                        }
                      >
                        Vehicle In
                      </Button>
                    </div>
                  </div>
                </AccordionSummary>
                {/* <AccordionDetails>
    <Typography>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
      eget.
    </Typography>
  </AccordionDetails> */}
              </Accordion>
            );
          })}
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <h1 className="text-2xl text-red-900 tracking-widest">
            No any available lead
          </h1>
          <span className="text-4xl">😭</span>
        </div>
      )}
      {form_data.open_form && (
        <div className="w-28 flex-auto py-10 flex flex-col items-center gap-4  shadow-2xl">
          <div className="self-end mr-4 cursor-pointer ">
            <CloseIcon
              onClick={() =>
                setform_data({
                  ...form_data,
                  open_form: false,
                  item_t_add: null,
                })
              }
            />
          </div>
          <h1 className="text-2xl text-green-700 font-bold">Vehicle In</h1>
          <TranForm center_type={1} form_data={form_data} />
        </div>
      )}
    </div>
  );
};

export default TransVehicleIn;
