import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProductModel } from "../../../../models/product_data_model";
import Rating from "@mui/material/Rating";
import IconButton from "@mui/material/IconButton";
interface productAccordionProps {
  product?: ProductModel;
  setupdate: Function;
}

export default function ProductAccordion({
  product,
  setupdate,
}: productAccordionProps) {
  return (
    <div>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ borderBottom: "thin solid #00aa13" }}
        >
          <div className="grid grid-cols-6 gap-4 w-full">
            <div className="flex gap-5 items-center ">
              <img
                src={product?.image?.[0]}
                alt="product-image"
                className="w-32 h-20 object-conver rounded-lg"
              />
              <div className="flex flex-col w-24 flex-auto truncate  justify-center">
                <span className="truncate ">{product?.Product_Name}</span>
                <span className="truncate">{product?.description}</span>
              </div>
            </div>

            <div className="flex flex-col w justify-center">
              <h4 className="truncate text-center ">Category</h4>
              <span className="truncate text-center ">{product?.category}</span>
            </div>

            <div className="flex flex-col  justify-center">
              <h4 className="truncate text-center">Discount</h4>
              <span className="truncate text-center">{product?.discount}</span>
            </div>
            <div className="flex flex-col  justify-center ">
              <h4 className="truncate text-center">HSN Number</h4>
              <span className="truncate text-center">{product?.hsn_no}</span>
            </div>
            <div className="flex flex-col  justify-center items-center ">
              <h4 className="truncate text-center ">Ratings</h4>
              <Rating name="read-only" value={product?.ratings} readOnly />
            </div>
            <div className="flex items-center justify-end w-fit ">
              <IconButton
                aria-label=""
                onClick={(e) => {
                  e.preventDefault();
                  setupdate({
                    flag: true,
                    data: product,
                    id: product?.Component_Id,
                  });
                }}
                className="w-16 h-16"
              >
                <i className="fa-sharp fa-solid fa-pen text-[#00aa13]"></i>
              </IconButton>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid grid-flow-row ">
            <div className="grid grid-cols-5  py-4 justify-items-center">
              <div className="flex flex-col  justify-center ">
                <h4 className=" ">Product Code</h4>
                <span className="truncate text-[14px] text-center ">
                  {product?.Product_Code}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">Weight</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.weight_kg}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">Price/unit</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.price_per_unit}
                </span>
              </div>

              <div className="flex flex-col  justify-center ">
                <h4 className="">Purchase Price</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.Purchase_Price}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">Selling Price</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.Selling_Price}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-5  py-4 justify-items-center">
              <div className="flex flex-col  justify-center ">
                <h4 className=" ">IGST Percentage</h4>
                <span className="truncate text-[14px] text-center ">
                  {product?.igst_percentage}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">CGST Percentage</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.cgst_percentage}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">SGST Percentage</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.sgst_percentage}
                </span>
              </div>

              <div className="flex flex-col  justify-center ">
                <h4 className="">Payment Terms</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.payment_terms}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">Created On</h4>
                <span className="truncate text-[14px] text-center">
                  {new Date(product?.CreatedOn ?? "")?.toLocaleString(
                    "default",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-5  py-4 justify-items-center">
              <div className="flex flex-col  justify-center ">
                <h4 className="font-">Created by</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.created_by}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className=" ">Currency</h4>
                <span className="truncate text-[14px] text-center ">
                  <span className="text-md text-[#00aa13]">
                    {product?.currency_symbol}
                  </span>
                  <span className="ml-2">{product?.currency}</span>
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">Updated On</h4>
                <span className="truncate text-[14px] text-center">
                  {new Date(product?.CreatedOn ?? "")?.toLocaleString(
                    "default",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </div>

              <div className="flex flex-col  justify-center ">
                <h4 className="">Updated by</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.updated_by}
                </span>
              </div>
              <div className="flex flex-col  justify-center ">
                <h4 className="">has Serial</h4>
                <span className="truncate text-[14px] text-center">
                  {product?.hasSerial}
                </span>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
