import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sowModel } from "../../../../models/product_data_model";
import IconButton from "@mui/material/IconButton";
interface sowAccordionProps {
  sow: sowModel;
  setupdate: Function;
}
export default function SowAccordion({ sow, setupdate }: sowAccordionProps) {
  return (
    <div>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ borderBottom: "thin solid #00aa13" }}
        >
          <div className="grid grid-cols-6 w-full">
            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Sow Refrence No{" "}
              </span>
              <span className="truncate text-[16px] text-center ">
                {sow?.sow_reference_no}
              </span>
            </div>
            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Product Code
              </span>
              <span className="truncate text-[16px] text-center ">
                {sow?.Product_Code}
              </span>
            </div>
            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Warranty
              </span>
              <span className="truncate text-[16px] text-center ">
                {sow?.warranty}
              </span>
            </div>
            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Delivery Time
              </span>
              <span className="truncate text-[16px] text-center ">
                {new Date(sow?.delivery_time ?? "")?.toLocaleString("default", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </span>
            </div>

            <div className="flex flex-col ">
              <span className="truncate text-[20px] text-gray-700 text-center">
                Validity
              </span>
              <span className="truncate text-[16px] text-center ">
                {new Date(sow?.validity_sow ?? "")?.toLocaleString("default", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </span>
            </div>
            <div className="flex items-center justify-end w-fit ">
              <IconButton
                aria-label=""
                onClick={(e) => {
                  e.preventDefault();
                  setupdate({
                    flag: true,
                    data: sow,
                    id: sow.sow_id,
                  });
                }}
                className="w-16 h-16"
              >
                <i className="fa-sharp fa-solid fa-pen text-[#00aa13]"></i>
              </IconButton>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid grid-flow-row gap-1">
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Cost Owenership
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.cost_ownership}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Design
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.design}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Order Projection
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.order_projection}
                </span>
              </div>

              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Penalty Terms
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.penalty_terms}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Replacement Time
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.penalty_terms}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Created By
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.createdBy}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Created on
                </span>
                <span className="truncate text-[16px] text-center ">
                  {new Date(sow?.created_at ?? "")?.toLocaleString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Updated On
                </span>
                <span className="truncate text-[16px] text-center ">
                  {new Date(sow?.updateAt ?? "")?.toLocaleString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>

              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Updated by
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.updatedBy}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Validity Sow
                </span>
                <span className="truncate text-[16px] text-center ">
                  {new Date(sow?.validity_sow ?? "")?.toLocaleString(
                    "default",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Terms and Conditions
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.terms_conditions}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Created on
                </span>
                <span className="truncate text-[16px] text-center ">
                  {new Date(sow?.created_at ?? "")?.toLocaleString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Updated On
                </span>
                <span className="truncate text-[16px] text-center ">
                  {new Date(sow?.updateAt ?? "")?.toLocaleString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>

              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Updated by
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.updatedBy}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Validity Sow
                </span>
                <span className="truncate text-[16px] text-center ">
                  {new Date(sow?.validity_sow ?? "")?.toLocaleString(
                    "default",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </div>
            </div>
            <h1>Shipping Terms</h1>
            <div className="grid grid-cols-5 w-full ">
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Shipping Method
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.shipping_method_name}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Shipping Payment Terms
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.shipping_terms_name}
                </span>
              </div>
              <div className="flex flex-col ">
                <span className="truncate text-[20px] text-gray-700 text-center">
                  Payment Method
                </span>
                <span className="truncate text-[16px] text-center ">
                  {sow?.payment_method_name}
                </span>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
