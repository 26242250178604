interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "Component_Id", label: "Component Id", minWidth: 100 },
  { id: "Product_Name", label: "Product Name", minWidth: 100, align: "center" },
  {
    id: "Product_Code",
    label: "Product Code",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "hasSerial",
    label: "hasSerial",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];
export { columns };
